import React,{useRef,useContext,useState} from 'react';
import styles from './styles.module.scss';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/core/styles';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import {MainContext} from '../DataManager';
import {Link} from "react-router-dom"
// import CloseIcon from '@material-ui/icons/Close';
// import Button from '@material-ui/core/Button';
import UserGroupGate from '../UserGroupGate';
import {useHistory} from "react-router-dom";
import BlackLeather from "../../Img/Tex/blackLeather.jpg"
import BlackLeather2 from "../../Img/Tex/blackLeather2b.jpg"
import NavLogo from "../../Img/Tex/navLogo.png"
import MenuCorner from "../../Img/menuCornerPiece.png"
import clsx from 'clsx'
// import Slide from '@material-ui/core/slide'
import Drawer from '@material-ui/core/Drawer'
import MenuDivider from '../../Img/menuSeparator.png'
const toolbarStyles = makeStyles({
  root: {
    justifyContent:'space-between',
    background: `url(${BlackLeather})`
  }
})
const menuStyles = makeStyles({
  paper: {
    // height: '95vh',
    // maxHeight: '95vh',
    background: `url('${BlackLeather2}')`,
    right: 0,
    // paddingRight: '80px',
    overflowX: 'hidden',
    marginTop: '25px',
    height: 'calc(100% - 60px)'
    // minWidth: '20vw',
    // paddingTop: '10px'
  },
  list: {
    minHeight: '100%',
    margin: 0,
    padding: 0
  }
})

const menuPopoverStyles = makeStyles({
  root: {
    background: 'rgba(0,0,0,0.8)'
  }
})

 
const TopNav = (props)=>{
  const toolbarClasses = toolbarStyles();
  const menuClasses = menuStyles();
  const menuPopoverClasses = menuPopoverStyles();
  const menuIcon = useRef();
  const [menuOpen,setMenuOpen] = useState(false);
  const {user} = useContext(MainContext);
  const slideRef = useRef();
  const history = useHistory();
  const handleClose = ()=>{}
  const toggleMenuOpen = ()=>{
    setMenuOpen(!menuOpen)
  }
  const navTo = (dest)=>{
    history.push(dest);
  }
  return (<>
  <AppBar position="static" color="primary" className={styles.TopNav}>
      <Toolbar color="primary" className={styles.toolbar}>
          <Link href="#" onClick={(e)=>{e.preventDefault(); history.push('/')}}><img src={NavLogo} alt="Haunted Bar" /></Link>
          {!!user && <IconButton edge="start" className={styles.menuButton} color="inherit" aria-label="menu" onClick={toggleMenuOpen}>
            <MenuIcon ref={menuIcon} />
            <Drawer
              classes={{paper:menuClasses.paper, list:menuClasses.list}}
              className={styles.drawer}
              PopoverClasses={menuPopoverClasses}
              anchorEl={()=>{return menuIcon.current}}
              keepMounted
              open={menuOpen}
              onClose={handleClose}
              anchor="right"
              onClick={(e)=>{if (!(e.target instanceof HTMLAnchorElement) && (e.target.className.indexOf('MuiBackdrop') === -1)) {e.preventDefault();e.stopPropagation(); return false;}}} //don't close nav when clicking on empty areas of nav but do close when clicking on backdrop
            >
              <span className={styles.menuCloseButton} onClick={()=>{setMenuOpen(false)}}>X</span>
                <img src={MenuCorner} className={styles.topMenuCorner} alt=''/>
                <img src={MenuCorner} className={styles.bottomMenuCorner} alt=''/>
              <div className={styles.menuBG}>
                {!!user && <span className={clsx(styles.signedInAs)}>Signed in as:</span>}
                {!!user && <span className={clsx(styles.userName)}>{user.attributes.email}</span>}
                <div className={clsx(styles.menuLists)}>
                  <div className={clsx(styles.menuHeader)}>
                    <h1>Menu</h1>
                    <img src={MenuDivider} alt='' />
                  </div>
                  <MenuList>
                    <MenuItem><Link to="/">Home</Link></MenuItem>
                    {!!user && <MenuItem ><a href={"https://www.immersivelg.com/"} target="_blank">Get Tickets</a></MenuItem>}
                    {!!user && <MenuItem ><Link to={"/logout"} onClick={()=>{setMenuOpen(false)}}>Logout</Link></MenuItem>}
                  </MenuList>
                  <UserGroupGate redirect={false} groups={["moderator","admin"]}>
                  <div className={clsx(styles.restrictedMenuHeader)}>
                    <h1>Moderation</h1>
                    <img src={MenuDivider} alt='' />
                  </div>
                    <MenuList>  
                      <MenuItem><Link to="/showControl">Show Control</Link></MenuItem>
                      <MenuItem><Link to="/moderation">Moderation</Link></MenuItem>
                      {/* <MenuItem onClick={()=>{navTo('/createBurnables')}}><Link to="/createBurnables">Create New Burnable Codes</Link></MenuItem> */}
                    </MenuList>
                    <h2 className={styles.navSubhead}>Experience Actuals</h2>
                    <MenuList>
                      <MenuItem><Link to="/writingActual">Writing Actual</Link></MenuItem>
                      <MenuItem><Link to="/portraitActual1">Portrait Actual 1</Link></MenuItem>
                      <MenuItem><Link to="/portraitActual1">Portrait Actual 2</Link></MenuItem>
                      <MenuItem><Link to="/portraitActual1">Portrait Actual 3</Link></MenuItem>
                      <MenuItem><Link to="/portraitActual1">Portrait Actual 4</Link></MenuItem>
                      <MenuItem><Link to="/portraitActual1">Portrait Actual 5</Link></MenuItem>
                      <MenuItem><Link to="/portraitActual1">Portrait Actual 6</Link></MenuItem>
                    </MenuList>
                    <h2 className={styles.navSubhead}>Experience UIs</h2>
                    <span style={{marginRight:"0.8em"}}>Without Codes</span>
                    <MenuList>
                      <MenuItem><Link to="/Awaken">Awaken the Spirits</Link></MenuItem>
                      <MenuItem><Link to="/Dolled">Dolled</Link></MenuItem>
                      <MenuItem><Link to="/Seance">Seance</Link></MenuItem>
                      <MenuItem>~</MenuItem>
                      <MenuItem><Link to="/Writing">Writing</Link></MenuItem>
                      <MenuItem><Link to="/HouseFavorite">House Favorite</Link></MenuItem>
                    </MenuList>
                  </UserGroupGate>
                </div>
              </div>
            </Drawer>
          </IconButton>}
      </Toolbar>
    </AppBar></>)
}
export default TopNav;