import React, { useState } from "react";
import styles from "./styles.module.scss";

const GradientButton = (props)=>{
  const [isDown,setIsDown] = useState(false);
  return (<div style={{boxSizing:'border-box',...props.style}} className={isDown? styles.GradientButtonDown : styles.GradientButton} onClick={props.onClick} onMouseDown={()=>{setIsDown(true)}} onMouseUp={()=>{setIsDown(false)}} onMouseOut={()=>{setIsDown(false)}}>
    <span className={styles.text} style={{fontSize:props.fontSize || '1.2em'}} >{props.text}</span>
  </div>)
}

export default GradientButton;