import React, {useEffect} from "react";
import GoldBorderModal from '../Modals/GoldBorderModal'
import styles from './styles.module.scss';
import CurlyArrow from '../../Img/curlyDownArrow.png';
import DecoButton from '../DecoButton';

const PermsPrompt = (props)=>{
  const {permsState,action} = props;

  const screen = (<div className={styles.PermsPrompt}>
    <h1>Your Permission Required</h1>
    <p>Hello Haunters!</p>
    <p>To provide everyone with the best 
possible experience, and to prevent undue mischief, we must require access to location permissions while you are using the Haunted Bar onsite app. Please press the button below, and hit “allow” in the pop-over to grant location permissions for the site.</p>
    <p>Thank you!</p>
    <img src={CurlyArrow} className={styles.arrow} alt=""/>
    <div className={styles.bottomRow}>
      <DecoButton text="Grant" onClick={props.action} actionOnEnterOrSpace={props.action} fontSize="1.5rem"/>
    </div>
  </div>)

  return(<GoldBorderModal modalContent={screen} />)
}

export default PermsPrompt