import React, {useEffect, useContext} from 'react';
import {Auth} from 'aws-amplify';
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import SignUp from '../SignUp';
import Login from "../Login"
import styles from "./styles.module.scss"
import {MainContext} from "../DataManager"
const AuthGreeting = (props)=>{
  const {setMainModalContent,setMainModalTabs,setMainModalType} = useContext(MainContext);
  useEffect(()=>{
    setMainModalTabs([
      {tabTitle: 'Login',tabContent:<Login/>},
      {tabTitle: 'Sign Up',tabContent:<SignUp />}
    ])
    setMainModalType('leatherTabbed')
    setMainModalContent('lorem')
  },[])


  // const [createOrLogin, setCreateOrLogin] = useState("login")

  // const setCreate = ()=>{
  //   setCreateOrLogin("create");
  // }
  // const setLogin = ()=>{
  //   setCreateOrLogin("login");
  // }

  return (<>
  {/* <div className={styles.forceHeightToFitKeyboard} style={{height:'150vh'}}></div> */}
  {/* <Grid container xs={12} spacing={0} className={styles.AuthGreetingRoot}>
    <Grid item xs={12}>
      {createOrLogin === "login" && <Login switchToCreate={setCreate} />}
      {createOrLogin === "create" && <SignUp switchToLogin={setLogin} />}
    </Grid>
  </Grid> */}
    
  </>)
}

export default AuthGreeting;