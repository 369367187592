import React from "react";
import styles from './styles.module.scss';

const Wallpaper=(props)=>{
  const style = {
    backgroundImage: `url(${props.bgImg})`,
    backgroundSize: `cover`,
    position: 'fixed',
    top: '68px',
  }
  return (<>
    <div className={styles.Wallpaper} style={style} />
  </>)
}


export default Wallpaper