import React,{useEffect,useContext,useRef} from "react";
import HBLogo512 from '../../../Img/HBLogo512.png'
// import WelcomeGhost from '../../../Img/Wallpapers/welcomeGhost.jpg'
import HBInterior from '../../../Img/Wallpapers/HBInterior.jpg'
import {MainContext} from '../../DataManager'
import styles from './styles.module.scss'
import GoldBorderModal from '../../Modals/GoldBorderModal'
const HomeScreen = (props)=>{
  const {setWallpaperImg} = useContext(MainContext)
  useEffect(()=>{
    setWallpaperImg(HBInterior)
  },[])

  const content = <>
  <div className={styles.HomeScreen}>
    <h1 className={styles.title}><span>Welcome!</span> Please see the Host or Bartender to get you started!</h1>
    <img className={styles.BarLogo} src={HBLogo512} alt='Haunted Bar' />
  </div>
</>

return(<GoldBorderModal modalContent={content} />)
}

export default HomeScreen