import React, { useEffect,useState,useContext,useRef } from 'react';
import { PubSub,API,Storage } from 'aws-amplify';
import * as queries from '../../../../graphql/queries';
import * as mutations from '../../../../graphql/mutations';
import {useParams} from 'react-router-dom'

import DefaultControlUI_Landing from '../../../DefaultControlUIs/DefaultControlUI_Landing';
import DefaultControlUI_Launcher from '../../../DefaultControlUIs/DefaultControlUI_Launcher';
import DefaultControlUI_ThisIsBurnt from '../../../DefaultControlUIs/DefaultControlUI_ThisIsBurnt';
import DefaultControlUI_ThankYou from '../../../DefaultControlUIs/DefaultControlUI_ThankYou';
import DefaultControlUI_ThisIsInvalid from '../../../DefaultControlUIs/DefaultControlUI_ThisIsInvalid';
import DefaultControlUI_Busy from '../../../DefaultControlUIs/DefaultControlUI_Busy';
import DefaultControlUI_Idle from '../../../DefaultControlUIs/DefaultControlUI_Idle';
import DefaultControlUI_Lockout from '../../../DefaultControlUIs/DefaultControlUI_Lockout';
import DefaultControlUI_ModeratedControl from '../../../DefaultControlUIs/DefaultControlUI_ModeratedControl';

import {MainContext} from '../../../../Components/DataManager';

const ClientModeratedControl = (props)=>{
  const {experienceName} = props;
  const [view,setView] = useState();
  const {user} = useContext(MainContext);
  const [modalContent, setModalContent] = useState(false);
  const {guid} = useParams();
  const lockoutUntil = useRef(0);
  // const triggerFired = useRef(false);
  useEffect(()=>{
    setView('landing')
  },[])

  const LandingComponent = props.landingComponent || DefaultControlUI_Landing;
  const LauncherComponent = props.launcherComponent || DefaultControlUI_Launcher;
  const IsBurntComponent = props.isBurntComponent || DefaultControlUI_ThisIsBurnt;
  const ThankYouComponent = props.thankYouComponent || DefaultControlUI_ThankYou;
  const IsInvalidComponent = props.isInvalidComponent || DefaultControlUI_ThisIsInvalid;
  const ControlComponent = props.controlComponent || DefaultControlUI_ModeratedControl;
  const BusyComponent = props.BusyComponent || DefaultControlUI_Busy;
  const IdleComponent = props.IdleComponent || DefaultControlUI_Idle;
  const LockoutComponent = props.LockoutComponent || DefaultControlUI_Lockout;

  const parseComms = (data)=>{
    const _data = data
    const msg = JSON.parse(_data.value);
    const cmd = msg.cmd;
    switch(cmd) {
      case 'startControlRequestAccepted':
        const _channel = `${process.env.REACT_APP_BUILD_ENV}/guid/${guid}/trigger`
        PubSub.publish(_channel,JSON.stringify({cmd:'ack',guid:guid}));
        break;
      case 'launchControls':
        setView('control');
        break;
      case 'endExperience':
        setTimeout(()=>{setView('thankyou')},25);
        break;
      case 'idle':
        setView('idle')
        break;  
      case 'busy':
        setView('busy')
        break;  
      case 'lockout':
        lockoutUntil.current = msg.lockoutUntil;
        setView('lockout');
        break;
      default:
        break;
    }
  }

  const sendContentToModeration = async (content,extension='png')=>{
    let _contentType;
    if (extension === 'png') {
      _contentType = 'image/png';
    }
    else if (extension === 'txt') {
      _contentType = 'text/plain';
    }
    const _filename = `moderation/${Date.now()}.${extension}`
    const _username = user.attributes.email;
    const putResult = await Storage.put(_filename,content,{level:'public',contentType:_contentType,metadata:{userName:_username,experienceName:experienceName}})
    // send all done to trigger
    PubSub.publish(`${process.env.REACT_APP_BUILD_ENV}/guid/${guid}/trigger`,JSON.stringify({cmd:'contentSentToModeration',guid:guid}))
  }

  return (<>
    {view === 'landing' && <LandingComponent experienceName={experienceName} setParentView={setView} guid={guid} parseCommsFromActual={parseComms} PubSub={PubSub} sendContentToModeration={sendContentToModeration} queries={queries} mutations={mutations} API={API} />}
    {view === 'launch' && <LauncherComponent setParentView={setView} guid={guid} mutations={mutations} API={API} experienceName={experienceName} />}
    {view === 'burnt' && <IsBurntComponent setParentView={setView} guid={guid} />}
    {view === 'invalid' && <IsInvalidComponent setParentView={setView} guid={guid} />}
    {view === 'control' && <ControlComponent setParentView={setView} guid={guid} setModalContent={setModalContent} PubSub={PubSub} mode="moderated" sendContentToModeration={sendContentToModeration}/>}
    {view === 'thankyou' && <ThankYouComponent setParentView={setView} guid={guid}/>}
    {view === 'busy' && <BusyComponent setParentView={setView} guid={guid}/>}
    {view === 'idle' && <IdleComponent setParentView={setView} guid={guid}/>}
    {view === 'lockout' && <LockoutComponent setParentView={setView} guid={guid} lockoutUntil={lockoutUntil}/>}
  </>)
}


export default ClientModeratedControl;