import React, {useContext} from "react"
import styles from "./styles.module.scss"
import GoldBorderModal from '../GoldBorderModal'
import LeatherTabbedModal from '../LeatherTabbedModal'
import Modal from '@material-ui/core/Modal';
import { useTheme,makeStyles } from '@material-ui/core/styles';
import {MainContext} from '../../DataManager';

const modalStyle = makeStyles((theme) => ({
  backdrop: {
    top: '68px !important',
    boxShadow: 'inset 0px 30px 27px -27px rgba(0,0,0,0.25)'
  },
}));

const MultiModal =(props)=>{
  const {modalType="goldBorder",modalContent,setModalContent,buttonActions}=props;
  const {user} = useContext(MainContext);
  const modalClasses = modalStyle();
  const onClose=(e)=>{
    if(!!user) {
      setModalContent(null);
    }
  }
  return (
    <Modal open={!!modalContent} className={styles.multiModal} BackdropProps={{className:modalClasses.backdrop}} onClose={onClose}>
      <>
      {modalType==="goldBorder"&&<GoldBorderModal modalStyle={modalStyle} {...props}>{modalContent}</GoldBorderModal>}
      {modalType==="leatherTabbed"&&!!props.modalTabs&&<LeatherTabbedModal modalStyle={modalStyle} {...props}>{modalContent}</LeatherTabbedModal>}
      </>
    </Modal>
  )
}


export default MultiModal;