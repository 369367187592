import React, {useContext} from "react";
import styles from "./styles.module.scss";
import {ReactComponent as LoadingFlask} from '../../../Img/loadingFlask.svg';
const LoadingModal=(props)=>{
  return (
    <div className={styles.LoadingModal} >
      <div className={styles.inner}>
        {props.content==='default' && <LoadingFlask />} {/* can show custom load content or default spinner */}
        {props.content!=='default' && props.content}
      </div>
    </div>
  )
}

export default LoadingModal;