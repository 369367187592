import React from 'react';
import styles from './styles.module.scss';
import ClientIndirectControl from '../Standard/ClientIndirectControl';
import WidowControlUI from '../../UIs/Widow/WidowControlUI';

const WidowClient = (props)=>{
  const {experienceName="Widow",wallpaperImg} = props;
  return (<ClientIndirectControl experienceName={experienceName} controlComponent={WidowControlUI} wallpaperImg={wallpaperImg} />)
}

export default WidowClient;