import React,{useEffect, useState,useRef} from 'react';
import {Auth} from 'aws-amplify';
import Amplify, { PubSub } from 'aws-amplify';
import { AWSIoTProvider } from '@aws-amplify/pubsub';
import {generateMapToken} from "../MapGuide/mapUtils"


const MainContext = React.createContext("MainContext");

const DataManager = (props)=>{

  // EVENT CONFIG
  const eventLatLong = {lat: 37.22301466197524, long: -121.98381171614042} // Immersive LG  
  const eventRadiusInner = 14.5; //feet
  const eventRadiusOuter = 27; //feet


  const [user,setUser] = useState(null);
  const [loading,setLoading] = useState(true);
  // const [mapToken,setMapToken] = useState(null);
  const mapToken = useRef(null);
  const {mainModalContent,setMainModalContent,setMainModalType,setMainModalTabs,mainModalType,mainModalTabs,loadingModalContent,setLoadingModalContent,wallpaperImg,setWallpaperImg} = props;
  const clearMainModal = ()=>{setMainModalContent(null);}
  // const [mainModalType, setMainModalType] = useState("goldBorder");
  useEffect(()=>{ //once per full app load (so 1st hit or hard nav in the bar), check to see if there is a pre-existing user
    if (!user) {
      Auth.currentAuthenticatedUser()
      .then((user)=>{
        setUser(user)
        if(!user.attributes.email_verified) {
          console.log('email not verified')
        }
      })
      .catch((err)=>{
        switch (err.code){
          case "UserNotConfirmedException":
            console.log('user not confirmed')
            break;
          default:
            break;
        }
      })
      .finally(()=>{
          setLoading(false)
        })
    }
  },[]); 

  // LOADING - we can keep adding vars here that loading is dependent upon - when all vars are loaded this will set loading to false.
  useEffect(()=>{
    if (!!user) {
      setLoading(false)
    }
  },[user])

  useEffect(()=>{ //once per full app load (so 1st hit or hard nav in the bar), init IOT systems
    Amplify.addPluggable(new AWSIoTProvider({
      aws_pubsub_region: 'us-west-2',
      aws_pubsub_endpoint: 'wss://a16r2a5cc5iq9j-ats.iot.us-west-2.amazonaws.com/mqtt'
    }))
  },[])

  useEffect(()=>{
    
  },[mapToken])

  

  const getMapToken = ()=>{
    return new Promise(async(res,rej)=>{
      if (!mapToken.current || Date.now() > mapToken.current.expires_on) {
        const tok = await generateMapToken();
        const newToken = {
          access_token: tok.access_token,
          expires_on: tok.expires_in+Date.now()
        };
        mapToken.current = newToken
        res(newToken.access_token);
      }
      else {
        res(mapToken.current.access_token)
      }
    })
  }

  const _setUser = (_user)=>{
    if (_user && !user) {
      setUser(_user)
    }
    else if (!_user) {
      setUser(null);
    }
  }

  // FEATURE CONFIGURATION CONTROLS

  const [debugEnabled,setDebugEnabled] = useState(false);
  const [spaceGateEnabled,setSpaceGateEnabled] = useState(true);

  const _setDebugEnabled = (val)=>{
    console.log('_setDebugEnabled val=',val)
    setDebugEnabled(val);
  }

  //automatically enable or disable spaceGate based on env
  useEffect(()=>{
    if (process.env.NODE_ENV !== "production" || (process.env.REACT_BUILD_ENV === "master")) {
      // setSpaceGateEnabled(false);
    }
  },[])

  const _mainContext = {user,setUser:_setUser,loading:loading,mainModalContent,setMainModalContent,clearMainModal,debugEnabled,setDebugEnabled:_setDebugEnabled,spaceGateEnabled,setSpaceGateEnabled,eventLatLong,eventRadiusInner,eventRadiusOuter,getMapToken,setMainModalType,setMainModalTabs,mainModalType,mainModalTabs,loadingModalContent,setLoadingModalContent,wallpaperImg,setWallpaperImg}
  return (<MainContext.Provider value={_mainContext}>{props.children}</MainContext.Provider>)
}


export {DataManager,MainContext}