import React from "react";
import { Route } from "react-router";
import AuthGuard from "./Components/AuthGuard";
import CreateBurnable from "./Components/CreateBurnable";
import DrippingCanvas from "./Components/DrippingCanvas";
import Logout from "./Components/Logout";
import Moderation from "./Components/Moderation";
import PubSubTest from "./Components/PubSubTest";
import S3PermsTest from "./Components/S3PermsTest";
import ShowControlBoard from "./Components/ShowControlBoard";
import SpaceGate from "./Components/SpaceGate";
import TopNav from "./Components/TopNav";
import UserGroupGate from "./Components/UserGroupGate";
import Wallpaper from "./Components/Wallpaper";
import IndirectControlActual from "./Experiences/Actuals/IndirectControlActual";
import ClientIndirectControl from "./Experiences/Clients/Standard/ClientIndirectControl";
import UseOnceTestClient from "./Experiences/Clients/UseOnceTest";
import WritingClient from "./Experiences/Clients/WritingClient";
import SpiritMakerUI from "./Experiences/UIs/SpiritMaker";
import AwakenClient from './Experiences/Clients/AwakenClient'
import DolledClient from "./Experiences/Clients/DolledClient";
import SeanceClient from "./Experiences/Clients/SeanceClient";
import WidowClient from "./Experiences/Clients/WidowClient";
import QRGen from './Components/CreateBurnable/QRGen';
import GoldBorderModal from './Components/Modals/GoldBorderModal'
import IOTPermsTest from "./Components/IOTPermsTest";
import HomeScreen from "./Components/Screens/HomeScreen";
import BatchPushBurnables from "./Components/BatchPushBurnables";
import QRStamper from "./Components/QRStamper";

// wallpaper imgs
import BarSignHand from './Img/Wallpapers/barSignHand.jpg'
import awakenPaper from './Img/Wallpapers/awakenPaper.png'
import dolledPaper from './Img/Wallpapers/dollPaper.png'
import seancePaper from './Img/Wallpapers/seancePaper.png'
import widowPaper from './Img/Wallpapers/widowPaper.png'
import flatBlack from './Img/Wallpapers/flatBlack.png'
import HBInterior from './Img/Wallpapers/HBInterior.jpg'

const Routes = [
  // experiences, actuals first, then other experience related, then other routes
  // exp actuals
  {
    path: "/WritingActual",
    exact: true,
    spaceGate: false,
    authGuard: false,
    permittedUserGroups: [],
    topNav: false,
    wallpaperImg: null, 
    hideModal: true,
    main: <DrippingCanvas experienceName="Writing" />
  },
  // {
  //   path: "/indirectControlActual", 
  //   exact: true,
  //   spaceGate: false,
  //   authGuard: true,
  //   permittedUserGroups: ["admin","moderator"],
  //   topNav: null,
  //   wallpaperImg: false, // REMEMBER <Wallpaper bgImg={wallpaperImg}/>
  //   main: <IndirectControlActual experienceName="IndirectControl"/>
  // },

  // exp controls
  {
    path: "/Writing/:guid",
    exact: true,
    spaceGate: false,
    authGuard: true,
    permittedUserGroups: ["user","admin","moderator"],
    topNav: <TopNav />,
    wallpaperImg: flatBlack, 
    main: <WritingClient experienceName="Writing" />
  },

  {
    path: "/HouseFavorite", // NEEDS GUID CHECK
    exact: true,
    spaceGate: false,
    authGuard: true,
    permittedUserGroups: [],
    topNav: <TopNav />,
    wallpaperImg: BarSignHand, // REMEMBER <Wallpaper bgImg={wallpaperImg}/>
    main: <SpiritMakerUI experienceName={"HouseFavorite"} />
  },

  {
    path: "/Awaken/:guid",
    exact: true,
    spaceGate: false,
    authGuard: true,
    permittedUserGroups: ["admin","moderator","user"],
    topNav: <TopNav />,
    wallpaperImg: awakenPaper, // REMEMBER <Wallpaper bgImg={wallpaperImg}/>
    main: <AwakenClient experienceName="Awaken"/>
  },

  {
    path: "/Dolled/:guid",
    exact: true,
    spaceGate: false,
    authGuard: true,
    permittedUserGroups: ["admin","moderator","user"],
    topNav: <TopNav />,
    wallpaperImg: dolledPaper, // REMEMBER <Wallpaper bgImg={wallpaperImg}/>
    main: <DolledClient experienceName="Dolled"/>
  },

  {
    path: "/Seance/:guid",
    exact: true,
    spaceGate: false,
    authGuard: true,
    permittedUserGroups: ["admin","moderator","user"],
    topNav: <TopNav />,
    wallpaperImg: seancePaper, // REMEMBER <Wallpaper bgImg={wallpaperImg}/>
    main: <SeanceClient experienceName="Seance"/>
  },

  {
    path: "/Widow/:guid",
    exact: true,
    spaceGate: false,
    authGuard: true,
    permittedUserGroups: ["admin","moderator","user"],
    topNav: <TopNav />,
    wallpaperImg: widowPaper, // REMEMBER <Wallpaper bgImg={wallpaperImg}/>
    main: <WidowClient experienceName="Widow"/>
  },

  // {
  //   path: "/IndirectControl/:guid",
  //   exact: true,
  //   spaceGate: false,
  //   authGuard: true,
  //   permittedUserGroups: ["admin","moderator"],
  //   topNav: <TopNav />,
  //   wallpaperImg: BarSignHand, // REMEMBER <Wallpaper bgImg={wallpaperImg}/>
  //   main: <ClientIndirectControl experienceName="IndirectControl"/>
  // },

  // {
  //   path: "/UseOnceTest/:guid",
  //   exact: true,
  //   spaceGate: false,
  //   authGuard: true,
  //   permittedUserGroups: ["admin","moderator"],
  //   topNav: <TopNav />,
  //   wallpaperImg: BarSignHand, // REMEMBER <Wallpaper bgImg={wallpaperImg}/>
  //   main: <UseOnceTestClient experienceName="UseOnce"/>
  // },
  {
    path: "/IndirectControl/:guid",
    exact: true,
    spaceGate: false,
    authGuard: true,
    permittedUserGroups: ["admin","moderator"],
    topNav: <TopNav />,
    wallpaperImg: BarSignHand, // REMEMBER <Wallpaper bgImg={wallpaperImg}/>
    main: <ClientIndirectControl experienceName="IndirectControl"/>
  },
  
  // system and misc
  {
    path: "/S3PermsTest",
    exact: true,
    spaceGate: false,
    authGuard: true,
    permittedUserGroups: ["admin","moderator","user"],
    topNav: <TopNav />,
    wallPaperImg: BarSignHand,
    main: <S3PermsTest />
  },
  {
    path: "/IOTPermsTest",
    exact: true,
    spaceGate: false,
    authGuard: true,
    permittedUserGroups: ["admin","moderator","user"],
    topNav: <TopNav />,
    wallPaperImg: BarSignHand,
    main: <IOTPermsTest />
  },
  {
    path: "/ShowControl",
    exact: true,
    spaceGate: false,
    authGuard: true,
    permittedUserGroups: ["admin","moderator"],
    topNav: <TopNav />,
    wallpaperImg: BarSignHand,
    main: <ShowControlBoard />
  },
  {
    path: "/logout",
    exact: true,
    spaceGate: false,
    authGuard: false,
    permittedUserGroups: [],
    topNav: <TopNav />,
    wallpaperImg: BarSignHand,
    main: <Logout />
  },
  {
    path: "/pubSubTest",
    exact: true,
    spaceGate: false,
    authGuard: true,
    permittedUserGroups: ["admin","moderator"],
    topNav: <TopNav />,
    wallpaperImg: BarSignHand,
    main: <PubSubTest />
  },
  {
    path: "/QRGen",
    exact: true,
    spaceGate: false,
    authGuard: true,
    permittedUserGroups: ["admin"],
    topNav: <TopNav />,
    wallpaperImg: BarSignHand,
    main: <GoldBorderModal modalContent={<QRGen guids={[]}/>} />
  },
  {
    path: "/createBurnables",
    exact: true,
    spaceGate: false,
    authGuard: true,
    permittedUserGroups: ["admin"],
    topNav: <TopNav />,
    wallpaperImg: BarSignHand,
    main: <CreateBurnable />
  },
  {
    path: "/batchPushBurnables",
    exact: true,
    spaceGate: false,
    authGuard: true,
    permittedUserGroups: ["admin"],
    topNav: <TopNav />,
    wallpaperImg: BarSignHand,
    main: <BatchPushBurnables />
  },
  {
    path: "/QRStamper",
    exact: true,
    spaceGate: false,
    authGuard: true,
    permittedUserGroups: ["admin"],
    topNav: <TopNav />,
    wallpaperImg: BarSignHand,
    main: <QRStamper />
  },
  {
    path: "/moderation",
    exact: true,
    spaceGate: false,
    authGuard: true,
    permittedUserGroups: ["admin","moderator"],
    topNav: <TopNav />,
    wallpaperImg: BarSignHand,
    main: <Moderation />
  },
  {
    path: "/",
    exact: true,
    spaceGate: true,
    authGuard: true,
    permittedUserGroups: [],
    topNav: <TopNav />,
    wallpaperImg: HBInterior,
    main: <HomeScreen />
  }
];

const RenderedRoutes = Routes.map(_route=>{
  // compose route base and sequentially wrap.
  let routeContent = _route.main
  if (!!_route.wallpaperImg) {
    routeContent = React.cloneElement(routeContent,{wallpaperImg:_route.wallpaperImg})
  }
  if (!!_route.spaceGate) {
    routeContent = <SpaceGate>{routeContent}</SpaceGate>
  }
  if (!!_route.permittedUserGroups?.length) {
    routeContent = <UserGroupGate redirect={true} groups={_route.permittedUserGroups}>{routeContent}</UserGroupGate>
  }
  if (!!_route.authGuard || !!_route.permittedUserGroups?.length){
    routeContent = <AuthGuard hideModal={_route.hideModal}>{routeContent}</AuthGuard>
  }
  routeContent = (<> 
    {_route.topNav}
    {routeContent}
    {/* {!!_route.wallpaperImg && <Wallpaper bgImg={_route.wallpaperImg}/>} */}
  </>)
  return (<Route exact={_route.exact} path={_route.path}>
    {routeContent}
  </Route>)
})


export {Routes,RenderedRoutes};