import React, {useState,useEffect,useRef} from "react"
import { PollyClient, SynthesizeSpeechCommand } from "@aws-sdk/client-polly";
import {Auth} from 'aws-amplify'


const PollyPlayer = (props)=>{
  const [credentials,setCredentials] = useState(null);
  const [polly,setPolly] = useState();
  // const selfInterrupt = props.selfInterrupt || false;
  // const audioContext = useRef(new (window.AudioContext || window.webkitAudioContext)())
  // const [audioContext] = useState(new (window.AudioContext || window.webkitAudioContext)())
  const audioContext = useRef(new (window.AudioContext || window.webkitAudioContext)())
  const audioStack = useRef();
  const src=useRef();
  const reader = useRef();
  
  useEffect(()=>{
    Auth.currentCredentials().then((creds)=>{
      setCredentials(creds)
    })
    //unmount
    return ()=>{
      try {
        audioContext.current.suspend();
        audioContext.current = null;
      }
      catch(err){}
    }
  },[]) // set credentials on load
  useEffect(()=>{
    // once credentials exist we can init our player
    if (!!credentials) {
      setPolly(new PollyClient({region:'us-west-2', credentials:credentials}))
    }
  },[credentials])
  useEffect(()=>{
    if (!!polly && !!props.phrase){
      // if (src.current) src.current.stop();
      audioContext.current.suspend();
      audioContext.current = new (window.AudioContext || window.webkitAudioContext)()
      if(props.phrase.length > 1 ) Speak();
    }
  },[polly,props.phrase])
  
  
  const Speak = ()=>{
    
    const speechParams =  {
      Engine: "neural",
      VoiceId: "Justin",
      Text: props.phrase,
      OutputFormat:"mp3"
    }
    const speechCommand = new SynthesizeSpeechCommand(speechParams);
    polly.send(speechCommand).then((res)=>{
  
      audioStack.current = [];
      let nextTime = 0;
      reader.current = res.AudioStream.getReader();
      const bufferReadSuccess = (buffer)=>{
        audioStack.current.push(buffer);
        if (audioStack.current.length) {
          read();
          scheduleBuffers();
        }
      }
      const bufferReadErr = (err)=>{
        console.log('bufferReadErr',err);
      }
      function read() {
        return reader.current.read().then(({value,done})=>{
          if (done) {
            return;
          }
          try {
            audioContext.current.decodeAudioData(value.buffer,bufferReadSuccess,bufferReadErr)
          }
          catch(err){
            
          }
        })
      }
      function scheduleBuffers(){
        while (audioStack.current.length) {
          const buffer = audioStack.current.shift();
          src.current = audioContext.current.createBufferSource();
          src.current.buffer = buffer;
          src.current.connect(audioContext.current.destination);
          if (nextTime === 0) {
            nextTime = audioContext.current.currentTime + 0.09;  /// add 500ms latency to work well across systems - tune this if you like
          }
          src.current.start(nextTime);
          nextTime += src.current.buffer.duration-0.041;
        }
      }
      read();
    })
    .catch((err)=>{console.log('speech command err',err)})
  }

  return null; //no render
}


export default PollyPlayer;