const request = require('request')
const OAuth = require('oauth-1.0a')
const crypto = require('crypto') // depenency package for OAuth-1.0a

async function generateMapToken() {
  const generate = async ()=>{
    return new Promise((res,rej)=>{
      // #1 Initialize OAuth with your HERE OAuth credentials from the credentials file that you downloaded above
      const oauth = OAuth({
          consumer: {
              key: process.env.REACT_APP_HEREMAPS_REST_KEY, //Access key
              secret: process.env.REACT_APP_HEREMAPS_REST_SECRET, //Secret key
          },
          signature_method: 'HMAC-SHA256',
          hash_function(base_string, key) {
              return crypto
                  .createHmac('sha256', key)
                  .update(base_string)
                  .digest('base64')
          },
      });
      // #2 building the request object.
      const request_data = {
          url: 'https://account.api.here.com/oauth2/token',
          method: 'POST',
          data: { grant_type: 'client_credentials' },
      };  
      // #3 Sending the request to get the access token
      request(
          {
              url: request_data.url,
              method: request_data.method,
              form: request_data.data,
              headers: oauth.toHeader(oauth.authorize(request_data)),
          },
          function (error, response, body) {

              if (response.statusCode == 200) {
                  const result = JSON.parse(response.body);
                  res(result);
              }
          }
      );
  })}
  return await generate()
}

const calculateRoute=(platform,loc,eventLatLong)=>{
  return new Promise((res,rej)=>{
    if(!platform || !loc) return;
    var router = platform.getRoutingService(null, 8);
    const routeRequestParams = {
      routingMode: 'fast',
      transportMode: 'car',
      origin: `${loc.lat},${loc.long}`,
      destination: `${eventLatLong.lat},${eventLatLong.long}`, 
      language: 'en-us',
      metricSystem: 'imperial',
      unitSystem: 'IMPERIAL',
      units: 'imperial',
      return: 'polyline,turnByTurnActions,actions,instructions,travelSummary,routeHandle'
    };

    const onSuccessV8=(resp)=>{
      const _route = resp.routes[0];
      if (!_route) {return}
      const _routeHandle = resp.routes[0].routeHandle

      res({route:_route,routeHandle:_routeHandle})
    }

    const onErrorV8=(err)=>{
    }

    router.calculateRoute(
      routeRequestParams,
      onSuccessV8,
      onErrorV8
    );
  })
}

const refreshRoute = (token,userLoc,eventLatLong,routeHandle)=>{
  if (!routeHandle) return;
  return new Promise(async (res,rej)=>{
    // const token = await getToken;
    const requestHeaders = { // Preparing the headers
      'Authorization': 'Bearer ' + token
    };
    // const url = "https://geocode.search.hereapi.com/v1/geocode?q=5+Rue+Daunou%2C+75000+Paris%2C+France"
    const url =`https://router.hereapi.com/v8/routes/${routeHandle}?transportMode=car&origin=${userLoc.lat},${userLoc.long}&units=imperial&return=polyline,turnByTurnActions,actions,instructions,travelSummary,routeHandle`
    fetch(url, { 
      method: 'get',
      headers: requestHeaders
    })
    .then(function(resp) {
        resp.json().then((data)=>{
          if (data.routes.length) {
            const _route = data.routes[0];
            const _routeHandle = data.routes[0].routeHandle
            // console.log('##refresh route _route',_route);
            res({route:_route,routeHandle:_routeHandle})
          }
          else if (data.notices?.length) {
            if (data.notices[0].code === 'couldNotMatchOrigin') {
              //DO REROUTE
              res('REROUTE_NEEDED');
            }
            else {
            }
            
          }
        })
        
    })
    .catch(function(err) {
        rej(err)
    });
  })
}

const clearRouteShapesFromMap=(map)=>{
  for (const object of map.getObjects()){
    if (object.id==='route'){
        map.removeObject(object);
        }
    }
}

const drawToMap = (map,mapObject)=>{
  map.addObject(mapObject);
}

const drawMultipleToMap = (map,mapObjects)=>{
  mapObjects.forEach((mapObject)=>{
    drawToMap(map,mapObject);
  })
}

export {generateMapToken,calculateRoute,refreshRoute,clearRouteShapesFromMap,drawToMap,drawMultipleToMap}