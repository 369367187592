import React,{useEffect,useContext} from 'react';
import {Auth} from 'aws-amplify';
import {useHistory} from "react-router-dom";
import {MainContext} from '../DataManager/';
import CircularProgress from '@material-ui/core/CircularProgress';

const Logout = ()=>{
  const {setUser,user,setMainModalContent,setLoadingModalContent} = useContext(MainContext);
  const history=useHistory();
  const logout = async()=>{
    
    // setMainModalContent(<CircularProgress />)
    setLoadingModalContent('default')
    Auth.signOut().then(()=>{
      setUser(null);
      setLoadingModalContent(null);
      // history.push("/")
      
    }).catch((err)=>{
      console.warn('error logging out:',err);
    })
  }
  useEffect(()=>{
    logout()
  },[]);

  useEffect(()=>{
    if(!user){
      setLoadingModalContent(null);
      // setMainModalContent(null);
      history.push("/");
    }
  },[user])

  return (<>{!!user && <span>Logging out...</span>} {!user && <span>Logged out</span>}</>)
}

export default Logout;