import React,{useRef} from "react";
import TextField from "@material-ui/core/TextField"
import DecoButton from "../../../Components/DecoButton"
import GoldBorderModal from "../../../Components/Modals/GoldBorderModal";
import styles from './styles.module.scss';
const WidowControlUI = (props)=>{
  const {guid,PubSub,sendTriggerOrDataBundle,experienceName} = props;
  // const fireTrigger = (e)=>{
  //   // e.currentTarget.classList.add(styles.disabled);
  //   PubSub.publish(`${process.env.REACT_APP_BUILD_ENV}/guid/${guid}/trigger`,JSON.stringify({cmd:'trigger',guid:guid}));
  // }
  const sendOn=()=>{sendTriggerOrDataBundle({cmd:'on'})}
  const sendOff=()=>{sendTriggerOrDataBundle({cmd:'off'})}
  return (<GoldBorderModal modalContent={
    <div className={styles.WidowControlUI}>
      {/* <TextField type="contained" /> */}
      <h1>{experienceName} - widow custom ui</h1>
      <DecoButton onMouseDown={sendOn} onTouchStart={sendOn} onTouchEnd={sendOff} onMouseUp={sendOff} fontSize="0.5rem" text='Fire trigger' />
    </div>
  }/>)
}

export default WidowControlUI
