import React from 'react';
import styles from './styles.module.scss';
import ClientIndirectControl from '../Standard/ClientIndirectControl';
import AwakenControlUI from '../../UIs/Awaken/AwakenControlUI';
import AwakenLauncherUI from '../../UIs/Awaken/AwakenLauncherUI';

const AwakenClient = (props)=>{
  const {experienceName="Awaken",wallpaperImg} = props;
  return (<ClientIndirectControl experienceName={experienceName} launcherComponent={AwakenLauncherUI} controlComponent={AwakenControlUI} wallpaperImg={wallpaperImg} />)
}

export default AwakenClient;