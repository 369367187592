import React from "react"
import queryString from 'query-string';
import { useEffect } from "react";


/***** MODEL ******* 

const MainParamBehaviors = {
    debug: {
      vals: {
        "0": disableDebug,
        "1": enableDebug
      },
      envs: ["dev","stage"]
    }
  }

*/


const QueryStringParser = ({paramBehaviors,...props})=>{
  const queryParams = queryString.parse(window.location.search);
  const parseParams = ()=>{
    const params = Object.keys(paramBehaviors);
    if (!params?.length) return null;
    params.map((param)=>{
      const paramSettings = paramBehaviors[param]
      if (!paramSettings?.envs) {
        // console.warn('QueryStringParser not passed allowed envs, param will never be parsed in lowers or prod')
      }
      else {
        if (process.env.NODE_ENV === 'development') {
          applyFuncs(param,paramSettings);
        }
        else if (process.env.NODE_ENV === 'production') {
          //check allowed envs against REACT_APP_BUILD_ENV
          if (!paramSettings?.envs) {
            return null;
          }
          else {
            const matchedEnv = paramSettings.envs.find((env)=>{
              return env === process.env.REACT_APP_BUILD_ENV;
            })
            if (!!matchedEnv) {
              applyFuncs(param,paramSettings);
            }
          }
        }
      }
    })
  }
  const applyFuncs = (param, paramSettings)=>{
    if (!!queryParams[param]) {
      // param is present
      const paramConfigVal = Object.keys(paramSettings.vals).find((val)=>{
        return val === queryParams[param]
      })
      if (!!paramConfigVal) {
        const queryConfigCommand = paramSettings.vals[paramConfigVal];
        try {
          queryConfigCommand();
        }
        catch(err){
          console.warn('error running query string command:',err)
        }
      }
    }
  }
  useEffect(parseParams,[]);
  return null
}

export default QueryStringParser