import React,{useRef,useState,useContext} from "react";
import {Auth} from 'aws-amplify';
import { fireFunctionFromKeycode } from "../../Utils/utils-main";
import TextField from '@material-ui/core/TextField'
import styles from './styles.module.scss'
import DecoButton from '../DecoButton'
import Login from '../Login';
import SignUp from '../SignUp';
import {MainContext} from "../DataManager";
import { actionIfEnterOrSpace } from "../../Utils/utils-main";
import VerifyResetPasswordForm from "../VerifyResetPasswordForm"
import { useTheme,makeStyles} from '@material-ui/core/styles';
const ResetPasswordForm=(props)=>{
  const usernameField = useRef();
  const [errorState,setErrorState] = useState(null);
  const {setMainModalTabs,setLoadingModalContent} = useContext(MainContext);
  const submitForgotPassword = async ()=>{
    setLoadingModalContent('default');
    Auth.forgotPassword(usernameField.current.querySelector('input').value).then((res)=>{
      const verificationTabs = [
        {tabTitle: 'Verify',tabContent:<VerifyResetPasswordForm codeDestination="email" username={usernameField.current.querySelector('input').value} />},
      ]
      setMainModalTabs(verificationTabs);
      setLoadingModalContent(null);
    }).catch((err)=>{
      setLoadingModalContent(null);
      if (err.code === 'UserNotFoundException') {
        setErrorState('User not found!')
      }
      else if (err.code === 'LimitExceededException') {
        setErrorState('Rate Limit Exceeded! Please try again later.')
        
      }
    })    
  }


  const textFieldStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      backgroundColor: '#fcfcfb',
      border: 'solid 1px rgba(0,0,0,0.2)',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // padding: '20px 12px 20px',
      padding: 0,
      borderRadius: '0',
      '&:hover': {
        border: 'solid 1px rgba(0,0,0,0.4)',
        backgroundColor: '#fff',
      },
      '&$focused': {
        border: 'solid 1px rgba(0,0,0,0.5)',
        backgroundColor: '#fff',
      },
      
    },
    input:{
      padding:'20px 12px 20px',
      fontFamily: 'haboro-contrast-normal, sans-serif',
      fontWeight: 400,
      fontStyle: 'normal',
      fontSize: '1.2em'
    },
    focused: {},
  }));
  const textFieldClasses=textFieldStyles()


  const backToLogin = ()=>{
    setMainModalTabs([
      {tabTitle: 'Login',tabContent:<Login/>},
      {tabTitle: 'Sign Up',tabContent:<SignUp />}
    ])
  }

  return (
  <div className={styles.ResetPasswordForm}>
    <form className={`${styles.form}`} onSubmit={(e)=>{e.preventDefault();e.stopPropagation();return null}}>
      <h1>Email</h1>
      <TextField InputProps={{classes:textFieldClasses,disableUnderline:true}} variant="filled" ref={usernameField} error={errorState} helperText={errorState} onKeyUp={(e)=>{fireFunctionFromKeycode(e,submitForgotPassword,13)}}/>
      <div className={styles.bottomRow}>
        <div className={styles.goBtn}>
          <DecoButton text="Reset" type="dark" fontSize="1.5rem" onClick={submitForgotPassword} onEnterOrSpace={submitForgotPassword}/>
        </div>
        <span className={styles.backToLogin} onClick={backToLogin} onKeyUp={(e)=>{actionIfEnterOrSpace(e,backToLogin)}} tabIndex="0">Back</span>
      </div>
    </form>
  </div>)
}


export default ResetPasswordForm;