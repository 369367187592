import React, { useEffect,useState,useRef } from 'react';
import Amplify, { PubSub,Auth } from 'aws-amplify';
import { AWSIoTProvider } from '@aws-amplify/pubsub';
import Button from "@material-ui/core/Button";
import { API } from 'aws-amplify';
import * as mutations from '../../graphql/mutations';
import {nanoid} from 'nanoid';
import styles from './styles.module.scss';
import GoldBorderModal from '../Modals/GoldBorderModal';
import DecoButton from '../../Components/DecoButton';
import CreateBurnablesForm from './CreateBurnablesForm';
import QRGen from './QRGen';
import {saveAs} from 'file-saver';

const CreateBurnable = (props)=>{
  const numToCreate = useRef();
  const experienceName = useRef();
  const usesEach = useRef();
  const results = useRef();
  const [view,setView] = useState(null);
  const Burnable = function(id,experience,usesLeft) {
    this.id = id;
    this.experience = experience;
    this.usesLeft = usesLeft;
    return this;
  }
  const saveJSONToFile = (object)=>{
    const _date = new Date();
    const dateCode = `${String(_date.getMonth()).padStart(2,0)}-${String(_date.getDay()).padStart(2,0)}-${String(_date.getFullYear())}_${String(_date.getHours() % 12  || 12).padStart(2,0)}-${String(_date.getMinutes()).padStart(2,0)}-${_date.getSeconds()}${(_date.getHours() > 12? 'p':'a')}`
    const filename = `burnableQR_${experienceName.current.value}_${dateCode}_qrCodeObjects.json`
    const blob = new Blob([JSON.stringify(object)], { type: "text/json" });
    saveAs(blob,filename);
  }
  const launchCreate = async ()=>{
    if (!experienceName.current.value && !numToCreate.current.value && !usesEach.current.value) {alert('please fill all fields');return}
    const _guids = Array(Number(numToCreate.current.value)).fill(1);
    const guids = _guids.map(()=>{
      return {
        guid: nanoid(),
        experienceName: experienceName.current.value,
        uses: Number(usesEach.current.value)
      }
    })
    saveJSONToFile(guids);
    const output = "generation done, file downloading"
    results.current.innerText = output;
    const _expName = String(experienceName.current.value); //must cache 
    setTimeout(()=>{setView(<QRGen guids={guids} experienceName={_expName}/>)},3000) // show result for 3 secs then show create guids form
  }
  useEffect(()=>{
    setView((<CreateBurnablesForm launchCreate={launchCreate} experienceName={experienceName} numToCreate={numToCreate} usesEach={usesEach} results={results} />))
  },[])

  

  return (<GoldBorderModal modalContent={view} />)
}

export default CreateBurnable;
