import React,{useRef} from "react";
import TextField from "@material-ui/core/TextField"
import DecoButton from "../../../Components/DecoButton"
import GoldBorderModal from "../../../Components/Modals/GoldBorderModal";
import styles from './styles.module.scss';
const SeanceControlUI = (props)=>{
  const {guid,PubSub,sendTriggerOrDataBundle,experienceName} = props;
  const sendOn=()=>{sendTriggerOrDataBundle({cmd:'on'})}
  const sendOff=()=>{sendTriggerOrDataBundle({cmd:'off'})}
  return (<GoldBorderModal modalContent={
    <div style={{paddingBottom:'35px'}} className={styles.SeanceControlUI}>
      {/* <TextField type="contained" /> */}
      <h1 style={{marginBottom:'65px'}}>Press the button to hold a seance</h1>
      <DecoButton onMouseDown={sendOn} onTouchStart={sendOn} onTouchEnd={sendOff} onMouseUp={sendOff} fontSize="1.9rem" textPadding="0 0.4em"  text='Seance' />
    </div>
  }/>)
}

export default SeanceControlUI
