import React,{useEffect,useContext,useRef} from "react";
import SlasherWelcome from '../../../Img/slasherWelcome.png'
import HBLogo512 from '../../../Img/HBLogo512.png'
import WelcomeGhost from '../../../Img/Wallpapers/welcomeGhost.jpg'
import Wallpaper from '../../Wallpaper'
import {MainContext} from '../../DataManager'
import styles from './styles.module.scss'
const ComeInsideScreen = (props)=>{
  const {setWallpaperImg} = useContext(MainContext)
  const ghostBreathAudio = document.querySelector('.ghostBreathAudio');
  useEffect(()=>{
    setWallpaperImg(WelcomeGhost)
    ghostBreathAudio.play();
  },[])
  return (<>
    <div className={styles.ComeInsideScreen}>
      <img className={styles.welcome} src={SlasherWelcome} alt='Welcome' />
      <h1 className={styles.title}>Won't you come inside?</h1>
      <img className={styles.footerLogo} src={HBLogo512} alt='Haunted Bar' />
    </div>
  </>)
}

export default ComeInsideScreen