import React,{useEffect, useState,useRef, useCallback} from 'react';


//SHOW VAR CONFIGS
const showChannelName = "TEO MIDI Bus 2";














// MIDI vars
const NOTE_ON = 144;
const NOTE_OFF = 128;
const MAX_VEL = 127;









// code
const MidiContext = React.createContext("MidiContext");


const getShowOutput = async (outputName) => {
  const _promise = new Promise((res, rej) => {
    let found = 0;
    navigator.requestMIDIAccess().then(function (access) {
      access.outputs.forEach((output) => {
        if (output.name == outputName) {
          found = 1;
          res(output);
        }
      });
      if (!found) {
        rej(`output ${outputName} not found`);
      }
    });
  });
  return _promise;
};


const MidiManager = (props)=>{
  const [showOutput,setShowOutput] = useState(null);
  useEffect(()=>{
    const _get = async()=>{
      const _showOutput = await getShowOutput(showChannelName)
      setShowOutput(_showOutput);
    }
    _get();
  },[])
  const sendNoteOnToMain = useCallback((noteNum)=>{
    showOutput.send([NOTE_ON, noteNum, MAX_VEL]);
  },[showOutput])

  const sendNoteOffToMain = useCallback((noteNum)=>{
    showOutput.send([NOTE_OFF, noteNum, MAX_VEL]);
  },[showOutput])

  let _midiContext;
  if (!showOutput) {
    // output not yet initialized, triggers will have stale state issues in MQTT callbacks, so we don't export the triggers until they have a showOutput to use, then the components using the triggers can just wait for them to be populated.
    _midiContext = {showOutput}
  }
  else {
    _midiContext = {showOutput,sendNoteOnToMain,sendNoteOffToMain}
  }
  return (<MidiContext.Provider value={_midiContext}>{props.children}</MidiContext.Provider>)
}

export {MidiContext,MidiManager}