import React,{useRef} from "react";
import TextField from "@material-ui/core/TextField"
import DecoButton from "../../../Components/DecoButton"
import GoldBorderModal from "../../../Components/Modals/GoldBorderModal";
import styles from './styles.module.scss';
const DefaultControlUI_ModeratedControl = (props)=>{
  const {guid,sendContentToModeration} = props;
  const textFieldRef = useRef();

  const _sendContentToModeration = ()=>{
    const val = textFieldRef.current.value;
    sendContentToModeration(val,"txt");
  }

  return (<GoldBorderModal modalContent={
    <div className={styles.DefaultIndirectControl}>
      <TextField ref={textFieldRef} type="contained" />
      <DecoButton onMouseDown={_sendContentToModeration} fontSize="0.5rem" text='Send Content' />
    </div>
  }/>)
}

export default DefaultControlUI_ModeratedControl
