import React,{useEffect,useState,useRef,useContext} from "react";
import * as dat from "dat.gui"
import {MainContext} from '../DataManager/';



const DebugControls = (props)=>{
  const {debugEnabled,spaceGateEnabled,setSpaceGateEnabled} = useContext(MainContext);
  // const [controls,setControls] = useState(null);
  const controls = useRef(null);
  useEffect(()=>{
  },[debugEnabled])

  if (debugEnabled) {
    if (!!controls.current) return null;
    controls.current = "pending";
    const DebugControls = new dat.GUI({name: 'Debug Controls'});

    //spaceGate
    let spaceGateConfig = {
      enabled: spaceGateEnabled
    }
    const spaceGateFolder = DebugControls.addFolder('SpaceGate')
    const spaceGateEnable = spaceGateFolder.add(spaceGateConfig, 'enabled')
    spaceGateEnable.onChange((val)=>{setSpaceGateEnabled(val)})

    // setControls(DebugControls);
    controls.current = DebugControls;
  }
  else {
    if (!!controls.current) {
      controls.current.destroy();
      controls.current = null;
    }
  }

  return null;
}

export default DebugControls;