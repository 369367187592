import React, { useState, useRef, useEffect, useMemo, useCallback } from "react";
import Button from "@material-ui/core/Button";
import styles from "./styles.module.scss";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import IconButton from '@material-ui/core/IconButton';
import CancelScheduleSendIcon from '@material-ui/icons/CancelScheduleSend';
import {useWindowSize} from "../../Hooks/generalHooks";
import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle'
const UserCam = (props) => {
  const { onCapture, containerRef, hideCam } = props;
  
  const [stream, setStream] = useState(null);
  const [vidScale,setVidScale] = useState({left:0,top:0, cropperScale:1,cropperLeftOffset:0,cropperTopOffset:0})
  const [vidScaleLastSet,setVidScaleLastSet] = useState(0);
  const windowSize = useWindowSize();
  const videoRef = useRef();
  const vidConstraints = {
        width: { ideal: 1920 },
        height: { ideal: 1080 } 
  }

  const dismount = useCallback(()=>{
    stream.getVideoTracks()[0].stop();
    stream.getVideoTracks()[0].stop();
    hideCam();
  },[stream])


  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ audio: false, video: vidConstraints })
      .then((stream) => {
        setStream(stream);
      });
  }, []);

  useEffect(() => {
    if (!stream) return;
    // now that the stream has been set we must center everything up
    // first we must center the video inside the cropper - we are expecting 1024x1024 images on the BE so the cropper will
    // ALWAYS be set to 1024x1024 and we'll scale it with CSS scale
    setScales();
    videoRef.current.srcObject = stream;;
  }, [stream]);

  useEffect(()=>{
    setScales();
  },[vidScaleLastSet])

  const setNewScales = ()=>{
    resetVidScale();
    setVidScaleLastSet(Date.now())
    // setTimeout(setScales,250)
  }
  const debouncedSetNewScales = useMemo(()=>{return throttle(setNewScales,250)},[])
  useEffect(()=>{
    debouncedSetNewScales()
  },[windowSize])

  const setScales = ()=>{
    if (!stream) return;
    const vidSettings = stream.getVideoTracks()[0].getSettings();
    const width = vidSettings.width;
    const height = vidSettings.height;
    const leftOffset = Math.abs(vidSettings.width-1024)/2; 
    const topOffset = Math.abs(vidSettings.height-1024)/2;
    const cropperScale = calculateCropperScale();
    const cropperLeftOffset = -(1024-(1024*cropperScale))/2;
    const cropperTopOffset = -(1024-(1024*cropperScale))/2;
    setVidScale({left:leftOffset,top:topOffset,cropperScale:cropperScale,cropperLeftOffset:'50%',cropperTopOffset:'50%'});
  }

  const calculateCropperScale = ()=>{
    // if (!containerRef?.current?.clientWidth) {return 0}
    if (containerRef.current.clientWidth > containerRef.current.clientHeight) {
      // landscape, height is limiting factor
      return containerRef.current.clientHeight / 1024
    }
    else {
      // portrait, width is limiting factor (or sq and we dont care)
      return containerRef.current.clientWidth / 1024
    }
  }

  const resetVidScale = ()=>{
    setVidScale({left:0,top:0, cropperScale:1,cropperLeftOffset:0,cropperTopOffset:0})
  }

  if (!vidScale) {return null}
  return (
    <>
      <div className={styles.camWrapper} style={{height:`${1024*vidScale.cropperScale}px`}}>
        
        <div className={styles.buttons}>
            <IconButton className={styles.backButton} onClick={dismount}>
              <CancelScheduleSendIcon />
            </IconButton>
          <div className={styles.captureBtn} onClick={()=>{onCapture(videoRef.current)}}></div>
        </div>
        <div className={styles.vidCropper} style={{transform:`translate3d(-50%,-50%,0) scale(${vidScale.cropperScale})`, left: `50%`,top: `50%`}}>
          <video style={{left:-vidScale?.left,top:-vidScale?.top}} ref={videoRef} playsInline={true} autoPlay={true}></video>
        </div>
      </div>
    </>
  );
};

export default UserCam;
