import React, {useRef,useState,useContext,useEffect,setLoadingModalContent} from 'react';
import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/Send';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import {Auth} from 'aws-amplify';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import { useHistory } from "react-router-dom";
import {MainContext} from '../DataManager/';
import initUser from './initUser';
import styles from './styles.module.scss'
import LinearProgress from '@material-ui/core/LinearProgress'
import Button from '@material-ui/core/Button'
import {cryptoDeChipChop} from '../../Utils/utils-main'

const VerificationSuccess = (props)=>{
  const {setModalContent,user} = props;
  const history = useHistory();
  const {setUser,setLoadingModalContent} = useContext(MainContext);
  const [signedIn, setSignedIn] = useState(false);
  useEffect(()=>{ 
    //we must manually log the user in here
    if (signedIn) {setLoadingModalContent(null); return;} 
    //since we're only reading vals, not writing, and prop drilling here will be too lifty we can just do a direct pull
    // const un = document.querySelector('#username').value;
    // const pw = document.querySelector('#password').value;
    const un = props.username;
    const pw = cryptoDeChipChop(props.h,props.s);
    // const initResult = initUser(user);
    // console.log('!ug initResult',initResult);
    Auth.signIn(un,pw)
    .then((signInResult)=>{
      Auth.currentCredentials()
        .then((identity)=>{
          const {identityId} = identity;
          setUser(signInResult);
          setTimeout(()=>{document.activeElement.blur()},100) // stop menu button from highlighting
          setLoadingModalContent(null);
          initUser(identityId)
          .then(()=>{ 
            // on success advance login flow
            setUser(signInResult);
            setTimeout(()=>{document.activeElement.blur()},100) // stop menu button from highlighting
            // no longer reached because authGuard renders different content as soon as user is populated. 
            // !!! If we wanted to re-enable the "let's go" button functionality here, we could instead not setUser and setSignedIn, then setUser when let's go btn is hit. however, I think instant passthrough is better: smoother, lower friction.
            // setSignedIn(true); 
            setLoadingModalContent(null);
          })
          .catch((err)=>{
          })  
        })
        .catch((err)=>{
        })
      
    })
    .catch((err)=>{console.warn('error in post verification auto signin:',err)});
  },[])

  // const nextPage = ()=>{
  //   setModalContent(null);

  //   // history.push('/postSignup')
  // }
  return (<div className={styles.verificationSuccess} >
        {!signedIn && <div style={{width:'100%'}}> <LinearProgress color="primary" /></div>}
        {/* {signedIn && <>
          <h3><BeenhereIcon className={styles.greenCheck} />Verification Success!</h3>
          <div style={{width:'100%',display:'flex',justifyContent:'center'}}> 
            <Button variant="contained" onClick={nextPage}>Let's go!</Button>
          </div>
        </>} */}
      </div>)
}

export default VerificationSuccess