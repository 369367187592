const letters = {
  A: [
    "M30.49,143.39C36.43,131.5,77.39,21.18,86.63,13.26",
    "M82,10.61c12.55,9.25,50.2,116.92,52.18,128.81",
    "M47.66,80.63c17.17-1.32,56.81-5.28,68.7-7.92"],
  B: [
    "M52.61,12.26c-1,7.59-9.75,127-8.26,133.76",
    "M52.94,21.17c19.78-7.24,43.22,2,47.06,15.2,4.46,15.35-14.86,33.69-49,45.91",
    "M49.63,86.57C74.07,75.34,93.41,72,102.31,96.48c4,10.9-13.21,35.83-55.48,39.63"
  ],
  C: [
    "M118.5,22.66C68.79-27.21-23.85,145.85,106.28,116"
  ],
  D: ["M58.22,8.79c-2.15,7.1-20.15,110.31-19.65,122.53",
      "M55.58,11.93c25.87-1.3,66.11,23.33,64.38,54.49-1.3,23.29-22,43.86-78.09,64.41"],
  E: [
    "M54.26,10.28C53.43,20.35,41.05,129.17,42,136.11",
    "M56.73,10.77c4.79-1.65,40.3,5.45,61.6,2.15",
    "M50.62,67.74c3.14-.49,25.27-3.8,47.23-1.81",
    "M43.52,136.28c10.74-2.65,33.69-5.62,67-7.44"
  ],
  F: [
    "M70,17.94c-7.27,31-17.67,85.21-14.7,115.92",
    "M66.7,20.41c11.72-2.47,48.71-2.11,57.47.33",
    "M57.42,86.14C72.78,82.5,93,84,102.86,84.52"
  ],
  G: [
    "M129.94,34.94c-16.34-7.76-32.66-5-46.94.5C70.14,40.37,58.76,49.84,53.16,61c-4.17,8.31-4.37,17.34-3.8,27.41,3.28,58.71,58.1,42.39,68,29.39,4.3-5.61,12.55-6.44,10.08-32.53",
    "M132.92,87.13c-4,.15-24.77-2.62-31.54.17"
  ],
  H: [
    "M46.22,22.06c-1.16,9.09-7.76,95.29-6.61,105.86",
    "M124.5,17.77c-1.49,14-7.77,100.9-6.78,115.1",
    "M35.82,81.18c6.79-2.36,25.84-1.89,44.75-1.81,20.64.09,41.11,0,45.25,2.14"
  ],
  I: [
    "M92.29,17.77C87.17,30.82,74,124.28,73.63,132.21"
  ],
  J: [
    "M116.07,21.4c-.49,21.47-.36,71-16.68,96.61C87.5,136.67,50.3,137.12,39,108.6"
  ],
  K: [
    "M54.15,7.86c.49,11.73-10.38,119.45-8.76,134.59",
    "M51.34,59.72c9.58-4.46,35.5-9.58,78.11-29.56",
    "M91.63,45.35c7.93,18,20.48,52.18,26.43,86"
  ],
  L: [
    "M61.25,15.62c-.08,5.12.48,23.63-.33,44.43-.87,22.17-3.09,47-2.15,60.93",
    "M58.11,122.14c9.25-.5,55.16,1,59.45,1.81"
  ],
  M: [
    "M30,26.85c8.75,6.28,43.76,66.55,45.74,74.32",
    "M29.54,27.84c-4.62,10.9-11.89,96-11.23,103",
    "M128.46,17.61c4.46,12.55,14.86,111.13,17.17,120.88",
    "M126.64,18.27c-6.44,5.28-43.1,76.29-49.54,81.9"
  ],
  N: [
    "M45.23,33.62c-3,10.24-10.9,78.44-14.2,89.67",
    "M45.39,34.78c-.82,15.69,57.14,86.7,60.44,90.83",
    "M141.17,24.71c-2.64,12-26.91,90.66-34.34,100.9"
  ],
  O: [
    "M80.24,22.06c58.46-3.79,50.74,96.55-5,102.55-55.15,6-48.22-96.93,4.13-97.76"
  ],
  P: [
    "M66,13.48C63.39,26.19,55.47,131.72,55.8,140.3",
    "M68.84,14.3C139.36,8,126,66.82,61.91,78.38"
  ],
  Q: [
    "M79.08,18.1c58.63,8.09,52.35,88.51,10.08,92.31-75.7,6.8-51.69-90.16-9.58-88.35",
    "M92.13,90.76c8.26,6.61,28.07,37.65,35.17,46.74"
  ],
  R: [
    "M50.84,17.11C75,7.2,102.68,16.22,110.62,32c9.42,18.66,4.79,30.88-47.06,49.54a37.93,37.93,0,0,1-9.41,2.81",
    "M50.51,17.94C52,27.18,53,125.44,56,136.83",
    "M88.83,71.94c2.8,7.59,26.42,55.48,30.55,62.09"
  ],
  S: [
    "M44.9,104c26.75,39.47,92-4.79,37.82-37.48-39.81-24-11.4-67.38,33.19-32.37"
  ],
  T: [
    "M31.36,28.17c12.22-1.81,74-12.22,88.84-11.89",
    "M75.12,24.21c.82,9.08,4.79,109.16,2.48,113.45"
  ],
  U: [
    "M43.58,19.92c-.33,14.86-20.82,105.53,27.91,104.69,28.73-.49,44.91-17,58.62-98.09"
  ],
  V:[
    "M41.6,37.75c4.62,9.25,20.64,77,22,82.9",
    "M138.86,26.52c-8.58,10.57-63.74,86-72.16,94"
  ],
  W: [
    "M48.53,122.63c-1.32-4.46-31.37-77.94-35.34-87.52",
    "M89.16,31.81c-7.93,20.8-33.69,81.91-39.8,87.85",
    "M89.82,35.77c1.32,22.13,8.91,57.14,21.3,84.22",
    "M154.55,39.73C148.28,48.32,120,106,113.27,119.17"
  ],
  X: [
    "M51.5,22.89c10.57,16.35,43.77,88.18,50.37,105.19",
    "M144.15,24.05c-22.3,17.83-50.7,52.84-110.81,103.54"
  ],
  Y: [
    "M135.56,12.82C121,27.35,83.21,66,77.93,73.75",
    "M33.83,30.65C47.54,47.17,69.34,72.1,76.44,75.57",
    "M78.42,75.57c-.49,7.43-3.14,56.15-4.13,63.58"
  ],
  Z: [
    "M39.28,30c13.38-2.31,61.93,1,70.68,1.49",
    "M112.61,32.8c-6.28,10.73-63.75,73.32-69.69,89.5",
    "M45.23,124.12c7.27-.5,52.68-7.27,69.52-7.1"
  ],
  0: [
    "M126.26,69.06c.12,25.68-22.79,47.23-45,50.21C60.69,122,43.69,98.45,43.69,72.78S60.51,25.93,81.26,26.29C100,26.62,126.09,35.21,126.26,69.06Z"
  ],
  1: [
    "M80.57,26.85c-.5,8.43-.83,94.63.66,99.91"
  ],
  2: [
    "M37.3,54.27C51.83,26,82.22,24.87,90.48,33.13,101.87,44.52,94.7,65.25,89,74.41A154.27,154.27,0,0,0,70.33,120",
    "M70.33,120c11.56-6.44,37-.82,45.41-3.63"
  ],
  3: [
    "M39.12,45.35c15.6-15.76,44.22-20.79,59.45-13.87,16.53,7.51,17,29.67,1.82,37.32",
    "M99.06,69.46c26.43-2.48,49.55,38.48-28.07,58",
  ],
  4: [
    "M96.26,22.39C95.1,30.82,88.17,115.7,86.18,126.1",
    "M62.57,21.73c-1.65,4.46-13.21,49.55-13.71,56.15",
    "M49.52,80.36c9.42-2.81,60.28,1,70,.16"
  ],
  5: [
    "M127.47,29.5c-8.26,0-58.63-2-68.37-2.65",
    "M57.12,26.36c-.83,5.28-2.48,36.33-4.95,42.11",
    "M54,69.62c20.09-6.05,35,2.67,42.44,14.54,9.91,15.68.83,33.68-39.47,36.33"
  ],
  6: [
    "M92.29,15.13c-14,.82-67.07,75.35-34.18,103,6.28,5.29,37.17,20.65,49.71-12.71,11.72-31.21-46.74-47.56-57.31-8.59"
  ],
  7: [
    "M45.89,22.06C57,17.77,100.55,15.79,108,14.63",
    "M109,16.78c-.33,7.27-31.21,112-31.37,122.53"
  ],
  8: [
    "M84.81,72c20.89-1,32.91-14.51,32.91-27.46S113.43,19.42,91,17.44c-14.76-1.3-26.91,14.13-26.91,27.08,0,11.81,4.07,22.23,20.75,25.15",
    "M83,73c15,3.22,26.59,11.71,26.59,26.83,0,17.56-15.12,31.79-33.77,31.79S43.69,118,43.69,100.44s14-35.83,38.48-28.9"
  ],
  9: [
    "M80.18,136.28c25.6-30.89,39.31-100.57,10.9-112.63-11.25-4.77-31.7,5.62-38.8,32.86s22,36,52.18,15.53"
  ],
  ".": [
    "M33.12,131.65c0,1.49-.66,5.45-.5,6.44"
  ],
  ",":[
    "M39.06,145.19c6.94-24.44,6.77-33.69-2.64-38.47"
  ],
  "?": [
    "M61.69,45.45c1.48-13.38,3-22.58,19.65-23C92,22.26,104,30.69,105.61,43,107.76,59.16,94.88,61.63,87,63.12,79.81,64.46,79.52,79,81.17,89.38",
    "M78.37,115.8c0,1.48-.66,4.29.16,5.94"
  ],
  ":":[
    "M38.9,47.93c0,3-.83,7.59,0,9.9",
    "M38.9,95.32a38.62,38.62,0,0,0,.49,6.61"
  ],
  ";":[
    "M38.78,57.06c-.5,1.82-3.47,8.59-3,10.74",
    "M34.65,99.5c2,1.49,6.44,5.78,4.13,8.92S20.57,105.58,29,93.72c5-6.93,16.18,6.11,16.68,13.88s-1,28.07-3.31,31.21"
  ],
  "!":[
    "M89.43,15.23c-1,9.08-10.73,73.49-10.9,80.26",
    "M74.4,128a37.88,37.88,0,0,1,.66,4"
  ],
  "@": [
    "M88.11,135c-28.9-6.27-54-23.45-54.33-52.84S47.32,18.86,76.39,18.37s68.2,14.53,52.51,51.35c-10.22,24-23.29,22.46-30.22,20.65s-1-30.88-10.74-37c-3.64-2.29-10.37,1.37-16.15,6.78C66.71,64.92,62.37,71,61.52,75.67c-1.81,9.91,11.73,39,28.57,6.61"
  ],
  "#": [
    "M76.22,14.4c-2.48,7.77-21.14,109.32-21.14,118.9",
    "M107.27,10.61c.33,7.59-17.84,120.55-17.18,127.48",
    "M27.5,59.49a466.47,466.47,0,0,1,96.44-9.09",
    "M20.73,99.78a418.15,418.15,0,0,1,97.44-16.51"
  ],
  "$":[
    "M119.65,52.55C62.35,4,36.46,66.27,73.58,79.63,127.25,99,91.91,144.86,37.74,106.88",
    "M88.94,7.63C73.25,63.45,80,72.37,56.24,150.31"
  ],
  "%":[
    "M102.81,28.44C81.5,60.15,69.61,80.29,48.48,131.32",
    "M59.21,39.5c0,10.58-22,32.54-37.32,15C10.77,41.86,29.16,18.37,41.54,19,51.56,19.56,59.21,28.93,59.21,39.5Z",
    "M147.56,103.91c0,13.13-10.6,23.78-22.46,23.78s-20.44-11-20.64-24.11c-.17-10.9,5-24,16.84-24S147.56,90.78,147.56,103.91Z"
  ],
  "^":[
    "M78.37,26C69,38.84,67,43.8,59.54,53.71",
    "M78.37,26c7.92,6.61,13,16.35,22.95,27.25"
  ],
  "&":[
    "M111.56,37.52c-11.23-5.28-33.19-8.58-41.28,10.57-4.68,11.08,3.13,18.33,9.41,19.49",
    "M78.7,68.73c-5-.16-33.86,8.26-23.29,34.19,5,12.29,22.3,30.22,51.86.33",
    "M89.93,6.31c-.66,5.12.16,19.16-1.32,24.61",
    "M76.72,122.4c-1.49,6.77.33,17-1.16,24.77"
  ],
  "*": [
    "M103.8,41.32C92.4,52.22,72.09,94,68.13,105.23",
    "M114.37,63.45c-24.61.49-55.32,10.24-64.24,15",
    "M72.42,39.83c16.19,25.11,14.37,39.8,26.42,62.43"
  ],
  "(":[
    "M120.48,6.48C93.56,14.4,50.79,77.16,97.85,144.37"
  ],
  ")":[
    "M34.27,143.78C60.55,133.93,98.68,68.26,46.9,4.62"
  ],
  "-":[
    "M60.37,77c15.85-.82,36.66-1,45.08-1.16"
  ],
  "_":[
    "M51.28,135.45c24.44-2.31,36-.66,60.61-1.16"
  ],
  "+":[
    "M47.82,75.34c14.53-2,53.83-6.28,62.09-4.46",
    "M82.66,39.83c.17,13.55-1.16,36.34.66,71.67"
  ],
  "=":[
    "M62.84,64.44c6.11.17,23.78,1.32,37.49,0",
    "M60.53,86.4c12.72.17,28.3.72,38,1.16"
  ],
  "~": [
    "M56.57,79.8c3.11-5,8.26-10,14-10.41,8.42-.66,9.57,12.72,20.47,12.89s13.21-5.62,15.2-9.09"
  ],
  "`": [
    "M105.12,32.73c3.8,4.46,13.21,16.35,16.18,18"
  ],
  "<":[
    "M108.59,43c-8.42,3.64-43.76,20.31-53.34,30.39",
    "M55.08,74.51c6.11,4.46,37.49,25.93,46.24,30.06"
  ],
  ">":[
    "M108.59,73C102.48,68.57,71.1,47.1,62.35,43",
    "M55.08,104.57c8.42-3.63,43.76-20.31,53.34-30.39"
  ],
  "/":[
    "M114.86,29.43c-8.09,7.76-55.15,91.65-60.93,93.8"
  ],
  "[":[
    "M104.31,23c-1.65,6.94-7.92,96.11-8.25,103.87",
    "M131.23,21.5c-5.78.83-20.64,2.65-24.61,2.65",
    "M121.49,123.56c-7.6,1.65-17.51,4-22.46,4.62"
  ],
  "]":[
    "M51.61,126.53c1.66-6.93,7.93-96.11,8.26-103.87",
    "M24.7,128c5.78-.83,20.64-2.64,24.6-2.64",
    "M32.13,23.32c7.59-1.65,19.81-1.32,24.77-2"
  ],
  "{":[
    "M120.31,11.27c-9.74-.17-24,3.37-25.76,15.19C92.07,43,97.69,62.46,78,72.86c31.37,13.38,5.61,20,15,56.15,3.89,14.93,17.34,11.56,22.13,11.56"
  ],
  "}":[
    "M28,140.89c9.74.16,24-3.38,25.76-15.19,2.48-16.52-3.14-36,16.52-46.41-31.38-13.37-5.62-20-15-56.14C51.36,8.22,37.91,11.59,33.12,11.59"
  ],
  "|":[
    "M85.47,20.35c-2.64,25.76-9.08,102.38-8.42,109.15"
  ],
  "\\":[
    "M53.93,29.43c8.09,7.76,55.15,91.65,60.93,93.8"
  ],
  "'": [
    "M86,12.92c-5.28,9.08-16,24.44-11.39,35.67s11.39,14,14.2,11.23c3.8-3.8-.16-14-6.11-15.2s.66,10.24,3.14,11.07"
  ],
  "lqo":[
    "M83.32,10.77c-2.81,5.29-14,27.58-7.6,41.29s17,5.28,10.57-1.66c0,0-4.29-2.47-6.27-1.32s3.63,5.29,3.63,5.29",
    "M109.74,10.44c-4,4.62-12.38,26.92-5.94,40.62s18-1.48,11.56-8.42c0,0-10.08-6.93-9.75-1.65a11,11,0,0,0,4.63,8.59s4.62-2.15,2.64-4.46"
  ],
  "rqo":[
    "M40,10.44c4,4.62,12.39,26.92,6,40.62s-18-1.48-11.56-8.42c0,0,10.07-6.93,9.74-1.65a11,11,0,0,1-4.62,8.59s-4.63-2.15-2.65-4.46",
    "M66.4,10.77c2.81,5.29,14,27.58,7.6,41.29S57,57.34,63.43,50.4c0,0,4.3-2.47,6.28-1.32s-3.64,5.29-3.64,5.29"
  ],
  "lsqo":[
    "M113,11.57c-5.28,9.09-16,24.44-11.39,35.67s11.38,14,14.2,11.23c3.8-3.8-.17-14-6.11-15.19s.66,10.24,3.13,11.06"
  ],
  "rsqo":[
    "M39,11.57C44.27,20.66,55,36,50.38,47.24S39,61.28,36.17,58.47c-3.79-3.8.17-14,6.11-15.19s-.66,10.24-3.13,11.06"
  ]
}
export default letters;