import React from "react";
import styles from './styles.module.scss';
import DecoButtonLightLeftCap from '../../Img/DecoButtonLightLeftCap.svg'
import DecoButtonLightRightCap from '../../Img/DecoButtonLightRightCap.svg'
import DecoButtonDarkLeftCap from '../../Img/DecoButtonDarkLeftCap.svg'
import DecoButtonDarkRightCap from '../../Img/DecoButtonDarkRightCap.svg'
import {actionIfEnterOrSpace} from "../../Utils/utils-main"
const DecoButton = React.forwardRef((props,ref)=>{
  const type = props.type || "light";
  const fontSize = props.fontSize || "1em";
  const textPadding = props.textPadding || '0 1em';
  const btnHeight = props.btnHeight || "2em";
  return (
    <>
      <div className={styles.DecoButton} style={{fontSize:fontSize, height:btnHeight}} ref={ref} {...props} onClick={props.onClick||null} onKeyUp={(e)=>{if(props.onEnterOrSpace)actionIfEnterOrSpace(e,props.onEnterOrSpace)}} tabIndex="0" onFocus={props.onFocus} onBlur={props.onBlur}>
        <div className={styles.noSelecty} />
        <div className={`${styles.leftEndcap} left`}>
          {type==="light" && <img src={DecoButtonLightLeftCap} alt=''/>}
          {type==="dark" && <img src={DecoButtonDarkLeftCap} alt=''/>}
        </div>
        <div style={{padding:`${textPadding}`,fontSize:`${fontSize}`}} className={`${type==='dark'? styles.textAreaDark : styles.textArea}`}>
          <span style={{fontSize:`${fontSize}`, padding:`${textPadding}`}}>{props.text}</span>
        </div>
        <div className={`${styles.rightEndcap} right`}>
          {type==="light" && <img src={DecoButtonLightRightCap} alt=''/>}
          {type==="dark" && <img src={DecoButtonDarkRightCap} alt=''/>}
        </div>
      </div>
    </>
  )
})

export default DecoButton