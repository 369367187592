import React, {useContext} from 'react';
import {useHistory} from "react-router-dom";
import {MainContext} from '../DataManager';

const UserGroupGate = (props)=>{
  const {children,groups,redirect} = props;
  if (!Array.isArray(groups)) {throw new Error('UserGroupGate: groups must be an array')}
  const {user,loading} = useContext(MainContext);
  const history=useHistory();
  let pass = false;
  if (loading) {
    return null;
  }
  if (!user) {
    if (redirect) {
      history.push("/");
    }
    return null;
  }
  const userGroups = user.signInUserSession.getIdToken().payload['cognito:groups'];
  groups.map((group)=>{
    if (userGroups && userGroups.indexOf(group) !== -1) {
      pass = true;
    }
    return null;
  })
  if (!pass && redirect) {
    history.push("/");
    return (<></>)
  }

  return (<>{!!pass && children}
  {!pass && ''}</>)
}

export default UserGroupGate;