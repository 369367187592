import React,{useCallback, useEffect, useRef, useState} from 'react';
import styles from './styles.module.scss';
// import QRCodeStyling from 'qr-code-styling';
// import qrCodeVersionsAtEccLevels from './QRCodeVersionsAtECCLevels'
import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, Grid, MenuItem, Radio, Select, TextField } from '@material-ui/core';
import DefaultQR from './DefaultQR';
import { makeStyles, useTheme } from '@material-ui/styles';
import { ExpandMore } from '@material-ui/icons';
// import {PhotoshopPicker} from 'react-color';
import ColorPicker from '../ColorPicker';
import SwatchButton from '../SwatchButton'
// import GradientButton from '../GradientButton'
import RotationWidget from '../RotationWidget'
import GradientButton from '../GradientButton';
import JSZip from 'jszip'
import {saveAs} from 'file-saver';

const defaultGradientColors = [
  {
    offset: 0,
    color: '#00F6FD'
  },
  {
    offset: 1,
    color: '#FD00B7'
  }
];


const QRGen = (props)=>{
  const {guids} = props;
  const theme = useTheme();
  const codeWrapper = useRef();
  const codeWrapperInner = useRef();
  const [qrCode,setQrCode] = useState(DefaultQR)
  //qr opts
  const [url,setUrl] = useState('')
  const [eccLevel,setEccLevel] = useState('Q')
  const fileInput = useRef();
  const [logoImage,setLogoImage] = useState(null)
  const [logoSize,setLogoSize] = useState('0.4')
  const [logoMargin,setLogoMargin] = useState(0);
  const [imageHideDots,setImageHideDots] = useState(false);
  const imageHideDotsCheckbox = useRef();
  const [dotsType,setDotsType] = useState('square')
  const [dotsColorType,setDotsColorType] = useState('single-color')
  const [dotsSingleColor,setDotsSingleColor] = useState('#000');
  const [colorPickerColor,setColorPickerColor] = useState(dotsSingleColor)
  const [colorPickerColorSetter,setColorPickerColorSetter] = useState(()=>{return setDotsSingleColor})
  const [showColorPicker,setShowColorPicker] = useState(false);

  const [dotsGradientColors,setDotsGradientColors] = useState(qrCode?.dotsOptions?.gradient?.colorStops)
  const [dotsGradientType,setDotsGradientType] = useState('linear')
  const [dotsGradientAngle,setDotsGradientAngle] = useState(0);

  const [cornerSquareType,setCornerSquareType] = useState('square');
  const [cornerSquaresColorType,setCornerSquaresColorType] = useState('single-color');
  const [cornerSquaresSingleColor,setCornerSquaresSingleColor] = useState('#000')
  const [cornerSquaresGradientColors,setCornerSquaresGradientColors] = useState(qrCode?.dotsOptions?.gradient?.colorStops)
  const [cornerSquaresGradientType,setCornerSquaresGradientType] = useState('linear')
  const [cornerSquaresGradientAngle,setCornerSquaresGradientAngle] = useState(0);

  const [cornerDotsType,setCornerDotsType] = useState(qrCode?.cornersDotOptions?.type || 'square');
  const [cornerDotsColorType,setCornerDotsColorType] = useState(qrCode?.cornersDotOptions?.type || 'single-color');
  const [cornerDotsSingleColor,setCornerDotsSingleColor] = useState('#000')
  const [cornerDotsGradientColors,setCornerDotsGradientColors] = useState(qrCode?.cornersDotOptions?.gradient?.colorStops)
  const [cornerDotsGradientType,setCornerDotsGradientType] = useState('linear')
  const [cornerDotsGradientAngle,setCornerDotsGradientAngle] = useState(0);
  
  const [backgroundColorType,setBackgroundColorType] = useState(qrCode?.cornersDotOptions?.type || 'single-color');
  const [backgroundSingleColor,setBackgroundSingleColor] = useState(qrCode?.backgroundOptions?.color || '#fff')
  const [backgroundGradientColors,setBackgroundGradientColors] = useState(qrCode?.cornersDotOptions?.gradient?.colorStops)
  const [backgroundGradientType,setBackgroundGradientType] = useState('linear')
  const [backgroundGradientAngle,setBackgroundGradientAngle] = useState(0);

  const primaryOptionsAccordion = useRef();
  // const scrollListener = useRef();
  const outputCanv = useRef();
  const urlField = useRef();
  

  window.addEventListener('scroll',(e)=>{
  })

  useEffect(()=>{
    const cl = styles.QRGen;
    const modalInner = document.querySelector(`.${cl}`).parentElement;
    modalInner.addEventListener('scroll',(e)=>{
      if (!codeWrapper.current) return;
      codeWrapper.current.style.top = `${modalInner.scrollTop}px`
    })
    primaryOptionsAccordion.current.click()
  },[])


  useEffect(()=>{
    if (!qrCode) return;
    qrCode.append(codeWrapperInner.current)
  },[qrCode])

  useEffect(()=>{
    if (!qrCode) return;
    else if (url==='' && qrCode._options.data==='https://www.youtube.com/watch?v=dQw4w9WgXcQ') return;
    qrCode.update({ 
      data: url
    });
  },[url])

  useEffect(()=>{
    if (!qrCode) return;
    qrCode.update({ 
      qrOptions: {
        errorCorrectionLevel: eccLevel
      }
    });
  },[eccLevel])

  useEffect(()=>{
    if(!qrCode) return;
    qrCode.update({
      image: logoImage
    })
  },[logoImage])

  useEffect(()=>{
    if(!qrCode) return;
    qrCode.update({
      imageOptions: {
        imageSize: logoSize
      }
    })
  },[logoSize])

  useEffect(()=>{
    if(!qrCode) return;
    qrCode.update({
      imageOptions: {
        margin: logoMargin
      }
    })
  },[logoMargin])

  useEffect(()=>{
    if(!qrCode) return;
    qrCode.update({
      imageOptions: {
        hideBackgroundDots: imageHideDots
      }
    })
  },[imageHideDots])

  useEffect(()=>{
    if(!qrCode) return;
    qrCode.update({
      dotsOptions: {
        type: dotsType
      }
    })
  },[dotsType])

  useEffect(()=>{
    if(!qrCode || dotsColorType !== 'single-color') return;
    qrCode.update({
      dotsOptions: {
        color: dotsSingleColor,
        gradient: null
      }
    })
  },[dotsSingleColor,dotsColorType])

  useEffect(()=>{
    if (!dotsGradientColors) return;
    qrCode.update({
      dotsOptions: {
        gradient: {
          colorStops: dotsGradientColors
        }
      }
    })
  },[dotsGradientColors,qrCode])

  useEffect(()=>{
    if (dotsColorType !== 'gradient') return;
    qrCode.update({
      dotsOptions:{
        gradient:{
          rotation: dotsGradientAngle
        }
      }
    })
  },[dotsGradientAngle])

  useEffect(()=>{
    qrCode.update({
      cornersSquareOptions:{
        type: cornerSquareType
      }
    })
  },[cornerSquareType])

  useEffect(()=>{
    if(!qrCode || cornerSquaresColorType !== 'single-color') return;
    qrCode.update({
      cornersSquareOptions:{
        color: cornerSquaresSingleColor,
        gradient: null
      }
    })
  },[cornerSquaresColorType,cornerSquaresSingleColor])

  useEffect(()=>{
    if (!cornerSquaresGradientColors) return;
    qrCode.update({
      cornersSquareOptions: {
        gradient: {
          colorStops: cornerSquaresGradientColors
        }
      }
    })
  },[cornerSquaresGradientColors,qrCode])

  useEffect(()=>{
    if (cornerSquaresColorType !== 'gradient') return;
    qrCode.update({
      cornersSquareOptions:{
        gradient:{
          rotation: cornerSquaresGradientAngle
        }
      }
    })
  },[cornerSquaresGradientAngle])

  useEffect(()=>{
    if(!qrCode) return;
    qrCode.update({
      cornersDotOptions: {
        type: cornerDotsType
      }
    })
  },[cornerDotsType])

  
  useEffect(()=>{
    if(!qrCode || cornerDotsColorType !== 'single-color') return;
    qrCode.update({
      cornersDotOptions:{
        color: cornerDotsSingleColor,
        gradient: null
      }
    })
  },[cornerDotsColorType,cornerDotsSingleColor])

  useEffect(()=>{
    if (!cornerDotsGradientColors) return;
    qrCode.update({
      cornersDotOptions: {
        gradient: {
          colorStops: cornerDotsGradientColors
        }
      }
    })
  },[cornerDotsGradientColors,qrCode])

  useEffect(()=>{
    if (cornerDotsColorType !== 'gradient') return;
    qrCode.update({
      cornersDotOptions:{
        gradient:{
          rotation: cornerDotsGradientAngle
        }
      }
    })
  },[cornerDotsGradientAngle])


  useEffect(()=>{
    if(!qrCode || backgroundColorType !== 'single-color') return;
    qrCode.update({
      backgroundOptions:{
        color: backgroundSingleColor,
        gradient: null
      }
    })
  },[backgroundColorType,backgroundSingleColor])

  useEffect(()=>{
    if (!backgroundGradientColors) return;
    qrCode.update({
      backgroundOptions: {
        gradient: {
          colorStops: backgroundGradientColors
        }
      }
    })
  },[backgroundGradientColors,qrCode])

  useEffect(()=>{
    if (backgroundColorType !== 'gradient') return;
    qrCode.update({
      backgroundOptions:{
        gradient:{
          rotation: backgroundGradientAngle
        }
      }
    })
  },[backgroundGradientAngle])
  



  //handlers

  const handleUrlChange = (e)=>{
    setUrl(e.target.value)
  }

  const handleEccChange=(e)=>{
    setEccLevel(e.target.value)
  }

  const openFilePicker=(e)=>{
    fileInput.current.click();
  }

  const handleLogoImageChange=(e)=>{
    if (!e.target.files.length) {setLogoImage('');return}
    const reader = new FileReader();
    reader.addEventListener('load',()=>{
      setLogoImage(reader.result)
    })
    reader.readAsDataURL(e.target.files[0])
  }

  const handleLogoSizeChange=(e)=>{
    setLogoSize(e.target.value);
  }

  const handleLogoMarginChange=(e)=>{
    setLogoMargin(e.target.value)
  }

  const handleChangeImageHideDots=(e)=>{
    setImageHideDots(!imageHideDots);
  }

  const handleDotsStyleChange=(e)=>{
    setDotsType(e.target.value);
  }

  const handleDotsColorTypeChange=(e)=>{
    if (e.target.value === 'gradient') {
      //get gradient cols from state or default
      const gradCols = dotsGradientColors || defaultGradientColors;
      //update qr code with gradient prop
      qrCode.update({
        dotsOptions:{
          gradient: {
            colorStops: gradCols
          }
        }
      })
      setDotsGradientColors(gradCols);
    }
    setDotsColorType(e.target.value);
  }
  const handleDotsSingleColorButton=useCallback((e)=>{
    setColorPickerColor(dotsSingleColor);
    setColorPickerColorSetter(()=>{return setDotsSingleColor});
    setShowColorPicker(true)
  },[dotsSingleColor,setDotsSingleColor])

  // it appears that only 2 gradient stops are supported `
  // const addGradientStop=()=>{
  //   const _cols = [...dotsGradientColors];
  //   _cols.push({
  //     offset: _cols.length,
  //     color: '#000000'
  //   })
  //   setDotsGradientColors(_cols);
  // }


  const handleDotsGradientColorChange = (colorStop,gradCols)=>{
    
    const changeFunc = (col)=>{
      let _gradCols = [...gradCols];
      _gradCols.splice(colorStop.offset,1,{offset:colorStop.offset,color:col})
      setDotsGradientColors(_gradCols)
      setColorPickerColor(col)
    }
    
    setColorPickerColorSetter(()=>{return changeFunc})
    setColorPickerColor(colorStop.color)
    setShowColorPicker(true);
  }

  const handleDotsGradientTypeChange = (e)=>{
    qrCode.update({
      dotsOptions:{
        gradient: {
          type: e.target.value
        }
      }
    })
    setDotsGradientType(e.target.value)
  }

  const handleCornerSquaresTypeChange = (e)=>{
    setCornerSquareType(e.target.value);
  }

  const handleCornerSquaresColorTypeChange = (e)=>{
    if (e.target.value === 'gradient') {
      //get gradient cols from state or default
      const gradCols = cornerSquaresGradientColors || defaultGradientColors;
      //update qr code with gradient prop
      qrCode.update({
        cornerSquaresOptions:{
          gradient: {
            colorStops: gradCols
          }
        }
      })
      setCornerSquaresGradientColors(gradCols);
    }
    setCornerSquaresColorType(e.target.value);
  }

  const handleCornerSquaresSingleColorButton = (e)=>{
    setColorPickerColor(cornerSquaresSingleColor);
    setColorPickerColorSetter(()=>{return setCornerSquaresSingleColor});
    setShowColorPicker(true)
  }

  const handleCornerSquaresGradientColorChange = (colorStop,gradCols)=>{
    
    const changeFunc = (col)=>{
      let _gradCols = [...gradCols];
      _gradCols.splice(colorStop.offset,1,{offset:colorStop.offset,color:col})
      setCornerSquaresGradientColors(_gradCols)
      setColorPickerColor(col)
    }
    
    setColorPickerColorSetter(()=>{return changeFunc})
    setColorPickerColor(colorStop.color)
    setShowColorPicker(true);
  }

  const handleCornerSquaresGradientTypeChange = (e)=>{
    qrCode.update({
      cornersSquareOptions:{
        gradient: {
          type: e.target.value
        }
      }
    })
    setCornerSquaresGradientType(e.target.value)
  }

  const handleCornerDotsTypeChange=(e)=>{
    setCornerDotsType(e.target.value);
  }


  
  const handleCornerDotsColorTypeChange = (e)=>{
    if (e.target.value === 'gradient') {
      //get gradient cols from state or default
      const gradCols = cornerSquaresGradientColors || defaultGradientColors;
      //update qr code with gradient prop
      qrCode.update({
        cornersDotOptions:{
          gradient: {
            colorStops: gradCols
          }
        }
      })
      setCornerDotsGradientColors(gradCols);
    }
    setCornerDotsColorType(e.target.value);
  }

  const handleCornerDotsSingleColorButton = (e)=>{
    setColorPickerColor(cornerDotsSingleColor);
    setColorPickerColorSetter(()=>{return setCornerDotsSingleColor});
    setShowColorPicker(true)
  }

  const handleCornerDotsGradientColorChange = (colorStop,gradCols)=>{
    
    const changeFunc = (col)=>{
      let _gradCols = [...gradCols];
      _gradCols.splice(colorStop.offset,1,{offset:colorStop.offset,color:col})
      setCornerDotsGradientColors(_gradCols)
      setColorPickerColor(col)
    }
    
    setColorPickerColorSetter(()=>{return changeFunc})
    setColorPickerColor(colorStop.color)
    setShowColorPicker(true);
  }

  const handleCornerDotsGradientTypeChange = (e)=>{
    qrCode.update({
      cornersDotOptions:{
        gradient: {
          type: e.target.value
        }
      }
    })
    setCornerDotsGradientType(e.target.value)
  }





  const handleBackgroundColorTypeChange = (e)=>{
    if (e.target.value === 'gradient') {
      //get gradient cols from state or default
      const gradCols = backgroundGradientColors || defaultGradientColors;
      //update qr code with gradient prop
      qrCode.update({
        backgroundOptions:{
          gradient: {
            colorStops: gradCols
          }
        }
      })
      setBackgroundGradientColors(gradCols);
    }
    setBackgroundColorType(e.target.value);
  }

  const handleBackgroundSingleColorButton = (e)=>{
    setColorPickerColor(backgroundSingleColor);
    setColorPickerColorSetter(()=>{return setBackgroundSingleColor});
    setShowColorPicker(true)
  }

  const handleBackgroundGradientColorChange = (colorStop,gradCols)=>{
    
    const changeFunc = (col)=>{
      let _gradCols = [...gradCols];
      _gradCols.splice(colorStop.offset,1,{offset:colorStop.offset,color:col})
      setBackgroundGradientColors(_gradCols)
      setColorPickerColor(col)
    }
    
    setColorPickerColorSetter(()=>{return changeFunc})
    setColorPickerColor(colorStop.color)
    setShowColorPicker(true);
  }

  const handleBackgroundGradientTypeChange = (e)=>{
    qrCode.update({
      backgroundOptions:{
        gradient: {
          type: e.target.value
        }
      }
    })
    setBackgroundGradientType(e.target.value)
  }

  const doOutput = ()=>{
    // loop through each guid in the array 
    const urlBase = urlField.current.querySelector('input').value.replace(/\/$/,'');
    const outputPngs = [];

    const delayedMap = (idx)=>{ // like array.map, but with a slight delay at the end so the user gets a glimpse of fast-changing output
      const guid = guids[idx].guid;
      qrCode.update({
        data: urlBase+`/${guid}`,
      })
      // qrCode.download({ name: `qr_${idx}`, extension: "svg" });
      // outputCanv.current.innerHTML = `<h1 style="background:#000;padding:0.5em;">Generating</h1>`
      qrCode.append(outputCanv.current)
      const _canv = outputCanv.current.querySelector('canvas')
      
      setTimeout(()=>{// some kind of draw call/racecon issue with qr-code-styling, needs this slight timeout to display the canv before we can read it
         _canv.toBlob((blob)=>{
              outputPngs.push(blob)
          
              if(idx < guids.length-1) setTimeout(()=>{delayedMap(idx+1)},30);
              else { // mapping done, zip it up
                let zip = new JSZip();
                const _date = new Date();
                const dateCode = `${String(_date.getMonth()).padStart(2,0)}-${String(_date.getDay()).padStart(2,0)}-${String(_date.getFullYear())}_${String(_date.getHours() % 12  || 12).padStart(2,0)}-${String(_date.getMinutes()).padStart(2,0)}${(_date.getHours() > 12? 'p':'a')}`
                outputPngs.map((png,idx)=>{
                  // let blob = new Blob([svg], {type: 'image/svg+xml'});
                  // const _svg = `<?xml version="1.0" standalone="no"?>
                  // <svg xmlns="http://www.w3.org/2000/svg" width="500" height="500">${svg.innerHTML}</svg>`
                  zip.file(`burnableQR_${props.experienceName}_${dateCode}_qr${idx}.png`,png)
                })
                outputCanv.current.innerHTML = `<h1 style="background:#000;padding:0.5em;">Creating and downloading zip file...</h1>`
                zip.generateAsync({type:'blob'}).then((content)=>{
                  saveAs(content,`BurnableQRCodes_${dateCode}.zip`)
                  setTimeout(()=>{
                    outputCanv.current.innerHTML = `<h1 style="background:#000;padding:0.5em;">Zip file downloaded! Enjoy...</h1>`
                    setTimeout(()=>{
                      outputCanv.current.innerHTML = ``
                    },2000)
                    qrCode.update({
                      data: urlBase,
                      type: 'canvas'
                    })
                    qrCode.append(codeWrapperInner.current)
                  },2000)
                })
              }
            }) 
      },400) // some kind of draw call/racecon issue with qr-code-styling, needs this slight timeout to display the canv before we can read it
      // debugger;
      // outputSvgs.push(_svg.cloneNode(true));
      // const _durl = _canv.toDataURL();
      // outputPngs.push(_durl.split('base64,')[1])
     /* _canv.toBlob((blob)=>{
        outputPngs.push(blob)
    
        if(idx < guids.length-1) setTimeout(()=>{delayedMap(idx+1)},30);
        else { // mapping done, zip it up
          let zip = new JSZip();
          const _date = new Date();
          const dateCode = `${String(_date.getMonth()).padStart(2,0)}-${String(_date.getDay()).padStart(2,0)}-${String(_date.getFullYear())}_${String(_date.getHours() % 12  || 12).padStart(2,0)}-${String(_date.getMinutes()).padStart(2,0)}${(_date.getHours() > 12? 'p':'a')}`
          outputPngs.map((png,idx)=>{
            // let blob = new Blob([svg], {type: 'image/svg+xml'});
            // const _svg = `<?xml version="1.0" standalone="no"?>
            // <svg xmlns="http://www.w3.org/2000/svg" width="500" height="500">${svg.innerHTML}</svg>`
            zip.file(`burnableQR_${props.experienceName}_${dateCode}_qr${idx}.png`,png)
          })
          outputCanv.current.innerHTML = `<h1 style="background:#000;padding:0.5em;">Creating and downloading zip file...</h1>`
          console.log('png gen done, creating zip')
          zip.generateAsync({type:'blob'}).then((content)=>{
            saveAs(content,`BurnableQRCodes_${dateCode}.zip`)
            setTimeout(()=>{
              outputCanv.current.innerHTML = `<h1 style="background:#000;padding:0.5em;">Zip file downloaded! Enjoy...</h1>`
              setTimeout(()=>{
                outputCanv.current.innerHTML = ``
              },2000)
              qrCode.update({
                data: urlBase,
                type: 'canvas'
              })
              qrCode.append(codeWrapper.current)
            },2000)
          })
        }
      }) */
    }
    delayedMap(0);
  }



  // styles

  const textFieldStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      backgroundColor: 'rgba(0,0,0,0.7) !important',
      color: '#fff',
      border: 'solid 1px rgba(0,0,0,0.2)',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // padding: '20px 12px 20px',
      padding: 0,
      letterSpacing: '0.1rem',
      borderRadius: '0',
      '&:hover': {
        border: 'solid 1px #650000',
        backgroundColor: '#fff',
      },
      '&$focused': {
        border: 'solid 1px #600000',
      },
      
    },
    input:{
      padding:'0.5rem',
      fontFamily: 'haboro-contrast-normal, sans-serif',
      fontWeight: 400,
      fontStyle: 'normal',
      fontSize: '1.3rem',
      textAlign: 'left',
    },
    focused: {
      backgroundColor: 'rgba(0,0,0,0.7)',
    },
  }));
  const textFieldClasses=textFieldStyles()

  const selectStyles = makeStyles((theme)=>({
    root: {
      backgroundColor: 'rgba(0,0,0,0.7) !important',
      color: '#fff',
      border: 'solid 1px rgba(0,0,0,0.2)',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      borderRadius: '0',
      padding: '1rem',
      marginBottom: '1px',
      letterSpacing: '0.1rem',
      '&:hover': {
        border: 'solid 1px #650000',
        backgroundColor: '#fff',
      },
      '&:focused': {
        border: 'solid 1px #600000',
      },
      select: {
        background: '#fff',
        root: {
          background: '#00f'
        }
      }
    },
    icon: {
      fill: 'rgba(255,255,255,0.4)'
    }
  }))
  const selectClasses=selectStyles()

  const accordionStyles = makeStyles((theme)=>({
      root: {
        background: 'rgba(20,20,20,1)',
        color: '#fff',
        border: 'solid 1px rgba(255,255,255,0.1)',
      }
  }))
  const accordionClasses=accordionStyles();
  
  const checkboxStyles = makeStyles((theme)=>({
    root: {
      '& svg':{
        fill: 'rgba(255,255,255,0.7)'
      }
    }
  }))
  const checkboxClasses = checkboxStyles();
  const accordionSummaryStyles = makeStyles((theme)=>({
    root: {
      color: '#fff',
      fill: '#fff',
      background: 'rgba(15,15,15,0.6)',
      letterSpacing: '0.21em',
    },
    expandIcon: {
      '& svg': {
        fill: 'rgba(255,255,255,0.6)'
      }
    }
  }))
  const accordionSummaryClasses = accordionSummaryStyles();



  return (
  <div className={styles.QRGen}>
    <Grid container spacing={2} xs={12}>
      <Grid item xs={7}>
          <Accordion color="primary" classes={accordionClasses}>
            <AccordionSummary ref={primaryOptionsAccordion} classes={accordionSummaryClasses} expandIcon={<ExpandMore />}>
              Primary Options
            </AccordionSummary>
            <AccordionDetails> 
              <form className={styles.optionsForm}>
                <label for="urlfield">Base Url (https://)</label>
                <TextField ref={urlField} name="urlfield" className={styles.textField} value={url} onChange={handleUrlChange} InputProps={{classes:textFieldClasses,disableUnderline:true}} variant="filled" />
                <label for="eccfield">Error Correction Level</label>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={eccLevel}
                  onChange={handleEccChange}
                  variant='filled'
                  classes={selectClasses}
                  disableUnderline={true}
                  name="eccfield"
                >
                  <MenuItem value={"L"}>Level "L" - (7%)</MenuItem>
                  <MenuItem value={"M"}>Level "M" - (15%)</MenuItem>
                  <MenuItem value={"Q"}>Level "Q" - (25%) (Default)</MenuItem>
                  <MenuItem value={"H"}>Level "H" - (30%)</MenuItem>
                </Select>
              </form>
            </AccordionDetails>
          </Accordion>
          <Accordion color="primary" classes={accordionClasses}>
            <AccordionSummary classes={accordionSummaryClasses} expandIcon={<ExpandMore />}>
              Image/Logo Options
            </AccordionSummary>
            <AccordionDetails> 
              <form className={styles.optionsForm}>
                <label for="logofield">Logo/Image for center</label>
                <input ref={fileInput} onChange={handleLogoImageChange} style={{height:0}}  type="file" name="logofield"  /> 
                <Button
                  variant='contained'
                  color="primary"
                  onClick={openFilePicker}
                >Choose file...</Button>
                <label for="logosizefield">Logo size </label>
                <TextField name="logosizefield" className={styles.textField} value={logoSize} onChange={handleLogoSizeChange} InputProps={{classes:textFieldClasses,disableUnderline:true}} variant="filled" />
                <label for="logomarginfield">Logo margin </label>
                <TextField name="logomarginfield" className={styles.textField} value={logoMargin} onChange={handleLogoMarginChange} InputProps={{classes:textFieldClasses,disableUnderline:true}} variant="filled" />
                <div className={styles.formSingleLineItem}>
                  <label for="logoHideDotsField">Logo should hide background dots?</label>
                  <Checkbox ref={imageHideDotsCheckbox} val={imageHideDots} onChange={handleChangeImageHideDots} name="logoHideDotsField" classes={checkboxClasses}/>
                </div>
              </form>
            </AccordionDetails>
          </Accordion>
          <Accordion color="primary" classes={accordionClasses}>
            <AccordionSummary classes={accordionSummaryClasses} expandIcon={<ExpandMore />}>
              Dot Options
            </AccordionSummary>
            <AccordionDetails className={styles.stackedAccordion}> 
              <form className={styles.optionsForm}>
                  <label for="dotstylefield">Dots Style</label>
                  <Select
                    value={dotsType}
                    onChange={handleDotsStyleChange}
                    variant='filled'
                    classes={selectClasses}
                    disableUnderline={true}
                    name="dotstylefield"
                  >
                    <MenuItem value={"square"}>Square</MenuItem>
                    <MenuItem value={"dots"}>Dots</MenuItem>
                    <MenuItem value={"rounded"}>Rounded</MenuItem>
                    <MenuItem value={"extra-rounded"}>Extra Rounded</MenuItem>
                    <MenuItem value={"classy"}>Classy</MenuItem>
                    <MenuItem value={"classy-rounded"}>Classy Rounded</MenuItem>
                  </Select>
                    <label for="dotscolortypefield">Dots Color Type</label>
                    <Select
                      value={dotsColorType}
                      onChange={handleDotsColorTypeChange}
                      variant='filled'
                      classes={selectClasses}
                      disableUnderline={true}
                      name="dotscolortypefield"
                  >
                    <MenuItem value={"single-color"}>Single Color</MenuItem>
                    <MenuItem value={"gradient"}>Gradient</MenuItem>
                  </Select>
                </form>
                <form className={(dotsColorType==='single-color')? styles.optionsForm : styles.optionsFormHidden}>
                  <label for="dotscolorfield">Dots Color</label>
                  <SwatchButton onClick={handleDotsSingleColorButton} swatchColor={dotsSingleColor} />
                </form>
                <form className={(dotsColorType==='gradient')? styles.optionsForm : styles.optionsFormHidden}>
                  <label for="dotstylefield">Dots Gradient</label>
                  <Grid name="dotstylefield" container item xs={12} spacing="1">
                    <Grid item xs={6} className={styles.gradientColorsSection}>
                      <span>Colors</span>

                      {!!dotsGradientColors && 
                        <>
                          {dotsGradientColors?.map((colorStop)=>{
                            return <SwatchButton onClick={()=>{
                              handleDotsGradientColorChange(colorStop,dotsGradientColors)}} swatchColor={colorStop.color} />
                          })}
                        </>
                      }

                    </Grid>
                    <Grid item xs={6} className={styles.gradientDetailsSection}>
                      <span>Settings</span>
                      <form className={styles.optionsForm}>
                        <label for="dotsgradienttypefield">Gradient Type</label>
                        <Select
                            value={dotsGradientType}
                            onChange={handleDotsGradientTypeChange}
                            variant='filled'
                            classes={selectClasses}
                            disableUnderline={true}
                            name="dotsgradienttypefield"
                        >
                          <MenuItem value={"linear"}>Linear</MenuItem>
                          <MenuItem value={"radial"}>Radial</MenuItem>
                        </Select>
                        <label for="dotsgradientanglefield">Gradient Angle</label>
                        <RotationWidget ang={dotsGradientAngle} setAng={setDotsGradientAngle} />
                      </form>
                    </Grid>
                  </Grid>
                 
                  
                  {/* <SwatchButton onClick={handleDotsGradientColor1Button} swatchColor={dotsGradientColor1} />
                  <SwatchButton onClick={handleDotsGradientColor2Button} swatchColor={dotsGradientColor2} /> */}
                </form>
            </AccordionDetails>
          </Accordion>
          
          <Accordion color="primary" classes={accordionClasses}>
            <AccordionSummary classes={accordionSummaryClasses} expandIcon={<ExpandMore />}>
              Corner Square Options
            </AccordionSummary>
            <AccordionDetails className={styles.stackedAccordion}> 
              <form className={styles.optionsForm}>
                  <label for="cornersquarestypefield">Square Style</label>
                  <Select
                    value={cornerSquareType}
                    onChange={handleCornerSquaresTypeChange}
                    variant='filled'
                    classes={selectClasses}
                    disableUnderline={true}
                    name="cornersquarestypefield"
                  >
                    <MenuItem value={"square"}>Square</MenuItem>
                    <MenuItem value={"dot"}>Dot</MenuItem>
                    <MenuItem value={"extra-rounded"}>Extra Rounded</MenuItem>
                  </Select>
                    <label for="cornersquaresscolortypefield">Square Color Type</label>
                    <Select
                      value={cornerSquaresColorType}
                      onChange={handleCornerSquaresColorTypeChange}
                      variant='filled'
                      classes={selectClasses}
                      disableUnderline={true}
                      name="cornersquaresscolortypefield"
                  >
                    <MenuItem value={"single-color"}>Single Color</MenuItem>
                    <MenuItem value={"gradient"}>Gradient</MenuItem>
                  </Select>
                </form>
                <form className={(cornerSquaresColorType==='single-color')? styles.optionsForm : styles.optionsFormHidden}>
                  <label for="dotscolorfield">Square Color</label>
                  <SwatchButton onClick={handleCornerSquaresSingleColorButton} swatchColor={cornerSquaresSingleColor} />
                </form>
                <form className={(cornerSquaresColorType==='gradient')? styles.optionsForm : styles.optionsFormHidden}>
                  <label for="cornersquaresstylefield">Square Gradient</label>
                  <Grid name="cornersquaresstylefield" container item xs={12} spacing="1">
                    <Grid item xs={6} className={styles.gradientColorsSection}>
                      <span>Colors</span>

                      {!!cornerSquaresGradientColors && 
                        <>
                          {cornerSquaresGradientColors?.map((colorStop)=>{
                            return <SwatchButton onClick={()=>{
                              handleCornerSquaresGradientColorChange(colorStop,cornerSquaresGradientColors)}} swatchColor={colorStop.color} />
                          })}
                          {/* <GradientButton text="+" fontSize="1.7rem" onClick={addGradientStop} /> */}
                        </>
                      }

                    </Grid>
                    <Grid item xs={6} className={styles.gradientDetailsSection}>
                      <span>Settings</span>
                      <form className={styles.optionsForm}>
                        <label for="cornersquaresgradienttypefield">Gradient Type</label>
                        <Select
                            value={cornerSquaresGradientType}
                            onChange={handleCornerSquaresGradientTypeChange}
                            variant='filled'
                            classes={selectClasses}
                            disableUnderline={true}
                            name="cornersquaresgradienttypefield"
                        >
                          <MenuItem value={"linear"}>Linear</MenuItem>
                          <MenuItem value={"radial"}>Radial</MenuItem>
                        </Select>
                        <label for="dotsgradientanglefield">Gradient Angle</label>
                        <RotationWidget ang={cornerSquaresGradientAngle} setAng={setCornerSquaresGradientAngle} />
                      </form>
                    </Grid>
                  </Grid>
                 
                  
                  {/* <SwatchButton onClick={handleDotsGradientColor1Button} swatchColor={dotsGradientColor1} />
                  <SwatchButton onClick={handleDotsGradientColor2Button} swatchColor={dotsGradientColor2} /> */}
                </form>
            </AccordionDetails>
          </Accordion>

          <Accordion color="primary" classes={accordionClasses}>
            <AccordionSummary classes={accordionSummaryClasses} expandIcon={<ExpandMore />}>
              Corner Dots Options
            </AccordionSummary>
            <AccordionDetails className={styles.stackedAccordion}> 
              <form className={styles.optionsForm}>
                  <label for="cornerdotstypefield">Corner Dots Style</label>
                  <Select
                    value={cornerDotsType}
                    onChange={handleCornerDotsTypeChange}
                    variant='filled'
                    classes={selectClasses}
                    disableUnderline={true}
                    name="cornerdotstypefield"
                  >
                    <MenuItem value={"square"}>Square</MenuItem>
                    <MenuItem value={"dot"}>Dot</MenuItem>
                    {/* <MenuItem value={"extra-rounded"}>Extra Rounded</MenuItem> */}
                  </Select>
                    <label for="cornerdotscolortypefield">Corner Dots Color Type</label>
                    <Select
                      value={cornerDotsColorType}
                      onChange={handleCornerDotsColorTypeChange}
                      variant='filled'
                      classes={selectClasses}
                      disableUnderline={true}
                      name="cornerdotsscolortypefield"
                  >
                    <MenuItem value={"single-color"}>Single Color</MenuItem>
                    <MenuItem value={"gradient"}>Gradient</MenuItem>
                  </Select>
                </form>
                <form className={(cornerDotsColorType==='single-color')? styles.optionsForm : styles.optionsFormHidden}>
                  <label for="cornerdotscolorfield">Corner Dots Color</label>
                  <SwatchButton onClick={handleCornerDotsSingleColorButton} swatchColor={cornerDotsSingleColor} />
                </form>
                <form className={(cornerDotsColorType==='gradient')? styles.optionsForm : styles.optionsFormHidden}>
                  <label for="cornerdotsstylefield">Corner Dots Gradient</label>
                  <Grid name="cornerdotsstylefield" container item xs={12} spacing="1">
                    <Grid item xs={6} className={styles.gradientColorsSection}>
                      <span>Colors</span>

                      {!!cornerDotsGradientColors && 
                        <>
                          {cornerDotsGradientColors?.map((colorStop)=>{
                            return <SwatchButton onClick={()=>{
                              handleCornerDotsGradientColorChange(colorStop,cornerDotsGradientColors)}} swatchColor={colorStop.color} />
                          })}
                          {/* <GradientButton text="+" fontSize="1.7rem" onClick={addGradientStop} /> */}
                        </>
                      }

                    </Grid>
                    <Grid item xs={6} className={styles.gradientDetailsSection}>
                      <span>Settings</span>
                      <form className={styles.optionsForm}>
                        <label for="cornerdotsgradienttypefield">Gradient Type</label>
                        <Select
                            value={cornerDotsGradientType}
                            onChange={handleCornerDotsGradientTypeChange}
                            variant='filled'
                            classes={selectClasses}
                            disableUnderline={true}
                            name="cornerdotsgradienttypefield"
                        >
                          <MenuItem value={"linear"}>Linear</MenuItem>
                          <MenuItem value={"radial"}>Radial</MenuItem>
                        </Select>
                        <label for="cornerdotsgradientanglefield">Gradient Angle</label>
                        <RotationWidget ang={cornerDotsGradientAngle} setAng={setCornerDotsGradientAngle}/>
                      </form>
                    </Grid>
                  </Grid>
                 
                  
                  {/* <SwatchButton onClick={handleDotsGradientColor1Button} swatchColor={dotsGradientColor1} />
                  <SwatchButton onClick={handleDotsGradientColor2Button} swatchColor={dotsGradientColor2} /> */}
                </form>
            </AccordionDetails>
          </Accordion>
          
          <Accordion color="primary" classes={accordionClasses}>
            <AccordionSummary classes={accordionSummaryClasses} expandIcon={<ExpandMore />}>
              Background Options
            </AccordionSummary>
            <AccordionDetails className={styles.stackedAccordion}> 
              <form className={styles.optionsForm}>
                    <label for="backgroundcolortypefield">Background Color Type</label>
                    <Select
                      value={backgroundColorType}
                      onChange={handleBackgroundColorTypeChange}
                      variant='filled'
                      classes={selectClasses}
                      disableUnderline={true}
                      name="backgroundscolortypefield"
                  >
                    <MenuItem value={"single-color"}>Single Color</MenuItem>
                    <MenuItem value={"gradient"}>Gradient</MenuItem>
                  </Select>
                </form>
                <form className={(backgroundColorType==='single-color')? styles.optionsForm : styles.optionsFormHidden}>
                  <label for="backgroundcolorfield">Background Color</label>
                  <SwatchButton name="backgroundcolorfield" onClick={handleBackgroundSingleColorButton} swatchColor={backgroundSingleColor} />
                </form>
                <form className={(backgroundColorType==='gradient')? styles.optionsForm : styles.optionsFormHidden}>
                  <label for="backgroundstylefield">Background Gradient</label>
                  <Grid name="backgroundstylefield" container item xs={12} spacing="1">
                    <Grid item xs={6} className={styles.gradientColorsSection}>
                      <span>Colors</span>

                      {!!backgroundGradientColors && 
                        <>
                          {backgroundGradientColors?.map((colorStop)=>{
                            return <SwatchButton onClick={()=>{
                              handleBackgroundGradientColorChange(colorStop,backgroundGradientColors)}} swatchColor={colorStop.color} />
                          })}
                          {/* <GradientButton text="+" fontSize="1.7rem" onClick={addGradientStop} /> */}
                        </>
                      }

                    </Grid>
                    <Grid item xs={6} className={styles.gradientDetailsSection}>
                      <span>Settings</span>
                      <form className={styles.optionsForm}>
                        <label for="backgroundgradienttypefield">Gradient Type</label>
                        <Select
                            value={backgroundGradientType}
                            onChange={handleBackgroundGradientTypeChange}
                            variant='filled'
                            classes={selectClasses}
                            disableUnderline={true}
                            name="backgroundgradienttypefield"
                        >
                          <MenuItem value={"linear"}>Linear</MenuItem>
                          <MenuItem value={"radial"}>Radial</MenuItem>
                        </Select>
                        <label for="backgroundgradientanglefield">Gradient Angle</label>
                        <RotationWidget ang={backgroundGradientAngle} setAng={setBackgroundGradientAngle} />
                      </form>
                    </Grid>
                  </Grid>
                 
                  
                  {/* <SwatchButton onClick={handleDotsGradientColor1Button} swatchColor={dotsGradientColor1} />
                  <SwatchButton onClick={handleDotsGradientColor2Button} swatchColor={dotsGradientColor2} /> */}
                </form>
            </AccordionDetails>
          </Accordion>
          <div width="500" height="500" ref={outputCanv} className={styles.outputSvg} />
        
          {showColorPicker && <ColorPicker color={colorPickerColor} onChange={colorPickerColorSetter} close={()=>{setColorPickerColor(null); setShowColorPicker(false)}}/>}
      </Grid>
      <Grid item xs={5}>
        <div ref={codeWrapper} className={styles.codeWrapper}>
          <div ref={codeWrapperInner} className={styles.codeWrapperInner}></div>
          {!!guids?.length && <GradientButton text="Ship it!" style={{width:'100%',marginTop:'20px',fontSize:'1.3rem',letterSpacing:'0.03rem'}} onClick={doOutput} />}
        </div>
      </Grid>
    </Grid>
  </div>
  )
}
export default QRGen