import React, { useEffect, useRef, useState } from "react";
import styles from './styles.module.scss';
import { getAngleBetweenPoints } from "../../Utils/utils-main";
import {throttle} from 'lodash'
const RotationWidget = (props)=>{
  const {setAng,ang} = props;
  const [dragging,setDragging] = useState(false);
  const scopeRef = useRef();
  const indicatorRef = useRef();
  const [currentRot,setCurrentRot] = useState(ang);
  useEffect(()=>{
    setAng(currentRot);
  },[currentRot])
  const _setCurrentRot = !!props.throttle? throttle(setCurrentRot,props.throttle): setCurrentRot
  const handleMouseMove = (e,start)=>{
    if(!dragging && !start) return;
    const scopeRect = scopeRef.current.getBoundingClientRect();
    const scopeCenter = {
      x:scopeRect.left + (scopeRect.width/2),
      y:scopeRect.top + (scopeRect.height/2)
    }

    const _ang = getAngleBetweenPoints({x:e.clientX,y:e.clientY},scopeCenter)
    _setCurrentRot(_ang.rad)
  }
  return (<div className={styles.rotationWidget} onMouseDown={(e)=>{setDragging(true);handleMouseMove(e,true);}} onMouseUp={()=>{setDragging(false)}} onMouseMove={handleMouseMove}>
    <div ref={scopeRef} className={styles.rotationWidgetScope}>
      <div ref={indicatorRef} className={styles.rotationWidgetIndicator} style={{transform:`rotate(${currentRot}rad)`}}/>
    </div>
  </div>)
}

export default RotationWidget
