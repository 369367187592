import React,{useRef,useState} from "react"
import styles from './styles.module.scss'

const EditableConfigRowNumeric = (props)=>{
  const {configurable,addRefToSet,removeRefFromSet,refSet} = props;
  const fieldRef = useRef();
  const [fieldVal,setFieldVal] = useState(props.configurable.value);
  const _setFieldVal = (val)=>{
    if (Number(val) !== Number(configurable.value)){
      //updates changed add to set
      addRefToSet(fieldRef)
    }
    else {
      //remove ref from set
      removeRefFromSet(fieldRef)
    }
    setFieldVal(val);
  }
  return (<li><span>{configurable.name}</span><input type="number" className={styles.controlElementInput}  ref={fieldRef} configurablename={configurable.name} valuetype="number" value={fieldVal} onChange={(e)=>{_setFieldVal(e.target.valueAsNumber)}}></input></li>);
}

export default EditableConfigRowNumeric