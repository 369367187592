import React,{useState} from "react";
import styles from './styles.module.scss';
import EditableConfigRowString from "./editableConfigrowString";
import EditableConfigRowNumeric from "./editableConfigrowNumeric";

const ConfigMenu = (props)=>{
  const {configurables,experienceName,setConfigModalContent,setConfigurables} = props;
  const [confirmSave,setConfirmSave] = useState(null);
  const [editedConfigs,setEditedConfigs] = useState([]);
  const addRefToEditedConfigs = (ref)=>{
    if(editedConfigs.indexOf(ref) === -1) {
      const newEditedConfigs = [...editedConfigs,ref]
      setEditedConfigs(newEditedConfigs)
    }
  }
  const removeRefFromEditedConfigs = (ref)=>{
    const _index = editedConfigs.indexOf(ref);
    if(_index !== -1){
      let newEditedConfigs = [...editedConfigs]
      newEditedConfigs.splice(_index,1)
      setEditedConfigs(newEditedConfigs);
    }
  }
  const confirmSaveConfig = ()=>{
    const edits = editedConfigs.map((editedConfig)=>{
      const configurableName = editedConfig.current.getAttribute('configurablename');
      const configurableWas = configurables.find((configurable)=>{return configurable.name === configurableName});
      const transformFunc = (()=>{
        if (editedConfig.current.getAttribute('valuetype') === 'number') {
          return Number
        }
        else if (editedConfig.current.getAttribute('valuetype') === 'string'){
          return String
        }
      })()
      if (transformFunc(editedConfig.current.value) === transformFunc(configurableWas.value)) {
        return null
      }
      else {
        const _val = transformFunc(editedConfig.current.value)
        return <li className={styles.editListItem}><span>{configurableName}</span><span>from</span><span>{configurableWas.value}</span><span>to</span><span>{_val}</span></li>
      }
    })
    setConfirmSave(<>
      <h1>Confirm edits to configuration</h1>
      <p>You are about to make the following changes to the configuration for experience {experienceName}</p>
      <ul>
        {edits}
      </ul>
      <p>Proceed?</p>
      <div className={styles.proceedButtonSet}>
        <span onClick={()=>{setConfirmSave(null)}}>&lt; Back to Edits</span>
        <span onClick={updateConfigurables}>Proceed &gt;</span>
      </div>
    </>)
  }
  const updateConfigurables = ()=>{
    const newConfigurables = [...configurables];
    editedConfigs.map((editedConfig)=>{
      const configName = editedConfig.current.getAttribute('configurableName');
      const configType = editedConfig.current.getAttribute('valuetype');
      const transformFunc = (configType === 'number')? Number:String;
      const configValue = transformFunc(editedConfig.current.value);
      let priorConfigIndex;
      let priorConfig = newConfigurables.find((configurable,idx)=>{
        if (configurable.name === configName) {
          priorConfigIndex = idx;
          return true;
        }
        else {
          return false
        }
      })
      const _priorConfig = {...priorConfig};
      _priorConfig.value = configValue; 
      newConfigurables.splice(priorConfigIndex,1,_priorConfig);
      return null;
    })
    setConfigurables(newConfigurables);
    setConfigModalContent(null);
  }
  const configList = configurables.map((configurable)=>{
    if (configurable.isEditable) {
      // return control, pivot based on type of value. for now just return same as non editable
      if (typeof configurable.value === 'number'){
        return <EditableConfigRowNumeric configurable={configurable} refSet={editedConfigs} addRefToSet={addRefToEditedConfigs} removeRefFromSet={removeRefFromEditedConfigs}/>
      }
      else if (typeof configurable.value === 'string') {
        return <EditableConfigRowString configurable={configurable} refSet={editedConfigs} addRefToSet={addRefToEditedConfigs} removeRefFromSet={removeRefFromEditedConfigs}/>
      }
      else return null;
    }
    else {
      return <li><span>{configurable.name}</span><div className={styles.controlElement}>{configurable.value}</div></li>
    }
  })
  return (<div className={styles.configMenu}>
    <h1>Configure {experienceName}</h1>
    <>
      <ul style={{display:(!confirmSave)? 'block':'none'}}>{configList}</ul>
      <div className={styles.proceedButtonSet}>
        {!!editedConfigs.length && <span onClick={()=>{setConfigModalContent(null)}}>&lt; Discard Edits</span>}
        {!!editedConfigs.length && <span onClick={confirmSaveConfig}>Save Edits &gt;</span>}
        {!editedConfigs.length && <span onClick={()=>{setConfigModalContent(null)}}>&lt; Back</span>}
      </div>
    </>
    {!!confirmSave && <ul>{confirmSave}</ul>}
    </div>)
}

export default ConfigMenu