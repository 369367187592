import React,{useState} from "react"
import styles from './styles.module.scss'
import GoldBorderModal from '../../../Components/Modals/GoldBorderModal'
import TextField from '@material-ui/core/TextField'
import { useTheme,makeStyles} from '@material-ui/core/styles';
import DecoButton from '../../../Components/DecoButton'
import CancelIcon from '@material-ui/icons/Cancel';
import clsx from 'clsx'
const WritingControlUI = (props)=>{
  const {mode="moderated"} = props;
  const [inputVal,setInputVal] = useState('');
  const handleChange=(e)=>{setInputVal(e.target.value)}

  const doSend = (dataBundle)=>{
    if (props.mode==='moderated') {
      props.sendContentToModeration(dataBundle.phrase,"txt");
    }
    else if (props.mode==='direct') {
      props.sendTriggerOrDataBundle(dataBundle)
    }
  }

  const textFieldStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      backgroundColor: 'rgba(0,0,0,0.7) !important',
      color: '#cc0000',
      border: 'solid 1px rgba(0,0,0,0.2)',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // padding: '20px 12px 20px',
      padding: 0,
      borderRadius: '0',
      '&:hover': {
        border: 'solid 1px #650000',
        backgroundColor: '#fff',
      },
      '&$focused': {
        border: 'solid 1px #600000',
      },
      
    },
    input:{
      padding:'2rem',
      fontFamily: 'haboro-contrast-normal, sans-serif',
      fontWeight: 400,
      fontStyle: 'normal',
      fontSize: '2.1rem',
      textAlign: 'center',
      paddingRight: '7rem'
    },
    focused: {
      backgroundColor: 'rgba(0,0,0,0.7)',
    },
  }));
  const textFieldClasses=textFieldStyles()

  return (<GoldBorderModal modalContent={
    <div className={styles.WritingClientControls}>
      <h1>Please Enter Your Message</h1>
      <TextField className={styles.input} value={inputVal} onChange={handleChange} InputProps={{classes:textFieldClasses,disableUnderline:true}} variant="filled" />
      <CancelIcon className={inputVal.length? styles.clearBtnActive : styles.clearBtn} onClick={()=>{setInputVal('')}}/>
      <div className={inputVal.length? styles.bottomRowActive : styles.bottomRow}>
        <DecoButton text="Write" fontSize="1.8rem" textPadding="0 0.4em" onClick={()=>{doSend({phrase:inputVal,loop:false,interrupt:true,random:false})}} /> {/*for direct we have controls for phrase,loop,interrupt,random*/}
      </div>
    </div>
  } />)
}

export default WritingControlUI;