import React,{useRef,useEffect, useState} from "react"

const CountdownTimer = (props)=>{
  const {endTime} = props
  const [countTime,setCountTime] = useState(null);
  const timer = useRef();
  useEffect(()=>{
    return ()=>{ // dismount cleanup
      clearTimeout(timer.current);
      timer.current = null;
    }
  },[])
  useEffect(()=>{_setCountTime();},[endTime]);
  const _setCountTime = ()=>{
    if (!endTime) return;
    let diff = endTime - Date.now();
    if (diff < 0) {diff = 0}
    const h = Math.floor((diff - (diff%3600000))/3600000);
    const m = Math.floor((diff%3600000)/60000);
    const s = Math.floor((diff%60000)/1000);
    if (diff > 0){
      timer.current = setTimeout(()=>{_setCountTime()},300)
    }
    setCountTime(`${h.toString().padStart(2,'0')}:${m.toString().padStart(2,'0')}:${s.toString().padStart(2,'0')}`);
  }
  
  return countTime;
}

export default CountdownTimer