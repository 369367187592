import React, { useEffect, useRef } from "react"
import styles from './styles.module.scss'
import {PhotoshopPicker} from 'react-color';

const ColorPicker = (props)=>{
  const {color,setColor,close,onChange,onChangeComplete} = props
  const startColorCache = useRef(null)
  useEffect(()=>{
    if (!!color && !startColorCache.current){
      startColorCache.current = color;
    }
  },[color])

  const handleChange=(e)=>{
    if (!onChange) return
    try{onChange(e.hex)}catch(err){};
  }

  const handleChangeComplete=(e)=>{
    if (!onChangeComplete) return
    try{onChangeComplete(e.hex)}catch(err){};
  }

  const handleAccept=(e)=>{
    doClose();
  }

  const handleCancel=(e)=>{
    if (!!onChange) {
      try {
        onChange(startColorCache.current)
      }
      catch(err){}
     }
     if(!!onChangeComplete) {
       try {
         onChangeComplete(startColorCache.current)
       }
       catch(err){}
     }
     doClose();
  } 

  const doClose=()=>{
    startColorCache.current = null;
    close();
  }

  return (<PhotoshopPicker className={styles.ColorPicker} color={color} onChange={handleChange} onCancel={handleCancel} onAccept={handleAccept} onChangeComplete={handleChangeComplete}/>)
}


export default ColorPicker