var CryptoJS = require("crypto-js");

const fireFunctionFromKeycode = (e,func,keycode)=>{
  e.preventDefault();
  if (e.keycode === keycode || e.which === keycode) {
    func();
    return true;
  }
  else return false;
}

const milesToFeet=(mi)=>{
  return mi*5280;
}
const feetToMiles=(ft)=>{
  return ft/5280;
}

function haversine_distance(mk1, mk2) {
  const R = 6371e3; // metres
  const φ1 = (mk1.lat * Math.PI) / 180; // φ, λ in radians
  const φ2 = (mk2.lat * Math.PI) / 180;
  const Δφ = ((mk2.lat - mk1.lat) * Math.PI) / 180;
  const Δλ = ((mk2.long - mk1.long) * Math.PI) / 180;

  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // in metres
  const feet = d * 3.2808;
  return feet ;
}


const actionIfEnterOrSpace = (e,action)=>{
  if (e.key !== undefined) {
    if (e.key === "Enter" || e.key===" ") {
      action();
    }
  } else if (e.keyCode !== undefined) {
    if (e.keyCode === 32 || e.keyCode === 13) {
      action();
    }
  }
}

const actionIfEnter = (e,action)=>{
  if (e.key !== undefined) {
    if (e.key === "Enter") {
      action();
    }
  } else if (e.keyCode !== undefined) {
    if (e.keyCode === 13) {
      action();
    }
  }
}

const cryptoChipChop = (text,s)=>{
  let h = CryptoJS.AES.encrypt(text,s).toString();
  // const split = h.slice(0,5)
  // h = split[1]+split[0];
  const s1 = s.slice(0,5);
  const s2 = s.slice(5,s.length)
  const s3 = s2+s1
  return {h:h,s:s3}
}

const cryptoDeChipChop = (h,s)=>{
  const s1 = String(s).slice(-5)
  const s2 = String(s).slice(0,s.length-5);
  const s3 = s1+s2;
  const plainText = CryptoJS.AES.decrypt(h,s3).toString(CryptoJS.enc.Utf8);
  return plainText;
}


const getAngleBetweenPoints = (p1,p2)=>{
  const angRad = Math.atan2(p2.y - p1.y, p2.x - p1.x);
  const angDeg = angRad * 180 / Math.PI;
  return {rad:angRad,deg:angDeg};
}

const promiseSleep = (delay, resolveValue) => new Promise((resolve) => {
  setTimeout(() => resolve(resolveValue), delay);
});


export {fireFunctionFromKeycode,haversine_distance,milesToFeet,feetToMiles,actionIfEnterOrSpace,actionIfEnter,cryptoChipChop,cryptoDeChipChop,getAngleBetweenPoints,promiseSleep}