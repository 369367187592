import React,{useRef,useState,useContext} from "react";
import styles from './styles.module.scss'
import TextField from '@material-ui/core/TextField'
import { fireFunctionFromKeycode } from "../../Utils/utils-main";
import Login from '../Login'
import SignUp from '../SignUp'
import {MainContext} from '../DataManager';
import DecoButton from '../DecoButton';
import { actionIfEnterOrSpace,cryptoChipChop } from "../../Utils/utils-main";
import {Auth} from 'aws-amplify';
import VerificationSuccess from "../VerificationSuccess";
import { useTheme,makeStyles} from '@material-ui/core/styles';

const VerifyResetPasswordForm = (props)=>{
  const {setMainModalTabs,setLoadingModalContent} = useContext(MainContext);
  const verificationField = useRef();
  const newPasswordField = useRef();
  const newPasswordVerifyField = useRef();
  const [verificationError,setVerificationError] = useState();
  const [newPasswordError,setNewPasswordError] = useState();
  const [rowShift,setRowShift] = useState(0); // used to shift rows up to keep fields in view with keyboard open on mobile devices
  
  const submitVerifyResetPassword=()=>{
    setVerificationError(null);
    setNewPasswordError(null);
    setLoadingModalContent('default');
    const pass1 = newPasswordField.current.querySelector('input').value;
    const pass2 = newPasswordVerifyField.current.querySelector('input').value;
    const un = props.username;
    const vc = verificationField.current.querySelector('input').value;

    if (pass1 !== pass2) {
      setLoadingModalContent(null);
      setNewPasswordError('Passwords do not match!');
      return;
    }
    else if (!vc.length) {
      setLoadingModalContent(null);
      setVerificationError('Verification code cannot be blank!')
      return;
    }
    else if (vc.length < 6) {
      setLoadingModalContent(null);
      setVerificationError("Invalid verification code!");
      return;
    }
    else if (!pass1.length) {
      setLoadingModalContent(null);
      setNewPasswordError('Password cannot be blank!')
      return;
    }
    Auth.forgotPasswordSubmit(un,vc,pass1)
    .then((res)=>{
      const {h,s} = cryptoChipChop(pass1,Date.now().toString()) // don't like putting plaintext pws in the DOM - so lightly encrypt
      setMainModalTabs([{tabTitle:'Reset',tabContent:<VerificationSuccess codeDestination="email" username={un} h={h} s={s} />}])
      setLoadingModalContent(null);
    })
    .catch((err)=>{
      console.log('error resetting password:',err)
      setLoadingModalContent(null);
      if (err.code === 'CodeMismatchException') {
        setVerificationError(err.message)
      }
      else if (err.code === 'InvalidPasswordException') {
        setNewPasswordError(err.message)
      }   
    })

  }

  const backToLogin=()=>{
    setMainModalTabs([
      {tabTitle: 'Login',tabContent:<Login/>},
      {tabTitle: 'Sign Up',tabContent:<SignUp />}
    ])
  }



  const textFieldStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      backgroundColor: '#fcfcfb',
      border: 'solid 1px rgba(0,0,0,0.2)',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // padding: '20px 12px 20px',
      padding: 0,
      borderRadius: '0',
      '&:hover': {
        border: 'solid 1px rgba(0,0,0,0.4)',
        backgroundColor: '#fff',
      },
      '&$focused': {
        border: 'solid 1px rgba(0,0,0,0.5)',
        backgroundColor: '#fff',
      },
      
    },
    input:{
      padding:'20px 12px 20px',
      fontFamily: 'haboro-contrast-normal, sans-serif',
      fontWeight: 400,
      fontStyle: 'normal',
      fontSize: '1.2em'
    },
    focused: {},
  }));
  const textFieldClasses=textFieldStyles()

  return (<div className={styles.VerifyResetPasswordForm}>
    <form className={`${styles.form}`} onSubmit={(e)=>{e.preventDefault();e.stopPropagation();return null}} style={{top:`-${rowShift*10}em`}}>
      <h2>Sent! Please check your email for verification code.</h2>
      <h1>Verification Code</h1>
      <TextField id="verificationCode" InputProps={{classes:textFieldClasses,disableUnderline:true}} variant="filled" ref={verificationField} error={verificationError} helperText={verificationError} onKeyUp={(e)=>{fireFunctionFromKeycode(e,submitVerifyResetPassword,13)}} onFocus={()=>{setRowShift(0)}} onBlur={()=>{setRowShift(0)}} />
      <h1>New Password</h1>
      <TextField id="newPasswordField" type="password" InputProps={{classes:textFieldClasses,disableUnderline:true}} variant="filled" ref={newPasswordField} error={newPasswordError} helperText={newPasswordError} onKeyUp={(e)=>{fireFunctionFromKeycode(e,submitVerifyResetPassword,13)}} onFocus={()=>{setRowShift(1)}} onBlur={()=>{setRowShift(0)}} />
      <h1>Repeat New Password</h1>
      <TextField id="newPasswordVerifyField" type="password" InputProps={{classes:textFieldClasses,disableUnderline:true}} variant="filled" ref={newPasswordVerifyField} error={newPasswordError} helperText={newPasswordError} onKeyUp={(e)=>{fireFunctionFromKeycode(e,submitVerifyResetPassword,13)}} onFocus={()=>{setRowShift(2)}} onBlur={()=>{setRowShift(0)}} />
      <div className={styles.bottomRow}>
        <div className={styles.goBtn}>
          <DecoButton text="Go" type="dark" fontSize="1.5rem" onClick={submitVerifyResetPassword} onEnterOrSpace={submitVerifyResetPassword}/>
        </div>
        <span className={styles.backToLogin} onClick={backToLogin} onKeyUp={(e)=>{actionIfEnterOrSpace(e,backToLogin)}} tabIndex="0">Back</span>
      </div>
    </form>
  </div>)
}

export default VerifyResetPasswordForm;
