import Storage from "@aws-amplify/storage";
import { PubSub } from "aws-amplify";
import React, { useCallback, useEffect, useRef, useState, useContext } from "react";
import GradientButton from "../GradientButton";
import styles from './styles.module.scss';
import { cryptoChipChop,cryptoDeChipChop } from "../../Utils/utils-main";
import { MainContext } from "../DataManager";


const Moderation=(props)=>{
  const {refreshRate=5000} = props;
  const {setLoadingModalContent} = useContext(MainContext)
  // const [moderationItems,setModerationItems] = useState([]);
  const moderationItems = useRef([])
  const lastKnownObjectList = useRef([]); 
  const refreshTimer = useRef();
  const failedDownloadRecords = useRef({})
  const [lastUpdated,setLastUpdated] = useState(0); //used to trigger rerender when ref changes - need ref for async callbacks
  const [itemUnderReview,setItemUnderReview] = useState(null);
  const [expNameForItemUnderReview,setExpNameForItemUnderReview] = useState();


  useEffect(()=>{ 
    const init = async ()=>{
      // await getUpdatedObjectList();
      updateObjectListAndDownloadNew();
      // setup clickhandlers for objectCarousel?
      // set up refresh timer
      refreshTimer.current = setInterval(updateObjectListAndDownloadNew,refreshRate)
    }
    init();
  },[])

  useEffect(()=>{
    if(!itemUnderReview && !!moderationItems.current.length) {
      setItemUnderReview(moderationItems.current[0])
      setExpNameForItemUnderReview(moderationItems.current[0].props['data-experience-name'])
    }
  },[itemUnderReview,lastUpdated]) 


  const getUpdatedObjectList = async ()=>{
    // query moderation folder, get object list.
    // const storageList = await Storage.list('/')
    const getList = ()=>{
      return new Promise((res,rej)=>{
        Storage.list('moderation/').then((list)=>{
          // list.splice(0,1);
          res(list);
        })
      })
    }
    const storageList = await getList();
    // return object list
    return storageList;
  }



  const addToModerationItems=(item)=>{
    moderationItems.current.push(item)
    setLastUpdated(Date.now())
  }

  const removeFromModerationItems=(item)=>{
    const idx = moderationItems.current.indexOf(item);
    if(idx!==-1){
      moderationItems.current.splice(idx,1);
      setLastUpdated(Date.now())
    }
  }

  const handleQueueClick = (e)=>{
    const idx = [...e.target.parentElement.children].indexOf(e.target)
    const expName = e.target.getAttribute('data-experience-name')
    setExpNameForItemUnderReview(expName)
    setItemUnderReview(moderationItems.current[idx]);
  }

  const updateObjectListAndDownloadNew = async ()=>{
    const updatedObjectList = await getUpdatedObjectList();
    // diff lastKnownObjectList and updatedlist
    let diff = updatedObjectList.filter(obj => !lastKnownObjectList.current.includes(obj.key));
    diff.map((obj,idx)=>{
      // add item key to last known object list right away so we are not thrashing repeated downloads
      lastKnownObjectList.current.push(obj.key);
      // for each new item download it and add it to the moderationItems array
      Storage.get(obj.key,{download:true})
      .then(async (objDownload)=>{
        let el;
        if (objDownload.Body.type==="image/png") { //object is image, add image el to moderationItems.
          const displayURL = URL.createObjectURL(objDownload.Body)
          const _metaData = JSON.stringify(objDownload.Metadata);
          const {h:metaDataH,s:metaDataS} = cryptoChipChop(_metaData,Date.now().toString());
          el = <img onClick={handleQueueClick} src={displayURL} data-experience-name={objDownload.Metadata.experiencename} data-key={obj.key} data-metadata-a={metaDataH} data-metadata-b={metaDataS}/>     
          addToModerationItems(el);
          setLastUpdated(Date.now().toString())
        }
        else if (objDownload.Body.type==="text/plain") { //object is text add span el to moderationItems
          const blobText= await objDownload.Body.text();
          const _metaData = JSON.stringify(objDownload.Metadata);
          const {h:metaDataH,s:metaDataS} = cryptoChipChop(_metaData,Date.now().toString());
          el = <p className={styles.contentPara} onClick={handleQueueClick}  data-experience-name={objDownload.Metadata.experiencename} data-key={obj.key} data-metadata-a={metaDataH} data-metadata-b={metaDataS}>{blobText}</p>
          addToModerationItems(el);
          setLastUpdated(Date.now().toString())
        }
      })
      .catch((err)=>{
        // if an error retrieving, remove key from lastknown objects so that download can be re-attempted. if a key has failed to download more than 3x, forsake efforts to dl.
        const idx = lastKnownObjectList.current.indexOf(obj.key);
        let failureRecord = failedDownloadRecords.current[obj.key] || 0;
        failedDownloadRecords.current[obj.key] = ++failureRecord; 
        if (failureRecord < 3) {
          // remove key so download will be reattempted
          lastKnownObjectList.current.splice(idx,1);
        }
      })

    })
  }

  const approveCurrentItem = async ()=>{
    //move itemUnderReview key from moderation to /prod/expname
    setLoadingModalContent('default');
    const srcKey = itemUnderReview.props['data-key'];
    const experienceName = itemUnderReview.props['data-experience-name']
    const destKey = `production/${experienceName}/${srcKey.slice(srcKey.lastIndexOf('/')+1,)}`
    const copyResult = await Storage.copy(
      { 
        key: srcKey,
        level: 'public'
      },
      {
        key: destKey,
      }
    )
    //remove item from moderation folder
    const removeResult = await Storage.remove(srcKey);
    //send trigger command to showcontrol board
    PubSub.publish(`${process.env.REACT_APP_BUILD_ENV}/admin/${experienceName}`,{command:'triggerFromModeration'})
    //send newModeratedContent command to actual on admin channel
    PubSub.publish(`${process.env.REACT_APP_BUILD_ENV}/admin/${experienceName}`,JSON.stringify({cmd:'newModeratedContent',key:destKey}))
    //clear blob handle
    URL.revokeObjectURL(itemUnderReview.props['src'])
    //remove item from moderation queue
    removeFromModerationItems(itemUnderReview)
    //force refresh of moderation queue
    await updateObjectListAndDownloadNew();
    //clear item under review
    setItemUnderReview(null)
    setLoadingModalContent(null);
  }
  
  const denyCurrentItem = async ()=>{
    //move itemUnderReview key from moderation to /prod/expname
    setLoadingModalContent('default');
    const srcKey = itemUnderReview.props['data-key'];
    const destKey = `denied/${srcKey.slice(srcKey.lastIndexOf('/')+1,)}`
    const copyResult = await Storage.copy(
      { 
        key: srcKey,
        level: 'public'
      },
      {
        key: destKey,
      }
    )
    //remove item from moderation folder
    const removeResult = await Storage.remove(srcKey);
    //clear blob handle
    URL.revokeObjectURL(itemUnderReview.props['src'])
    //remove item from moderation queue
    removeFromModerationItems(itemUnderReview)
    //force refresh of moderation queue
    await updateObjectListAndDownloadNew();
    //clear item under review
    setItemUnderReview(null)
    setLoadingModalContent(null);
  }

  return (<div className={styles.Moderation}>
    <h1 style={{margin:0}}>Moderation</h1>
    <h2 style={{fontSize:'1.5em',margin:0}}>{expNameForItemUnderReview}</h2>
    <div className={styles.contentBox}>
      {itemUnderReview}
      {!itemUnderReview && !moderationItems.length && <h1>🌴 Nothing in queue to moderate! 🌴</h1>}
    </div>
    {!!itemUnderReview && <div className={styles.buttonGroup}>
      <GradientButton style={{padding:'1.5em',fontSize:'1.5em',marginRight:'1.5em'}} onClick={denyCurrentItem} text="❌ Deny ❌"/>
      <GradientButton style={{padding:'1.5em',fontSize:'1.5em'}} onClick={approveCurrentItem} text="✅ Approve ✅" />
    </div>}
    <div lastUpdated={lastUpdated} className={styles.ObjectCarousel}>
      <div className={styles.ObjectCarouselInner}>
        {moderationItems.current.map((obj)=>{
          return obj
        })}
      </div>
    </div>
  </div>)
}


export default Moderation