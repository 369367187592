import React,{useEffect,useState,useRef,useContext} from "react";
import {MainContext} from '../DataManager/';
// import {Signer,Auth} from 'aws-amplify'
// import {Map,NavigationControl,Marker} from 'maplibre-gl';
// import mapStyles from 'maplibre-gl/dist/maplibre-gl.css';
import mapStyles from '@here/maps-api-for-javascript/bin/mapsjs-ui.css';
import styles from './styles.module.scss';
// import Location from 'aws-sdk/clients/location';
import PollyPlayer from '../PollyPlayer';
import {introPhrases,departurePhrases,randomPhrase,reroutePhrases} from '../../Speech/phrases' 
import H from "@here/maps-api-for-javascript";
import {calculateRoute,refreshRoute,clearRouteShapesFromMap,drawMultipleToMap} from './mapUtils'
import {SoundContext} from '../SoundManager';
import Bell1 from '../../Sounds/bell1.mp3'
import { milesToFeet, feetToMiles } from "../../Utils/utils-main";
import LetsGoBtn from "../../Img/LetsGoBtn.png"
import {Link} from "react-router-dom"
import NoDriveScreen from "../Screens/NoDriveScreen"
import eventGeofences from '../SpaceGate/eventGeofences'
const MapGuide = (props)=>{
  const {userIsAroundBar} = props;
  const {user,eventLatLong,getMapToken,setMainModalContent,setMainModalType} = useContext(MainContext);
  // const {Channels} = useContext(SoundContext);
  // const [soundChannel] = useState(Channels.routing)
  // const [credentials,setCredentials] = useState(null);
  const [platform,setPlatform] = useState(null);
  const [layers,setLayers] = useState(null)
  const [map,setMap] = useState(null)
  // const [userMarker,setUserMarker] = useState(null);
  const userMarker = useRef();
  const [route,setRoute] = useState(null);
  const [currentRoutePoint,setCurrentRoutePoint] = useState(0);
  const [routingLaunched,setRoutingLaunched] = useState(false)
  const mapContainer = useRef();
  const [pollyPhrase,setPollyPhrase] = useState(null)
  const routeCheckTimer = useRef();
  const routeHandle = useRef();
  // const _pollyPlayer = useRef();
  const distanceAnnouncedFlags = useRef({
    '50ft': false,
    '500ft': false,
    '0.5mi': false,
    '1mi': false,
    '2mi': false,
    '5mi': false,
    '10mi': false,
    '20mi': false,
    'initial': false
  });
  const lastActionListLength = useRef(0);
  const bellAudio = useRef(document.querySelector('.bellAudio'))

  const getLoc = ()=>{
    return window.currentLoc
  }

  //TODO TEMP DEBUG DELME SECURITY
  window.drawFencePolys = ()=>{
    let polys = [];
    for (const fenceId in eventGeofences) {
      const fencePoints = eventGeofences[fenceId];
      const lineString = new H.geo.LineString();
      for (const pointIdx in fencePoints) {
        const point = fencePoints[pointIdx]
        lineString.pushPoint({lat: point[1], lng: point[0]})
      }
      polys.push(new H.map.Polygon(lineString));
    }
    drawMultipleToMap(map,polys)
  }

  useEffect(()=>{
    setPlatform(new H.service.Platform({
      apikey: process.env.REACT_APP_HEREMAPS_JS_API_KEY
    }))
    //unmount effect
    return ()=>{
    }
  },[]) 
  // once platform inited, we can init layers
  useEffect(()=>{
    if (!!platform && !layers) {
      setLayers(platform.createDefaultLayers());
    }
  },[platform, layers]) 
  // once layers inited we can init map
  useEffect(()=>{
    if (!!platform && !!layers && !map) {
      initializeMap();
    }
  },[layers,platform,map])
  // once map inited we can init map interactivity + resize 
  useEffect(()=>{
    if(!map) return;
    addBarIcon();
    addUserMarker();
    initializeMapControls();
    // DEBUG HOOK
    // window.drawFencePolys();
  },[map])

  function initializeMap(){
    if (!platform || !layers) {return;}
    const map = new H.Map(mapContainer.current,
      layers.vector.normal.map,
      {
        center: {lat:37.223049, lng:-121.983765},
        zoom: 19,
        pixelRatio: window.devicePixelRatio || 1,
      }
    );
    setMap(map);
  }

  function addBarIcon(){
    //create dom icon and add/remove opacity listeners
    const destinationIcon = document.createElement('div');
    destinationIcon.className = styles.destinationIcon;
    const domIcon = new H.map.DomIcon(destinationIcon);

    var barMarker = new H.map.DomMarker({lat: eventLatLong.lat, lng: eventLatLong.long}, {
      icon: domIcon
    });
    map.addObject(barMarker);
  }

  function addUserMarker(){
    //create dom icon and add/remove opacity listeners
    const userIcon = document.createElement('div');
    userIcon.className = styles.userIcon;
    const domIcon = new H.map.DomIcon(userIcon);
    const loc = getLoc();
    if (!loc) return;
    const _userMarker = new H.map.DomMarker({lat: loc.lat, lng: loc.long}, {
      icon: domIcon
    });
    map.addObject(_userMarker);
    userMarker.current = _userMarker
    // setUserMarker(userMarker)
  }

  function updateUserMarker(loc) {
    if (!userMarker.current) return;
    const _loc = loc || getLoc();
    userMarker.current.setGeometry(new H.geo.Point(_loc.lat,_loc.long))
  }

  function centerMapToUser(){
    const _loc = getLoc();
    map.setCenter({lat:_loc.lat, lng:_loc.long});
    map.setZoom(17);
  }

  function centerMapToBar(){
    const _loc = eventLatLong
    map.setCenter({lat:_loc.lat, lng:_loc.long});
    map.setZoom(17);
  }

  function centerMapToRoute() {
    const mapObjects = map.getObjects();
    const route = mapObjects.find((obj)=>{return (obj.id && obj.id==="route")})
    const bb = route.getBoundingBox();
    const loc = bb.getCenter();
    focusMapToGeometry(route);
    return route
  }

  function addFocusButtonsToMap(ui){
    //control is like a wrapper
    const focusControls = new H.ui.Control();
    focusControls.addClass(styles.focusControls);
    ui.addControl("focusControls", focusControls);
    focusControls.setAlignment(H.ui.LayoutAlignment.TOP_RIGHT);

    const focusToUserButton = new H.ui.base.Button({
      label: "",
      onStateChange: (evt) => {
        // OK, button state changed... we don't care about up or down as there's no toggle, just one action
        centerMapToUser()
      },
    });
    focusToUserButton.addClass(styles.focusToUserButton)
    focusControls.addChild(focusToUserButton);

    const focusToBarButton = new H.ui.base.Button({
      label: "",
      onStateChange: (evt) => {
        // OK, button state changed... we don't care about up or down as there's no toggle, just one action
        centerMapToBar()
      },
    });
    focusToBarButton.addClass(styles.focusToBarButton)
    focusControls.addChild(focusToBarButton);

    const focusToRouteButton = new H.ui.base.Button({
      label: "",
      onStateChange: (evt) => {
        // OK, button state changed... we don't care about up or down as there's no toggle, just one action
        centerMapToRoute()
      },
    });
    focusToRouteButton.addClass(styles.focusToRouteButton)
    focusControls.addChild(focusToRouteButton);
    

  }

  async function initializeMapControls(){
    window.addEventListener('resize', () => map.getViewPort().resize()); //TODO roll into our debounced resize handler
    const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
    
    const ui = H.ui.UI.createDefault(map, layers);
    ui.setUnitSystem(H.ui.UnitSystem.IMPERIAL);

    const introPhrase = randomPhrase(introPhrases);
    const introPhrase2 = randomPhrase(departurePhrases)
    setTimeout(()=>{setPollyPhrase(`..... Hello ${user.attributes.name}. ${introPhrase} ${introPhrase2}`)},900);
    //colorize map
    changeFeatureStyle();

    //TEMP TODO DELME coord logger
    addMapDebugCoordLogger()

    //calc route
    const route = await calculateRoute(platform,getLoc(),eventLatLong);
    routeHandle.current = route.routeHandle;
    addRouteShapeToMap(route.route,true);
    setRoute(route.route);

    //add focus buttons
    addFocusButtonsToMap(ui);
  }

  const resetDistanceAnnouncedFlags = ()=>{
    distanceAnnouncedFlags.current = {
      '50ft': false,
      '500ft': false,
      '0.5mi': false,
      '1mi': false,
      '2mi': false,
      '5mi': false,
      '10mi': false,
      '20mi': false,
      'initial': false
    }
  }

  const initializeRouteTracking= async ()=>{
    const _route = await updateRouteTracking()
    centerMapToUser();
    // routeCheckTimer.current = window.setInterval(()=>{updateRouteTracking()},1000)
    resetDistanceAnnouncedFlags();
    lastActionListLength.current = _route.route.sections[0].actions.length;
    // alert('route.sections[0].actions.length;'+_route.sections[0].actions.length)
  }

  const parseAbbreviationsForPolly=(phrase)=>{
    let re = / dr/gmi;
    let _phrase = phrase.replace(re," drive");
    re = / undefined/gmi
    _phrase = _phrase.replace(re,"")
    re = / cir/gmi
    _phrase = _phrase.replace(re," circle") ;
    return _phrase;
  }
  
  const updateRouteTracking = ()=>{
    return new Promise(async (res,rej)=>{
      const mapToken = await getMapToken();
      // const routeRefresh = await refreshRoute(mapToken);
      // const routeRefresh = refreshRoute(mapToken)
      // debugger;
      const loc = getLoc();
      updateUserMarker({lat:loc.lat,long:loc.long})
      if (!routeHandle.current) return;
      refreshRoute(mapToken,loc,eventLatLong,routeHandle.current).then((routeRefresh)=>{
        if (routeRefresh !== 'REROUTE_NEEDED') {
          //clear route line
          clearRouteShapesFromMap(map)
          //draw new route line
          redrawUpdatedRoute(routeRefresh.route)
          
          const routeActions = routeRefresh.route.sections[0].actions
          const step = routeRefresh.route.sections[0].actions[0];
          let instruction = parseAbbreviationsForPolly(step.instruction);
          const nextStep = `${routeRefresh.route.sections[0].actions[1].action} ${routeRefresh.route.sections[0].actions[1].direction}`;
          const _nextInstruc = parseAbbreviationsForPolly(routeRefresh.route.sections[0].actions[1].instruction);
          const nextInstruction = `Go for ${routeRefresh.route.sections[0].actions[0].length} feet, then ${_nextInstruc || ''}`
          const instructionWithNextStep = `${instruction}. Then ${nextStep}`
          if (routeActions.length < lastActionListLength.current) {
            resetDistanceAnnouncedFlags();
            lastActionListLength.current = routeActions.length;
            // setPollyPhrase(instruction)
            // distanceAnnouncedFlags.current['initial'] = true
          }
          if(step.length <= 50 && !distanceAnnouncedFlags.current['50ft'] && distanceAnnouncedFlags.current['initial'])  {
            setPollyPhrase(nextInstruction)
            distanceAnnouncedFlags.current['50ft'] = true
          }
          else if(step.length <= 500 && step.length > 50 && !distanceAnnouncedFlags.current['500ft'] && distanceAnnouncedFlags.current['initial'])  {
            setPollyPhrase(nextInstruction)
            distanceAnnouncedFlags.current['500ft'] = true
            if (distanceAnnouncedFlags.current['50ft']) distanceAnnouncedFlags.current['50ft'] = false
          }
          else if(step.length <= milesToFeet(0.5) && step.length > 500 && !distanceAnnouncedFlags.current['0.5mi'] && distanceAnnouncedFlags.current['initial'])  {
            setPollyPhrase(instructionWithNextStep)
            distanceAnnouncedFlags.current['0.5mi'] = true
            if (distanceAnnouncedFlags.current['50ft']) distanceAnnouncedFlags.current['50ft'] = false
            if (distanceAnnouncedFlags.current['500ft']) distanceAnnouncedFlags.current['500ft'] = false
          }
          else if(step.length <= milesToFeet(1) && step.length > milesToFeet(0.5) &&  !distanceAnnouncedFlags.current['1mi'] && distanceAnnouncedFlags.current['initial'])  {
            setPollyPhrase(instruction)
            distanceAnnouncedFlags.current['1mi'] = true
            if (distanceAnnouncedFlags.current['50ft']) distanceAnnouncedFlags.current['50ft'] = false
            if (distanceAnnouncedFlags.current['500ft']) distanceAnnouncedFlags.current['500ft'] = false
            if (distanceAnnouncedFlags.current['0.5mi']) distanceAnnouncedFlags.current['0.5mi'] = false
          }
          else if(step.length <= milesToFeet(2) && step.length > milesToFeet(1) && !distanceAnnouncedFlags.current['2mi'] && distanceAnnouncedFlags.current['initial'])  {
            setPollyPhrase(instruction)
            distanceAnnouncedFlags.current['2mi'] = true
            if (distanceAnnouncedFlags.current['50ft']) distanceAnnouncedFlags.current['50ft'] = false
            if (distanceAnnouncedFlags.current['500ft']) distanceAnnouncedFlags.current['500ft'] = false
            if (distanceAnnouncedFlags.current['0.5mi']) distanceAnnouncedFlags.current['0.5mi'] = false
            if (distanceAnnouncedFlags.current['1mi']) distanceAnnouncedFlags.current['1mi'] = false
          }
          else if(step.length <= milesToFeet(5) && step.length > milesToFeet(2) && !distanceAnnouncedFlags.current['5mi'] && distanceAnnouncedFlags.current['initial'])  {
            setPollyPhrase(instruction)
            distanceAnnouncedFlags.current['5mi'] = true
            if (distanceAnnouncedFlags.current['50ft']) distanceAnnouncedFlags.current['50ft'] = false
            if (distanceAnnouncedFlags.current['500ft']) distanceAnnouncedFlags.current['500ft'] = false
            if (distanceAnnouncedFlags.current['0.5mi']) distanceAnnouncedFlags.current['0.5mi'] = false
            if (distanceAnnouncedFlags.current['1mi']) distanceAnnouncedFlags.current['1mi'] = false
            if (distanceAnnouncedFlags.current['2mi']) distanceAnnouncedFlags.current['2mi'] = false
          }
          else if(step.length <= milesToFeet(10) && step.length > milesToFeet(5) && !distanceAnnouncedFlags.current['10mi'] && distanceAnnouncedFlags.current['initial'])  {
            setPollyPhrase(instruction)
            distanceAnnouncedFlags.current['10mi'] = true
            if (distanceAnnouncedFlags.current['50ft']) distanceAnnouncedFlags.current['50ft'] = false
            if (distanceAnnouncedFlags.current['500ft']) distanceAnnouncedFlags.current['500ft'] = false
            if (distanceAnnouncedFlags.current['0.5mi']) distanceAnnouncedFlags.current['0.5mi'] = false
            if (distanceAnnouncedFlags.current['1mi']) distanceAnnouncedFlags.current['1mi'] = false
            if (distanceAnnouncedFlags.current['2mi']) distanceAnnouncedFlags.current['2mi'] = false
            if (distanceAnnouncedFlags.current['5mi']) distanceAnnouncedFlags.current['5mi'] = false
          }
          else if(step.length <= milesToFeet(20) && step.length > milesToFeet(10) && !distanceAnnouncedFlags.current['20mi'] && distanceAnnouncedFlags.current['initial'])  {
            setPollyPhrase(instruction)
            distanceAnnouncedFlags.current['20mi'] = true
            if (distanceAnnouncedFlags.current['50ft']) distanceAnnouncedFlags.current['50ft'] = false
            if (distanceAnnouncedFlags.current['500ft']) distanceAnnouncedFlags.current['500ft'] = false
            if (distanceAnnouncedFlags.current['0.5mi']) distanceAnnouncedFlags.current['0.5mi'] = false
            if (distanceAnnouncedFlags.current['1mi']) distanceAnnouncedFlags.current['1mi'] = false
            if (distanceAnnouncedFlags.current['2mi']) distanceAnnouncedFlags.current['2mi'] = false
            if (distanceAnnouncedFlags.current['5mi']) distanceAnnouncedFlags.current['5mi'] = false
            if (distanceAnnouncedFlags.current['10mi']) distanceAnnouncedFlags.current['10mi'] = false
          }
          else if(!distanceAnnouncedFlags.current['initial'])  {
            setPollyPhrase(instructionWithNextStep)
            distanceAnnouncedFlags.current['initial'] = true
          }
          // setPollyPhrase(route.sections[0].actions[0].instruction)

          // return
          res (routeRefresh)
        }
        else { //REROUTE_NEEDED
          stopRouteTracking();
          doReroute();
        }
      })
      
    })
    
  }

  //TODO DELME DEBUG
  const addMapDebugCoordLogger=()=>{
    map.addEventListener('tap',(e)=>{
      const coord = map.screenToGeo(e.currentPointer.viewportX,e.currentPointer.viewportY)
      // updateUserMarker({lat:coord.lat,long:coord.lng})
      window.currentLoc={lat:coord.lat,long:coord.lng}
      window.debugTapped = true;
      updateRouteTracking();
    })
  }

  function changeFeatureStyle(){
    // get the vector provider from the base layer
    var provider = map.getBaseLayer().getProvider();
    var style = new H.map.Style('https://hauntedbar-public.s3.us-west-2.amazonaws.com/mapColors.yml',        //'https://tml-misc-public.s3.us-west-1.amazonaws.com/mapColors.yaml'           //'https://heremaps.github.io/maps-api-for-javascript-examples/change-style-at-load/data/dark.yaml
          'https://js.api.here.com/v3/3.1/styles/omv/');
    // set the style on the existing layer
    provider.setStyle(style);
    // get the style object for the base layer
    var modStyle = provider.getStyle();
    var changeListener = (evt) => {
      if (modStyle.getState() === H.map.Style.State.READY) {
        modStyle.removeEventListener('change', changeListener);

        // query the sub-section of the style configuration
        // the call removes the subsection from the original configuration
        var modConfig = modStyle.extractConfig(['earth','water','places','buildings','landuse','roads']);
        window.modConfig = modConfig;
        window.modStyle=modStyle;
        // change the color, for the description of the style section
        // see the Developer's guide
        modConfig.layers.landuse.builtup.draw.polygons.color = '#222222'
        modConfig.layers.landuse.other.draw.polygons.color = '#000000'
        
        modConfig.layers.water.draw.polygons.color = '#640303'
        modConfig.layers.water.river.draw.lines.color = '#640303'
        modConfig.layers.water.small_water.draw.polygons.color = '#640303'
        modConfig.layers.water.deep_water.draw.polygons.color="#440303"
        modConfig.layers.landuse.beach.draw.polygons.color="#360202"

        modConfig.layers.landuse.military.draw.polygons.color = "#000000"
        modConfig.layers.landuse.glacier.draw.polygons.color = '#000000'
        modConfig.layers.landuse.university.draw.polygons.color = '#000000'

        modConfig.layers.landuse.draw.polygons.color = '#222222'
        // merge the configuration back to the base layer configuration
        modStyle.mergeConfig(modConfig);
      }
    };

    modStyle.addEventListener('change', changeListener);
  }

  function addRouteShapeToMap(route,autoFocus=false) {
    route.sections.forEach((section) => {
      // decode LineString from the flexible polyline
      let linestring = H.geo.LineString.fromFlexiblePolyline(section.polyline);

      // Create a polyline to display the route:
      let polyline = new H.map.Polyline(linestring, {
        style: {
          lineWidth: 4,
          strokeColor: 'rgba(200, 0, 0, 0.7)'
        }
      });
      polyline.id="route";
      // Add the polyline to the map
      map.addObject(polyline);
      // And zoom to its bounding rectangle
      if (autoFocus) {
        focusMapToGeometry(polyline)
      }
    });
  }

  function redrawUpdatedRoute(route) {
    if (!route) return;
    route.sections.forEach((section) => {
      // decode LineString from the flexible polyline
      let linestring = H.geo.LineString.fromFlexiblePolyline(section.polyline);

      // Create a polyline to display the route:
      let polyline = new H.map.Polyline(linestring, {
        style: {
          lineWidth: 4,
          strokeColor: 'rgba(200, 0, 0, 0.7)'
        }
      });
      const bounds = polyline.getBoundingBox()
      polyline.id="route";
      // Add the polyline to the map
      map.addObject(polyline);
      // And zoom to its bounding rectangle
      // map.getViewModel().setLookAtData({
      //   bounds: polyline.getBoundingBox()
      // });
    });
  }

  const stopRouteTracking = ()=>{
    window.clearInterval(routeCheckTimer.current);
    routeCheckTimer.current = null;
  }

  const doReroute = async ()=>{
    // pause location updates 😅
    stopRouteTracking();
    // we need the bell sound and the polly reroute phrases
    // soundChannel.playSound(Bell1);
    // bellAudio.current.load();
    // bellAudio.current.currentTime = 0
    bellAudio.current.play();
    const reroutePhrase = randomPhrase(reroutePhrases)
    setTimeout(()=>{setPollyPhrase(reroutePhrase)},2000)
    // calc fresh route - do we need new func or can we repurpose our initial route calc func
    const newRoute = await calculateRoute(platform,getLoc(),eventLatLong);
    // draw new route, we have all the tools for this, clear routes and draw route 
    clearRouteShapesFromMap(map);
    routeHandle.current = newRoute.routeHandle;
    addRouteShapeToMap(newRoute.route);
    setRoute(newRoute.route);
    //restart location updates
    setTimeout(initializeRouteTracking,6500)
    // initializeRouteTracking();
    //DEBUG HOOK
    // window.updateRouteManual = updateRouteTracking
  }

  const focusMapToGeometry = (geometry)=>{
    if(!geometry?.getBoundingBox) return;
    const bb = geometry.getBoundingBox();
    const view = map.getViewModel();
    
    view.setLookAtData({
      bounds: bb
    });
    setTimeout(()=>{
      const z = view.getLookAtData().zoom;
      view.setLookAtData({zoom: z-0.25})
    },400)

  }

  const showNoDriveModal = ()=>{
    setMainModalType('goldBorder');
    setMainModalContent(<NoDriveScreen launchAction={startRouting} />);
  }

  const startRouting = ()=>{
    setMainModalContent(null);
    const launchPhrase = randomPhrase(departurePhrases);
    setPollyPhrase(launchPhrase);
    setRoutingLaunched(true);
    // INIT TURN BY TURN HERE - NEEDS WORK
    setTimeout(initializeRouteTracking,1100);
  }

  // const announceNextStep = ()=>{
  //   // routes has array of sections, each section has an array of actions - how do we represent this
  //   const thisMove = route.sections[0].actions[0].instruction;
  //   const nextMove = route.sections[0].actions[1].action + " " + route.sections[0].actions[1].direction;
  //   const direction = thisMove + ". Then, " + nextMove;
  //   setTimeout(()=>{setPollyPhrase(direction)},2100)
  // }

  // const updateDirectionsPanel = ()=>{
  //   // list all dirs as styled text below map
  // }
  
  if (userIsAroundBar) {
    return <>
      Welcome.
    </>
  }
  else {
    return <>
    <div className={styles.mapContainer} ref={mapContainer}></div>
    {!routingLaunched && <Link className={styles.letsGo} onClick={showNoDriveModal}><img src={LetsGoBtn} alt="Let's Go!" /></Link>}
    {pollyPhrase && <PollyPlayer phrase={pollyPhrase} />}
    </>
  }
}



export default MapGuide