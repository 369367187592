import styles from './styles.module.scss';
import { API } from 'aws-amplify';
import * as mutations from '../../graphql/mutations';
import { useRef, useState } from 'react';
const BatchPushBurnables = (props)=>{
  const results = useRef();
  const handleFileSelect = (e)=>{
    e.stopPropagation();
    e.preventDefault();
    let  files;
    if (!!e.dataTransfer) {
      files = e.dataTransfer.files;
    }
    else {
      files = e.target.files;
    }
    const file = files[0]
    const reader = new FileReader();
    reader.onload = (e)=>{parseFile(JSON.parse(e.target.result))}
    reader.readAsText(file);
  }
  const parseFile = (objects)=>{
    let malformed = 0;
    for (let i = 0;i<objects.length;i++){
      const obj = objects[i]
      if (
        typeof obj?.guid !== 'string' ||
        typeof obj?.experienceName !== 'string' ||
        typeof obj?.uses !== 'number'
      ) {
        alert('malformed input');
        malformed = 1;
        break;
      }
    }
    if (malformed) return;
    doPush(objects)
  } 
  const doPush = async (objects)=>{
    const numToCreate = objects.length;
    const experienceName = objects[0].experienceName;
    const usesEach = objects[0].uses;
    const ok = window.confirm(`Push ${numToCreate} Burnables to Database for experience ${experienceName} in env ${process.env.REACT_APP_BUILD_ENV}? There is no undo!`)
    if (!ok) {
      return;
    }
    const guids = objects.map((obj)=>{
      return obj.guid
    })
    /* BATCH PUSH SCRIPT*/
    // can only batch push 25 at once so break up into calls of 25 each plus remainder
    const numFullCalls = Math.floor(Number(numToCreate) / 25);
    const numCallsRemainder = Number(numToCreate)%25;
    let numPushed = {min:0,max:Math.min(24,Number(numToCreate))};
    let errs = [];
    for (let i = 0; i < numFullCalls; i++) {
      const sendGuids = guids.slice(numPushed.min,numPushed.max+1);
      results.current.innerText = `Pushing ${numPushed.min+1}-${numPushed.max+1} of ${numToCreate}...`; 
      try {
        const res = await API.graphql({ query: mutations.batchCreateBurnables, variables:{input:JSON.stringify({count:25,experience:experienceName,usesEach:usesEach,guids:sendGuids})} }) 
        numPushed = {min:numPushed.min+25,max:numPushed.max+25}
      }
      catch(err){
        errs.push(err);
      }
    }
    if (numCallsRemainder > 0) {
      results.current.innerText = `Pushing ${numPushed.min+1}-${numPushed.max} of ${numToCreate}...`;
      const sendGuids = guids.slice(numPushed.min,numPushed.max+1);
      try {
        const res = await API.graphql({ query: mutations.batchCreateBurnables, variables:{input:JSON.stringify({count:numCallsRemainder,experience:experienceName,usesEach:usesEach,guids:sendGuids})} }) 
        // numPushed = {min:numPushed.min+25,max:numPushed.max+25}
      }
      catch(err){
        errs.push(err);
      }
    }

   

    let output = `Push complete. Errors:`;
    if (!errs.length){
      output += ` None.`
    }
    else {
      for (let i = 1; i <= errs.length; i++) {
        output+=`\n${i}: ${errs[i]}`
      }
    }
    results.current.innerText = output;

     /*END BATCH PUSH SCRIPT */
  }
  const dropZoneDragOver = (e)=>{
    e.stopPropagation();
    e.preventDefault();
    e.dataTransfer.dropEffect='copy'
  }
  const dropZoneDrop = (e)=>{
    handleFileSelect(e);
  }
  return (<div className={styles.BatchPushBurnables}>
    <input type="file" name="file" onChange={handleFileSelect} />
    <div className={styles.dropZone} onDragOver={dropZoneDragOver} onDrop={dropZoneDrop}/>
    <h1 ref={results}>{"Waiting..."}</h1>
  </div>)

}


export default BatchPushBurnables