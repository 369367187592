import React, {useContext,useEffect,useRef} from 'react';
import {MainContext} from "../DataManager";
import {Redirect,Route} from 'react-router-dom';
import AuthGreeting from '../AuthGreeting';
const AuthGuard = ({children,...props})=>{
  const {user,setMainModalContent,setMainModalTabs,setMainModalType, hideModal} = useContext(MainContext);
  useEffect(()=>{
    if(!!user){
      if (!user?.attributes?.email_verified ) {
        //force verification modal
      }
      else { //grant access
        clearModal();
      }
    }
  },[])
  useEffect(()=>{
    if(!!user){
      if (!user?.attributes?.email_verified ) {
        //force verification modal
      }
      else { //grant access
        clearModal();
      }
    }
  },[user,setMainModalTabs,setMainModalContent])
  const clearModal = ()=>{
      setMainModalTabs(null);
      setMainModalContent(null);
  }
  return (<>
    {!user && !hideModal && <><AuthGreeting /></>}
    {!!user && !!user?.attributes?.email_verified && <>{children}</>}
  </>)
}

export default AuthGuard