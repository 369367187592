import React from 'react';
import styles from './styles.module.scss';
import ClientIndirectControl from '../Standard/ClientIndirectControl';
import SeanceControlUI from '../../UIs/Seance/SeanceControlUI';
import SeanceLauncherUI from '../../UIs/Seance/SeanceLauncherUI';

const SeanceClient = (props)=>{
  const {experienceName="Seance",wallpaperImg} = props;
  return (<ClientIndirectControl experienceName={experienceName} launcherComponent={SeanceLauncherUI} controlComponent={SeanceControlUI} wallpaperImg={wallpaperImg} />)
}

export default SeanceClient;