import React from "react";
import GoldBorderModal from "../../../Components/Modals/GoldBorderModal";
import DecoButton from "../../../Components/DecoButton";

const WritingLauncherUI = (props)=>{
  const {guid,API,mutations,experienceName} = props;
  const launch = async ()=>{
    await API.graphql({ query: mutations.launchBurnableExperience, variables:{id:guid,env:process.env.REACT_APP_BUILD_ENV,experienceName:experienceName} }) 
  }
  const btn = <DecoButton onMouseDown={launch} onTouchStart={launch} fontSize="1.9rem" textPadding="0 0.4em" text='Connect' />
  return (<GoldBorderModal {...props} modalContent={<div style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column',paddingBottom:'35px'}}><h1 style={{marginBottom:'65px',display:'block'}}>The Writing on the Wall</h1>{btn}</div>}/>)
}

export default WritingLauncherUI