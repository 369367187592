import React from "react";
import styles from "./styles.module.scss";
const UserFileInput = (props) => {
  const { handleInput, children } = props;
  return (
    <form className={styles.inputForm}>
      <input
        className={styles.fileInput}
        type="file"
        name="file"
        id="file"
        onChange={handleInput}
      />
      {children}
    </form>
  );
};

export { UserFileInput };
