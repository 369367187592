const introPhrases = [
  "Are you ready to get spoopy?",
  "It's a beautiful night for a nightmare."
]

const departurePhrases = [
  "Let's go to the Haunted Bar.",
  "Are you ready to go to the Haunted Bar?",
  "Is it time to go?"
]

const reroutePhrases = [
  "Uh oh. I'll find another path.",
  "Hold on, I think I can do it another way.",
  "It's ok, we can try again.",
  "We can't go here. This is bat country. I'll find another road.",
  "Whoopsy. I'll find a different way.",
  "We can't go here. This is bat country. I'll find another road.",
]

const randomPhrase=(phrases)=>{
  const phraseNum = Math.floor(Math.random()*phrases.length)
  return phrases[phraseNum];
}

export {introPhrases,departurePhrases,randomPhrase,reroutePhrases}