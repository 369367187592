import React from 'react';
import { ThemeProvider,createTheme } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';

const _theme = createTheme({
  palette:{
    primary: {
      main: grey[900],
    },
    secondary: {
      main: red[700]
    },
    go: {
      main: green[700]
    }
  },
  modal: {
    defaultWidth: '50vw',
    defaultPosition: {
      left: '50%',
      top: '50px',
      transform: 'translate3d(-50%,0,0)'
    }
  }
});

const Theme = (props)=>{
  return (<ThemeProvider theme={_theme}>{props.children}</ThemeProvider>)
}

export default Theme