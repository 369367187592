import React,{useContext, useEffect,useRef} from "react";
import styles from './styles.module.scss';
import {MainContext} from '../../DataManager';
import BarSignHand from '../../../Img/Wallpapers/barSignHand.jpg';
import Crash512 from '../../../Img/crash512.png';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid'
import DecoButton from '../../DecoButton'
const useStyles = makeStyles({
  root: {
    marginRight: '10px',
  },
  icon: {
    border: `solid 1px #f00`,
    borderRadius: 3,
    backgroundColor: 'rgba(255,255,255,1)',
    width: 40,
    height: 40,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    // backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#cc1111',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#a11',
    // backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 50,
      height: 50,
      backgroundImage: `url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWw6c3BhY2U9InByZXNlcnZlIiB2ZXJzaW9uPSIxLjEiIHN0eWxlPSJzaGFwZS1yZW5kZXJpbmc6Z2VvbWV0cmljUHJlY2lzaW9uO3RleHQtcmVuZGVyaW5nOmdlb21ldHJpY1ByZWNpc2lvbjtpbWFnZS1yZW5kZXJpbmc6b3B0aW1pemVRdWFsaXR5OyIgdmlld0JveD0iMCAwIDMzMyAzOTguNzUiIHg9IjBweCIgeT0iMHB4IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCI+PGRlZnM+PHN0eWxlIHR5cGU9InRleHQvY3NzIj4KICAgCiAgICAuZmlsMCB7ZmlsbDpibGFja30KICAgCiAgPC9zdHlsZT48L2RlZnM+PGc+PHBhdGggY2xhc3M9ImZpbDAiIGQ9Ik01NCAxNTRsNjQgNzQgMTg3IC0yMjJjMTQsLTE1IDM2LDEgMjUsMThsLTE4NCAyODFjLTE0LDE4IC0zMywyMCAtNDksMmwtOTMgLTExMWMtMTgsLTI2IDI4LC02MyA1MCwtNDJ6Ii8+PC9nPjwvc3ZnPg==')`,
      backgroundRepeat: 'no-repeat',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#cc1111',
    },
  },
});



const NoDriveScreen = (props)=>{
  const {setWallpaperImg} = useContext(MainContext)
  useEffect(()=>{
    setWallpaperImg(BarSignHand)
  },[])
  const checked = useRef();
  const checkboxRef = useRef();
  const checkboxClasses = useStyles();
  const confirmCheck = ()=>{
    if(!!checked.current) {
      props.launchAction();
    }
    else {
      checkboxRef.current.classList.add(styles.shakeCheck)
      // window.checkboxRef = checkboxRef;
    }
  }
  const changeCheck = (e)=>{
    checked.current=e.target.checked
  }
  return (
    <div className={styles.NoDriveScreen}>
      <img className={styles.crunch} src={Crash512} alt='Crunch' />
      <h1 className={styles.header}>Warning</h1>
      <p className={styles.para}>This app is for PASSENGERS and PEDESTRIANS <em>ONLY!!</em></p>
      <p className={styles.para}>Do not use this app while driving!<br />DO NOT DRIVE WHILE USING THIS APP!</p>
      <p className={styles.para}>By checking the box below, you certify that you will practice good sense and safety while driving, and that you absolutely will not use this app while driving.</p>
      <form className={styles.form}>
        <Grid container spacing={0}>
          <Grid item xs={3} className={styles.checkboxCol}>
            <Checkbox 
              className={checkboxClasses.root} 
              color="default" 
              ref={checkboxRef}
              onChange={changeCheck}
              checkedIcon={<span className={clsx(checkboxClasses.icon,checkboxClasses.checkedIcon)}/>} 
              icon={<span className={checkboxClasses.icon} />}
            />
          </Grid>
          <Grid item xs={9} className={styles.spanCol}>
            <span className={styles.checkLabel}>I swear that I will never use this app while driving.</span>
          </Grid>
        </Grid>
      </form>
      <div className={styles.bottomRow}>
        <div className={styles.swearBtn}>
          <DecoButton text="Swear" type="light" fontSize="1.6rem" textPadding="0 1em" onClick={confirmCheck} actionIfEnterOrSpace={confirmCheck} />
        </div>
      </div>
    </div>
  )
}

export default NoDriveScreen