/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const initUser = /* GraphQL */ `
  mutation InitUser($identityId: String) {
    initUser(identityId: $identityId)
  }
`;
export const launchBurnableExperience = /* GraphQL */ `
  mutation LaunchBurnableExperience(
    $id: String
    $env: String
    $experienceName: String
  ) {
    launchBurnableExperience(
      id: $id
      env: $env
      experienceName: $experienceName
    )
  }
`;
export const batchCreateBurnables = /* GraphQL */ `
  mutation BatchCreateBurnables($input: String!) {
    batchCreateBurnables(input: $input)
  }
`;
export const decrementBurnable = /* GraphQL */ `
  mutation DecrementBurnable($id: String) {
    decrementBurnable(id: $id)
  }
`;
export const doFaceCutter = /* GraphQL */ `
  mutation DoFaceCutter(
    $sourceFile: String!
    $destBucket: String!
    $username: String!
  ) {
    doFaceCutter(
      sourceFile: $sourceFile
      destBucket: $destBucket
      username: $username
    )
  }
`;
export const createTodo = /* GraphQL */ `
  mutation CreateTodo(
    $input: CreateTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    createTodo(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const updateTodo = /* GraphQL */ `
  mutation UpdateTodo(
    $input: UpdateTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    updateTodo(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const deleteTodo = /* GraphQL */ `
  mutation DeleteTodo(
    $input: DeleteTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    deleteTodo(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const createBurnable = /* GraphQL */ `
  mutation CreateBurnable(
    $input: CreateBurnableInput!
    $condition: ModelBurnableConditionInput
  ) {
    createBurnable(input: $input, condition: $condition) {
      id
      experience
      usesLeft
      createdAt
      updatedAt
    }
  }
`;
export const updateBurnable = /* GraphQL */ `
  mutation UpdateBurnable(
    $input: UpdateBurnableInput!
    $condition: ModelBurnableConditionInput
  ) {
    updateBurnable(input: $input, condition: $condition) {
      id
      experience
      usesLeft
      createdAt
      updatedAt
    }
  }
`;
export const deleteBurnable = /* GraphQL */ `
  mutation DeleteBurnable(
    $input: DeleteBurnableInput!
    $condition: ModelBurnableConditionInput
  ) {
    deleteBurnable(input: $input, condition: $condition) {
      id
      experience
      usesLeft
      createdAt
      updatedAt
    }
  }
`;
