import React from "react"
import styles from './styles.module.scss'
import ClientIndirectControl from '../Standard/ClientIndirectControl';
import ClientModeratedControl from '../Standard/ClientModeratedControl';
import WritingControlUI from '../../UIs/Writing/WritingControlUI';
import WritingLauncherUI from "../../UIs/Writing/WritingLauncherUI";
import WritingThankYouUI from '../../UIs/Writing/WritingThankYouUI';
const WritingClient = (props)=>{
  const {mode='moderated'} = props;
  return <>
  {mode==='direct' && <ClientIndirectControl {...props} thankYouComponent={WritingThankYouUI} launcherComponent={WritingLauncherUI} controlComponent={WritingControlUI}/>}
  {mode==='moderated' && <ClientModeratedControl {...props} thankYouComponent={WritingThankYouUI} launcherComponent={WritingLauncherUI} controlComponent={WritingControlUI} />}
  </>

}

export default WritingClient 