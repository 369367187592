import React from 'react';
import styles from './styles.module.scss';
import DecoButton from '../DecoButton'

const CreateBurnablesForm = (props)=>{
  const {experienceName,numToCreate,usesEach,launchCreate,results} = props;
  return (
  <div className={styles.createBurnablesForm}>
    <label for="experienceName">
      Experience name:
    </label>
    <input type="text" name="experienceName" ref={experienceName} />
    <label for="numToCreate">
      Number of burnables to create:
      </label>
    <input type="number" name="numToCreate" min="1" max="1000" ref={numToCreate} /> 
    <label for="usesPer">
      Uses per Burnable: (locked to 1)
    </label>
    <input type="number" name="usesPer" value={1} disabled min="1" max="500" ref={usesEach} /> 
    <div className={styles.buttonDiv}>
      <DecoButton text={numToCreate < 2?  'Create burnable' : 'Create Burnables'} onClick={launchCreate} onEnterOrSpace={launchCreate}/>
    </div>
    {/* <Button variant="contained" onClick={launchCreate} >{numToCreate < 2?  'Create burnable' : 'Create Burnables'}</Button> */}
    <div className={styles.resultsBar}>
      <h1 ref={results}></h1>
    </div>
  </div>
  )
}



export default CreateBurnablesForm;