import React, {useRef,useState,useContext,useEffect} from 'react';
import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/Send';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import {Auth} from 'aws-amplify';
import {MainContext} from '../DataManager/';
import styles from './styles.module.scss'
import VerificationSuccess from '../VerificationSuccess'
import DecoButton from '../DecoButton'



const VerificationForm = (props)=>{
  const {user,codeDestination,setModalContent,username} = props;
  const {setMainModalContent,setMainModalTabs,setLoadingModalContent} = useContext(MainContext);
  const digitRefs = [useRef(),useRef(),useRef(),useRef(),useRef(),useRef()]
  const submitButtonRef = useRef();
  const [codeMismatch,setCodeMismatch] = useState(false);
  const resendVerificationCode = ()=>{
      if (!user) {
        //we are on login page attempting to login with an unconfirmed account
        Auth.resendSignUp(username)
        setLoadingModalContent('default');
        setTimeout(()=>{setLoadingModalContent(null);},250)
      }
      else {
        // otherwise we are on signup page creating new account
        Auth.resendSignUp(user.user.username)
        setLoadingModalContent('default');
        setTimeout(()=>{setLoadingModalContent(null);},250)
      }
  }
  const handleVerificationDigitChange = (e)=>{
    if (e.target.value.length > 5 && e.target.value.length < 7) {
      //focus send button
      submitButtonRef.current.focus()
    }  
    // const idx = Number(e.target.getAttribute('idx'));
    // if (e.target.value.length > 1) {
    //   e.target.value = e.target.value.slice(0,1)
    // }
    // else if (e.target.value.length < 1 ) {
    //   return;
    // }
    // if (idx === digitRefs.length-1) {
    //   //focus send button
    //   submitButtonRef.current.focus()
    // }
    // else {
    //   digitRefs[idx+1].current.focus();
    // }
  }
  const handleDigitFocus = (e)=>{
    e.target.select();
  }
  const verifyVerificationCode = ()=>{
    // let code = "";
    // for (let i = 0; i < digitRefs.length; i++) {
    //   code += digitRefs[i].current.value;
    // }
    let code = digitRefs[0].current.value;
    console.log('!vc code=',code)
    if (props.h) { //we are coming from signup, do signup flow
      setLoadingModalContent('default');
      Auth.confirmSignUp(username,code).then((val)=>{
        //val will == 'SUCCESS' on successful verification
        if (val === 'SUCCESS') {
          setMainModalTabs([{tabTitle:'Verify',tabContent:<VerificationSuccess username={username} h={props.h} s={props.s} />}])
        }
      }).catch((err)=>{
        console.log('!cu err',err)
        setLoadingModalContent(null);
        if (err.code === "CodeMismatchException") {
          setCodeMismatch(true);
        }
      })
    }
    else { // we are coming from reset password, do reset password flow
      
    }
    
  }
  useEffect(()=>{digitRefs[0].current.focus()},[]) //focus first digit on first render
  return (
    <div className={styles.VerificationForm}>
      <h3>Ok! We have sent a verification code via {codeDestination}. Please enter it below:</h3>
      {codeMismatch && <div className={styles.codeMismatch}> <div><ReportProblemIcon /> Uh-oh! That code didn't match. Please try again :) </div> </div>}
      <input type="number" idx={0} ref={digitRefs[0]} onChange={handleVerificationDigitChange} onFocus={handleDigitFocus} className={styles.SMSVerificationDigit} min="0" max="9" />
      {/* <input type="number" idx={1} ref={digitRefs[1]} onChange={handleVerificationDigitChange} onFocus={handleDigitFocus} className={styles.SMSVerificationDigit} min="0" max="9" />
      <input type="number" idx={2} ref={digitRefs[2]} onChange={handleVerificationDigitChange} onFocus={handleDigitFocus} className={styles.SMSVerificationDigit} min="0" max="9" />
      <input type="number" idx={3} ref={digitRefs[3]} onChange={handleVerificationDigitChange} onFocus={handleDigitFocus} className={styles.SMSVerificationDigit} min="0" max="9" />
      <input type="number" idx={4} ref={digitRefs[4]} onChange={handleVerificationDigitChange} onFocus={handleDigitFocus} className={styles.SMSVerificationDigit} min="0" max="9" />
      <input type="number" idx={5} ref={digitRefs[5]} onChange={handleVerificationDigitChange} onFocus={handleDigitFocus} className={styles.SMSVerificationDigit} min="0" max="9" /> */}
      <div className={styles.bottomRow}>
        <div className={styles.goBtn}>
          <DecoButton ref={submitButtonRef} text="Confirm" type="dark" fontSize="1.5rem" onClick={verifyVerificationCode} onEnterOrSpace={verifyVerificationCode}/>
        </div>
        {/* <IconButton className={styles.submitVerificationCode} ref={submitButtonRef} onClick={verifyVerificationCode}><SendIcon/></IconButton> */}
        <span className={styles.resend} onClick={resendVerificationCode}>Resend Code</span>
      </div>
    </div>
  )
}


export default VerificationForm