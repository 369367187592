import React, {useState,useContext,useEffect,useRef} from "react"
import styles from './styles.module.scss'
import {MidiManager,MidiContext} from '../MidiManager';
import { useConfigurables } from "../../Utils/triggerUtils";
import ConfigMenu from './configMenu'
import ConfigModal from './configModal'
import clsx from 'clsx'
import {usePrevious} from '../../Hooks/generalHooks';
import SettingsSharpIcon from '@material-ui/icons/SettingsSharp';
import IconButton from '@material-ui/core/IconButton'
import LockIcon from '@material-ui/icons/Lock';
import CountdownTimer from './countdownTimer'
import {ReactComponent as BootIcon} from '../../Img/Icons/boot.svg'
// applies default vals to configs when init component
const initializeConfigurables = (configurables)=>{
  const _configurables = configurables.map((configurable)=>{
    if (configurable.value === undefined || configurable.value === null) {
      configurable.value = configurable?.defaultValue;
    }
    return configurable;
  })
  return _configurables;
}

const ExperienceControlRelayInner = (props)=>{
  const {experienceName,children} = props;
  const {sendNoteOnToMain,sendNoteOffToMain} = useContext(MidiContext);
  const [configurables,setConfigurables] = useState(initializeConfigurables(props.configurables)); 
  const parsedConfigurables = useConfigurables(configurables);
  const [configModalContent,setConfigModalContent] = useState(null);
  const [status, setStatus] = useState('idle');
  const prevStatus = usePrevious(status);
  const [lockoutUntil,setLockoutUntil] = useState(0);
  const prevLockoutUntil = usePrevious(lockoutUntil);
  const configurablesRef = useRef(null); 
  const [allowedUseUntil,setAllowedUseUntil]= useState();
  const [allowedIdleUntil,setAllowedIdleUntil] = useState();
  const [lockoutReset,setLockoutReset] = useState(0); // trigger to children to reset lockout
  const [kickAndBurnUser,setKickAndBurnUser] = useState(0); // trigger to children to terminate user connection and burn token
  const [kickUser,setKickUser] = useState(0); // trigger to children to terminate user connection and not burn token


  useEffect(()=>{
    configurablesRef.current = parsedConfigurables;
  },[parsedConfigurables])

  useEffect(()=>{
    if(lockoutUntil !== prevLockoutUntil) {
      if(!!lockoutUntil) {
        setStatus("lockout")
      }
      else if (!lockoutUntil || Date.now()>lockoutUntil) {
        setStatus("idle")
      }
    }
  },[lockoutUntil])

  const showConfig = ()=>{
    setConfigModalContent(<ConfigMenu configurables={configurables} setConfigurables={setConfigurables} setConfigModalContent={setConfigModalContent} experienceName={experienceName}/>)
  }

  const hideConfig = ()=>{
    setConfigModalContent(null);
  }
  
  const triggerOn= (!sendNoteOnToMain)? null : ()=>{
    sendNoteOnToMain(configurablesRef.current.midiNote)
    setStatus('active')
    // store date.now+allowed use time so we can show timer in kick box
    if (!!configurablesRef.current.allowedUseTime) {
      setAllowedUseUntil(Date.now()+configurablesRef.current.allowedUseTime)
    }
  }

  const triggerOff =  (!sendNoteOnToMain)? null : ()=>{
    sendNoteOffToMain(configurablesRef.current.midiNote)
  }

  const setConnected = (isConnected)=>{
    if (!!isConnected) {
      setStatus("userConnected")
      setAllowedIdleUntil(Date.now()+configurablesRef.current.idleTimeout);
    }
    else if (!isConnected) {
      setStatus("idle")
    }
  }

  const decoratedChildren = React.Children.map(children,(child,i)=>{
    return React.cloneElement(child,{configurables:configurables,experienceName:experienceName,triggerOn:triggerOn,triggerOff:triggerOff, setConnected:setConnected, setLockoutUntil:setLockoutUntil, lockoutReset:lockoutReset,setParentStatus:setStatus,kickAndBurnUser:kickAndBurnUser,kickUser:kickUser})
  }) 

  const statusDisplayClass = ()=>{
    switch(status) {
      case "idle":
        return styles.ExperienceControlRelayIdle;
      case "userConnected":
        return styles.ExperienceControlRelayUserConnected;
      case "active":
        return styles.ExperienceControlRelayActive;
      case "lockout":
        return styles.ExperienceControlRelayLockout;
      default:
        break;
    }
  }

  const clearLockout = ()=>{
    setLockoutReset(Date.now());
    // setStatus('idle');
    // setLockoutUntil(0);
  }

  const doKickAndBurnUser = ()=>{
    setKickAndBurnUser(Date.now());
  }

  const doKickUser=()=>{
    setKickUser(Date.now())
    setAllowedIdleUntil(null);
    setAllowedUseUntil(null);
  }

  return (
    <>
      <div className={statusDisplayClass()}>
        {/* <span style={{color:'#fff',marginRight:'5em'}} className={styles.gearBtn} onClick={showConfig}>Config</span> */}
        <IconButton onClick={showConfig} className={styles.configBtn}><SettingsSharpIcon /></IconButton>
        {!!lockoutUntil && <div className={styles.lockoutGroup}>
          <LockIcon onClick={clearLockout}  />  
          <span onClick={clearLockout} ><CountdownTimer endTime={lockoutUntil} /></span>
        </div>}
        {(status === 'active' || status === 'userConnected') && <div className={styles.kickGroup}>
            <BootIcon onClick={doKickAndBurnUser} />
            <span onClick={doKickAndBurnUser} ><CountdownTimer endTime={allowedUseUntil} /></span>
          </div>}
        {status==='userConnected' && <div className={styles.kickGroup}>
            <BootIcon onClick={doKickUser} />
            <span onClick={doKickUser} ><CountdownTimer endTime={allowedIdleUntil} /></span>
          </div>}
        <h1 className={styles.expNameHeader}>{experienceName}</h1>
        {decoratedChildren}
      </div>
      {!!configModalContent && <ConfigModal configModalContent={configModalContent} />}
    </>
  )
}

const ExperienceControlRelay = (props)=>{
  return (
    <MidiManager>
      <ExperienceControlRelayInner  {...props} />
    </MidiManager>
  )
}

export default ExperienceControlRelay;