import React from "react";
import ExperienceControlRelay from "../ExperienceControlRelay";
import styles from "./styles.module.scss";
import experienceList from './experienceList.jsx';
import Grid from '@material-ui/core/Grid'
const ShowControlBoard = (props)=>{
  
  const experienceControlRelays = experienceList.map((experience)=>{
    return (
      <Grid item xs={12} sm={4}>
        <ExperienceControlRelay configurables={experience.configurables} experienceName={experience.experienceName}>
          {experience.experienceControlComponent}
        </ExperienceControlRelay>
      </Grid>
    )
  })


  return (<div className={styles.ShowControlBoard}>
    <h1>Experience Status Board</h1>
    <Grid container item xs={12} spacing={1}>
      {experienceControlRelays}
    </Grid>
  </div>)
}

export default ShowControlBoard