import React from "react";

const parseConfigurables=(configurables,setter)=>{
  const _parsedConfigurables = {};
  configurables.map((configurable)=>{
    _parsedConfigurables[configurable.name] = configurable.value
  })
  setter(_parsedConfigurables)
}

const useConfigurables = (configurables)=>{
  let parsedConfigurables;
  const setter = (val)=>{parsedConfigurables = val}
  parseConfigurables(configurables,setter);
  return parsedConfigurables;
}


export {parseConfigurables, useConfigurables}