import React from "react";
import styles from './styles.module.scss'
import GoldBorderModal from "../../../Components/Modals/GoldBorderModal";
const DefaultControlUI_Lockout = (props)=>{
  const {lockoutUntil} = props;
  console.log('!rf DefaultControlsLockout, lockoutUntil',lockoutUntil)
  return (<GoldBorderModal {...props} modalContent={
    <>
      <h1>Lockout!</h1>
      <h1>This experience is in lockout mode until:</h1>
      <h1 className={styles.lockoutTime}>{new Date(lockoutUntil.current).toLocaleTimeString(
        'en-us',
        {
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          year: 'numeric',
          month: 'numeric',
          day: 'numeric'
        }
      )}</h1>
      <h1>Try again then!</h1>
    </>
  }/>)
}

export default DefaultControlUI_Lockout