import React from 'react';
import styles from './styles.module.scss';
import ClientIndirectControl from '../Standard/ClientIndirectControl';
import DolledControlUI from '../../UIs/Dolled/DolledControlUI';
import DolledLauncherUI from '../../UIs/Dolled/DolledLauncherUI';

const DolledClient = (props)=>{
  const {experienceName="Dolled",wallpaperImg} = props;
  return (<ClientIndirectControl experienceName={experienceName} launcherComponent={DolledLauncherUI} controlComponent={DolledControlUI} wallpaperImg={wallpaperImg} />)
}

export default DolledClient;