/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const checkBurnable = /* GraphQL */ `
  query CheckBurnable($id: String, $experienceName: String) {
    checkBurnable(id: $id, experienceName: $experienceName) {
      id
      experience
      usesLeft
      createdAt
      updatedAt
    }
  }
`;
export const listBurnablesByExperience = /* GraphQL */ `
  query ListBurnablesByExperience($experience: String) {
    listBurnablesByExperience(experience: $experience)
  }
`;
export const getTodo = /* GraphQL */ `
  query GetTodo($id: ID!) {
    getTodo(id: $id) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const listTodos = /* GraphQL */ `
  query ListTodos(
    $filter: ModelTodoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTodos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBurnable = /* GraphQL */ `
  query GetBurnable($id: ID!) {
    getBurnable(id: $id) {
      id
      experience
      usesLeft
      createdAt
      updatedAt
    }
  }
`;
export const listBurnables = /* GraphQL */ `
  query ListBurnables(
    $filter: ModelBurnableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBurnables(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        experience
        usesLeft
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
