import React, {useEffect} from "react";
// import TriggerNoControl from "../Experiences/Triggers/TriggerNoControl";
import TriggerIndirectControl from '../../Experiences/Triggers/TriggerIndirectControl'
// import UpdateTester from './updateTester'
const experienceList = [
	{
		experienceName: "Writing",
		experienceControlComponent: (<TriggerIndirectControl />),
		configurables: [
			{
				name: 'idleTimeout',
				defaultValue: 2*60*1000,
				isEditable: true,
				description: 'How long a user can sit idle in control of the experience before control is released to another user'
			},
			{
				name: 'midiNote',
				defaultValue: 1,
				isEditable: true,
				description: 'Numerical value of the MIDI note that this experience will use for triggering'
			},
			{
				name: 'lockoutTime',
				defaultValue: 10*60*1000, // x min in ms
				isEditable: true,
				description: 'Time that the experience will be "locked" after use to prevent over-frequent firings.'
			}
		]
	},
	{
		experienceName: "HouseFavorite",
		experienceControlComponent: (<TriggerIndirectControl />),
		configurables: [
			{
				name: 'idleTimeout',
				defaultValue: 2*60*1000,
				isEditable: true,
				description: 'How long a user can sit idle in control of the experience before control is released to another user'
			},
			{
				name: 'midiNote',
				defaultValue: 2,
				isEditable: true,
				description: 'Numerical value of the MIDI note that this experience will use for triggering'
			},
			{
				name: 'lockoutTime',
				defaultValue: 10*60*1000, // x min in ms
				isEditable: true,
				description: 'Time that the experience will be "locked" after use to prevent over-frequent firings.'
			}
		]
	},
	{
		experienceName: "Awaken",
		experienceControlComponent: (<TriggerIndirectControl />),
		configurables: [ 
			{
				name: 'idleTimeout',
				defaultValue: 2*60*1000,
				isEditable: true,
				description: 'How long a user can sit idle in control of the experience before control is released to another user'
			},
			{
				name: 'midiNote',
				defaultValue: 3,
				isEditable: true,
				description: 'Numerical value of the MIDI note that this experience will use for triggering'
			},
			{
				name: 'lockoutTime',
				defaultValue: 10*60*1000, // x min in ms
				isEditable: true,
				description: 'Time that the experience will be "locked" after use to prevent over-frequent firings.'
			}
		]
	},
	{
		experienceName: "Dolled",
		experienceControlComponent: (<TriggerIndirectControl />),
		configurables: [ 
			{
				name: 'idleTimeout',
				defaultValue: 2*60*1000,
				isEditable: true,
				description: 'How long a user can sit idle in control of the experience before control is released to another user'
			},
			{
				name: 'midiNote',
				defaultValue: 4,
				isEditable: true,
				description: 'Numerical value of the MIDI note that this experience will use for triggering'
			},
			{
				name: 'lockoutTime',
				defaultValue: 10*60*1000, // x min in ms
				isEditable: true,
				description: 'Time that the experience will be "locked" after use to prevent over-frequent firings.'
			}
		]
	},
	{
		experienceName: "Seance",
		experienceControlComponent: (<TriggerIndirectControl />),
		configurables: [ 
			{
				name: 'idleTimeout',
				defaultValue: 2*60*1000,
				isEditable: true,
				description: 'How long a user can sit idle in control of the experience before control is released to another user'
			},
			{
				name: 'midiNote',
				defaultValue: 5,
				isEditable: true,
				description: 'Numerical value of the MIDI note that this experience will use for triggering'
			},
			{
				name: 'lockoutTime',
				defaultValue: 10*60*1000, // x min in ms
				isEditable: true,
				description: 'Time that the experience will be "locked" after use to prevent over-frequent firings.'
			}
		]
	},
	{
		experienceName: "Widow",
		experienceControlComponent: (<TriggerIndirectControl />),
		configurables: [ 
			{
				name: 'idleTimeout',
				defaultValue: 2*60*1000,
				isEditable: true,
				description: 'How long a user can sit idle in control of the experience before control is released to another user'
			},
			{
				name: 'midiNote',
				defaultValue: 6,
				isEditable: true,
				description: 'Numerical value of the MIDI note that this experience will use for triggering'
			},
			{
				name: 'lockoutTime',
				defaultValue: 10*60*1000, // x min in ms
				isEditable: true,
				description: 'Time that the experience will be "locked" after use to prevent over-frequent firings.'
			}
		]
	},
	// {
	// 	experienceName: "IndirectControl",
	// 	experienceControlComponent: (<TriggerIndirectControl />),
	// 	configurables: [
	// 		{
	// 			name: 'idleTimeout',
	// 			defaultValue: 4*60*1000,
	// 			isEditable: true,
	// 			description: 'How long a user can sit idle in control of the experience before control is released to another user'
	// 		},
	// 		{
	// 			name: 'midiNote',
	// 			defaultValue: 1,
	// 			isEditable: true,
	// 			description: 'Numerical value of the MIDI note that this experience will use for triggering'
	// 		},
	// 		{
	// 			name: 'lockoutTime',
	// 			defaultValue: 10*60*1000, // x min in ms
	// 			isEditable: true,
	// 			description: 'Time that the experience will be "locked" after use to prevent over-frequent firings.'
	// 		},
	// 		{
	// 			name: 'allowedUseTime',
	// 			defaultValue: 1*60*1000, // x min in ms
	// 			isEditable: true,
	// 			description: 'Length of time that users are allowed to use the experience per QR code.'
	// 		}
	// 	]
	// },
]

export default experienceList