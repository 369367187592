import React,{useState,useEffect} from "react"
import styles from "./styles.module.scss"

const LeatherTabbedModal =(props)=>{
  const [activeTab,setActiveTab] = useState(props.activeTab || 0)
  const _setActiveTab = (idx)=>{
    if (idx === activeTab) return;
    setActiveTab(idx)
  }
  useEffect(()=>{
    const _active = props.activeTab || 0
    _setActiveTab(_active)
  },[props.modalTabs,props.activeTab])
  return (
    <div className={styles.LeatherTabbedModal}>
      <div className={styles.tabRow}>
        {props.modalTabs.map((tab,idx)=>{
          return (<a onClick={()=>{_setActiveTab(idx)}}className={idx===activeTab? styles.tabActive : styles.tab}>
            <span className={idx===activeTab? styles.tabTitleLinkActive: styles.tabTitleLink}>{tab.tabTitle}</span>
          </a>)
        })}
      </div>
      <div className={styles.contentRow}>
        <div className={styles.contentContainer}>
          {props.modalTabs.map((tab,idx)=>{
            return (<div className={idx===activeTab? styles.contentActive: styles.contentInactive}>
              {tab.tabContent}
            </div>)
          })}
        </div>
      </div>
    </div>
  )
}


export default LeatherTabbedModal;