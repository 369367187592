import React, {useState} from 'react';

function Channel({vol}){
  this.audio = new Audio();
  this.playSound = (snd)=>{
    this.audio.addEventListener("canplaythrough", event => {
      this.audio.play();
    });
    this.audio.addEventListener('error',(err)=>{console.log('error playing audio',err)})
    this.audio.src=`${window.location.origin}${snd}`;
    this.audio.setAttribute('src',`${window.location.origin}${snd}`)
    this.audio.load();
  }
  this.setSrc = ()=>{}
  this.setVol = ()=>{}
  this.play = ()=>{}
  this.pause = ()=>{}
  if(vol){
    this.audio.volume = vol
  }
}

const SoundContext = React.createContext("MainContext");

const SoundManager = (props)=>{

  const [Channels] = useState({
    routing: new Channel({vol:0.5})
  })

  // works
  // const audioTest = new Audio();
  // audioTest.src=Bell1;
  // setTimeout(()=>{audioTest.play()},5000)

  const _soundContext = {Channels}
  return (<SoundContext.Provider value={_soundContext}>{props.children}</SoundContext.Provider>)
}

export {SoundManager,SoundContext}