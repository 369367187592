import { useState, useEffect, useRef } from "react";
import {parseConfigurables} from '../Utils/triggerUtils'
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}


// function useConfigurablesRefs(configurables){
//   let parsedConfigurables;
//   const setter = (val)=>{parsedConfigurables = val}
//   parseConfigurables(configurables,setter);
//   let parsedConfigurablesRef = {};
//   const parsedConfigurablesRef = useRef({});
//   Object.keys(parsedConfigurables).map((key)=>{
//     const val = parsedConfigurables[key];
//     [key] = useRef(val);
//   })
// }

export {useWindowSize,usePrevious}