import React,{useRef, useState,useEffect, useContext} from "react"
import styles from "./styles.module.scss";
import GoldBorderModal from '../../../Components/Modals/GoldBorderModal'
import UserCam from '../../../Components/UserCam'
import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/Send';
import CancelScheduleSendIcon from '@material-ui/icons/CancelScheduleSend';
import {useWindowSize} from "../../../Hooks/generalHooks";
import { UserFileInput } from "./UserFileInput";
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DecoButton from "../../../Components/DecoButton";
import Storage from '@aws-amplify/storage'
import {MainContext} from '../../../Components/DataManager'; 
import * as mutations from '../../../graphql/mutations';
import { API } from 'aws-amplify';
const SpiritMakerUI = (props)=>{
  const {experienceName} = props;
  const containerRef = useRef();
  const [showUserCam,setShowUserCam] = useState(false)
  const [showFinalCanvas,setShowFinalCanvas] = useState(false)
  const [showUploadConfirmModal,setShowUploadConfirmModal] = useState(false);
  const [finalCanvasHeight, setFinalCanvasHeight] = React.useState();
  const [finalCanvasLastReset, setFinalCanvasLastReset] = React.useState(0);
  const [uploadDone, setUploadDone] = useState(false)
  const finalCanvasRef = useRef();
  const windowsize = useWindowSize();
  const {setLoadingModalContent,user} = useContext(MainContext);
  console.log('!md user:',user)
  useEffect(()=>{resetFinalCanvas();},[windowsize])

  useEffect(()=>{setTimeout(()=>{setFinalCanvasHeightProportional(containerRef.current)},5)},[finalCanvasLastReset])

  const setFinalCanvasHeightProportional=(container)=>{
    if (!container) return
    if (container.clientHeight > container.clientWidth) {
      //portrait set to width
      setFinalCanvasHeight(container.clientWidth)
    }
    else {
      //set to height
      setFinalCanvasHeight(container.clientHeight)
    }
  }

  const resetFinalCanvas = ()=>{
    setFinalCanvasHeight(1024);
    setFinalCanvasLastReset(Date.now())
  }

  const toggleCam = ()=>{
    setShowUserCam(!showUserCam)
  }

  const doCamCapture = (video) => {
    resetFinalCanvas();
    writeCamToCanvas(video);
    setShowFinalCanvas(true)
  };

  const writeCamToCanvas = (video) =>{
    //video is a ref referring to a video el in the userCam component
    const ctx = finalCanvasRef.current.getContext("2d")
    ctx.save()
    ctx.scale(-1,1)
    ctx.drawImage(
      video,
      (1024-video.clientWidth)/-2,
      (1024-video.clientHeight)/2,
      -video.clientWidth,
      video.clientHeight
    );
    ctx.restore();
  }

  const hideFinalCanvas = ()=>{
    setShowFinalCanvas(false)
  }

  const _showUploadConfirmModal = ()=>{
    setShowUploadConfirmModal(true);
  }

  const _hideUploadConfirmModal = ()=>{
    setShowUploadConfirmModal(false);
  }

  const loadFromDisk = (e) => {
    if (!!FileReader && e?.target?.files?.length) {
      // setShowFinalCanvas(true);
      const file = e.target.files[0];
      const img = new Image();
      const fr = new FileReader();
      fr.onload = () => {
        img.src = fr.result;
      };
      img.onload = () => {
        finalCanvasRef.current
          .getContext("2d")
          .drawImage(
            img,
            (1024-img.width)/2,
            (1024-img.height)/2,
            img.width,
            img.height
          );
        resetFinalCanvas();
        setShowFinalCanvas(true);
      };
      fr.readAsDataURL(file);
    }
  };


  const processUpload = async ()=>{
    // show loader
    setLoadingModalContent('default')
    finalCanvasRef.current.toBlob(async (blob)=>{
      const _filename = `moderation/${Date.now()}.png`
      const _username = user.attributes.email;
      const putResult = await Storage.put(_filename,blob,{level:'public',contentType:'image/png',metadata:{userName:_username,experienceName:experienceName}})
      console.log('upload image result',putResult) 
      let filePath = await Storage.get(_filename,{download:false, level:'public'}) 
      filePath = filePath.substring(0,filePath.indexOf('?'))
      console.log('download image result',filePath)
      const matches = [...filePath.matchAll(/\/\/(.*?)\./gmi)]
      const bucket = matches[0][1];
      console.log('! bucket was',bucket)
      const listResult = await Storage.list('*')
      console.log('listResult',listResult); 
      API.graphql({ query: mutations.doFaceCutter, variables:{sourceFile:filePath,destBucket:bucket,username:_username} }) 
      setLoadingModalContent(null);
      setUploadDone(true);
    })

   
    // const putResult = await Storage.put('test4.txt','hello swirled', {level:'public', contentType:'text/plain'})
    // console.log('upload test text result',putResult) // logs: upload test text result {key: "test4.txt"}
    // const getResult = await Storage.get('test4.txt',{download:false, level:'public'}) 
    // console.log('retrieve test.txt data result',getResult); // logs: non-functional pre-signed URL
    // const listResult = await Storage.list('test4')
    // console.log('listResult',listResult);  // logs: upload test text result {key: "test4.txt"}
  }

  return (<GoldBorderModal modalInnerClass={styles.modalInnerClass} modalOuterClass={styles.modalOuterClass} modalContent={<div ref={containerRef} className={styles.SpiritMakerUI} >
    {!showUserCam && !showFinalCanvas && <div className={styles.initialButtons}>
            
            <UserFileInput handleInput={loadFromDisk}>
              <IconButton className={styles.attachButton}>
                <AttachFileIcon />
              </IconButton>
            </UserFileInput>
            <IconButton variant="outlined" onClick={toggleCam} className={styles.camButton}>
              <PhotoCameraIcon />
            </IconButton>
          </div>
    }
    {(!!showUserCam) && <UserCam hideCam={()=>{setShowUserCam(false)}} containerRef={containerRef} onCapture={doCamCapture} />}
    
    <div className={(showFinalCanvas)? (!!showUserCam? styles.finalCanvasWrapperOpenCamOpen : styles.finalCanvasWrapperOpenCamClosed):styles.finalCanvasWrapper}>
      {(!uploadDone && !showUploadConfirmModal) && <div className={styles.finalCanvasButtons}>
        <IconButton onClick={()=>{!showUploadConfirmModal && hideFinalCanvas()}}>
          <CancelScheduleSendIcon />
        </IconButton>
        <IconButton onClick={_showUploadConfirmModal}>
          <SendIcon />
        </IconButton>
      </div>}
      <canvas style={{width:'auto',height:`${finalCanvasHeight}px`}}className={styles.finalCanvas} width="1024" height="1024" ref={finalCanvasRef} />
    </div>
    
    {(!!showUploadConfirmModal && !uploadDone) && <div className={styles.uploadConfirmModal}>
      <p>Send to the ether? There's no going back! This will use up this key!</p>
        <div className={styles.sendButtons}>
          <DecoButton text="Nah" fontSize="21px" onClick={_hideUploadConfirmModal} onEnterOrSpace={_hideUploadConfirmModal} /><DecoButton text="Send It!" fontSize="21px" onClick={processUpload} onEnterOrSpace={processUpload} />
        </div>
      </div>}

    {!!uploadDone && <div className={styles.uploadConfirmModal}>
        <h1>Your visage has been transubstantiated to the ether. Check your email in a few minutes for a message from the spirits.</h1>
      </div>}

  </div>} />)
}


export default SpiritMakerUI