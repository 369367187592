import React from "react"
import styles from "./styles.module.scss"
import clsx from 'clsx'

const GoldBorderModal=(props)=>{
  return (
  <div className={clsx(styles.GoldBorderModal,props.modalOuterClass)}>
    <div className={clsx(styles.modalInner,props.modalInnerClass)}>
      <>{props.modalContent}</>
    </div>
  </div>)
}


export default GoldBorderModal;