import styles from './styles.module.scss'
import Storage from '@aws-amplify/storage'
const S3PermsTest = (props)=>{
  const testModerationWriteAccess = async ()=>{
    const putResult = await Storage.put('moderation/test5.txt','hello swirled', {level:'public', contentType:'text/plain'})
  }

  const testModerationReadAccess = async ()=>{
    const listResult = await Storage.list('moderation/')
    const getResult = await Storage.get('moderation/testX.txt',{download:false, level:'public'}) 
  }

  const testProductionWriteAccess = async ()=>{
    const putResult = await Storage.put('production/test4.txt','hello swirled', {level:'public', contentType:'text/plain'})
  }

  const testProductionReadAccess = async ()=>{
    const listResult = await Storage.list('production/')
    const getResult = await Storage.get('production/test4.txt',{download:false, level:'public'}) 
  }

  return (<div className={styles.S3PermsTest}>
    <button onClick={testModerationWriteAccess}>test moderation write access</button>
    <button onClick={testModerationReadAccess}>test moderation read access</button>
    <button onClick={testProductionWriteAccess}>test production write access</button>
    <button onClick={testProductionReadAccess}>test production read access</button>
  </div>)
}

export default S3PermsTest