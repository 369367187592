import React, { useState } from "react";
import styles from "./styles.module.scss";

const SwatchButton = (props)=>{
  const [isDown,setIsDown] = useState(false);
  return (<div className={isDown? styles.SwatchButtonDown : styles.SwatchButton} onClick={props.onClick} onMouseDown={()=>{setIsDown(true)}} onMouseUp={()=>{setIsDown(false)}} onMouseOut={()=>{setIsDown(false)}}>
    <div className={styles.swatch} style={{background:props.swatchColor}}/>
  </div>)
}

export default SwatchButton;