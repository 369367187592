import React, { useEffect,useState } from 'react';
import Amplify, { PubSub,Auth } from 'aws-amplify';
import { AWSIoTProvider } from '@aws-amplify/pubsub';
import Button from "@material-ui/core/Button";
import { API } from 'aws-amplify';
const topicID = 'hauntedbar'

// Amplify.addPluggable(new AWSIoTProvider({
//   aws_pubsub_region: 'us-west-2',
//   aws_pubsub_endpoint: 'wss://a16r2a5cc5iq9j-ats.iot.us-west-2.amazonaws.com/mqtt'
// }))

const PubSubTest = (props)=>{
  const [identityId,setIdentityId] = useState();
  
  useEffect(()=>{
    Auth.currentCredentials().then((info) => {
      setIdentityId(info.identityId);
      // const cognitoIdentityId = info.identityId;
      // console.log('!! cognitoIdentityId:', cognitoIdentityId);
    });

    PubSub.subscribe(topicID).subscribe({
      next: (data)=>{console.log('PubSub message received:',data)},
      error: (err)=>{console.error('Pubsub subscribe error:',err)}
    })

  },[])
  const sendMsg = (msg)=>{
    PubSub.publish(topicID, {msg:msg})
  }

  return (<>
    <Button variant="contained" onClick={()=>{sendMsg(Date.now())}} >Fire pubsub test</Button>
  </>)
}

export default PubSubTest;
