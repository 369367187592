import React, { useEffect,useState,useContext,useRef } from 'react';
import { PubSub } from 'aws-amplify';
import { API } from 'aws-amplify';
import * as queries from '../../../../graphql/queries';
import * as mutations from '../../../../graphql/mutations';
import {useParams} from 'react-router-dom'
import {MainContext} from '../../../../Components/DataManager'


import DefaultControlUI_Landing from '../../../DefaultControlUIs/DefaultControlUI_Landing';
import DefaultControlUI_Launcher from '../../../DefaultControlUIs/DefaultControlUI_Launcher';
import DefaultControlUI_ThisIsBurnt from '../../../DefaultControlUIs/DefaultControlUI_ThisIsBurnt';
import DefaultControlUI_ThankYou from '../../../DefaultControlUIs/DefaultControlUI_ThankYou';
import DefaultControlUI_ThisIsInvalid from '../../../DefaultControlUIs/DefaultControlUI_ThisIsInvalid';
// import DefaultUseOnceControl from '../../../DefaultControls/DefaultUseOnceControl'; //DEPRECATED?
import DefaultControlUI_Busy from '../../../DefaultControlUIs/DefaultControlUI_Busy';
import DefaultControlUI_Idle from '../../../DefaultControlUIs/DefaultControlUI_Idle';
import DefaultControlUI_Lockout from '../../../DefaultControlUIs/DefaultControlUI_Lockout';
// import DefaultNoControl from '../../../DefaultControls/DefaultNoControl'; //DEPRECATED?
import DefaultControlUI_IndirectControl from '../../../DefaultControlUIs/DefaultControlUI_IndirectControl';

const ClientIndirectControl = (props)=>{
  const {experienceName,wallpaperImg} = props;
  const [view,setView] = useState();
  // const [guid,setGuid] = useState(null);
  const [modalContent, setModalContent] = useState(false);
  // const {user} = useContext(MainContext);
  const {guid} = useParams();
  const lockoutUntil = useRef(0);
  const triggerFired = useRef(false);
  const {setWallpaperImg} = useContext(MainContext)
  useEffect(()=>{
    setView('landing')
  },[])
  useEffect(()=>{
    if (!!wallpaperImg) {
      setWallpaperImg(wallpaperImg)
    }
  },[wallpaperImg])

  const LandingComponent = props.landingComponent || DefaultControlUI_Landing;
  const LauncherComponent = props.launcherComponent || DefaultControlUI_Launcher;
  const IsBurntComponent = props.isBurntComponent || DefaultControlUI_ThisIsBurnt;
  const ThankYouComponent = props.thankYouComponent || DefaultControlUI_ThankYou;
  const IsInvalidComponent = props.isInvalidComponent || DefaultControlUI_ThisIsInvalid;
  const ControlComponent = props.controlComponent || DefaultControlUI_IndirectControl;
  const BusyComponent = props.BusyComponent || DefaultControlUI_Busy;
  const IdleComponent = props.IdleComponent || DefaultControlUI_Idle;
  const LockoutComponent = props.LockoutComponent || DefaultControlUI_Lockout;

  const parseComms = (data)=>{
    const _data = data
    const msg = JSON.parse(_data.value);
    const cmd = msg.cmd;
    switch(cmd) {
      case 'startControlRequestAccepted':
        const _channel = `${process.env.REACT_APP_BUILD_ENV}/guid/${guid}/trigger`
        PubSub.publish(_channel,JSON.stringify({cmd:'ack',guid:guid}));
        break;
      case 'launchControls':
        setView('control');
        break;
      case 'endExperience':
        setTimeout(()=>{setView('thankyou')},25);
        break;
      case 'idle':
        setView('idle')
        break;  
      case 'busy':
        setView('busy')
        break;  
      case 'lockout':
        lockoutUntil.current = msg.lockoutUntil;
        setView('lockout');
        break;
      default:
        break;
    }
  }

  
  const sendTriggerOrDataBundle = (dataBundle)=>{
    if (!triggerFired.current) {
      // fire trigger with data bundle
      PubSub.publish(`${process.env.REACT_APP_BUILD_ENV}/guid/${guid}/trigger`,JSON.stringify({cmd:'trigger',guid:guid,dataBundle:dataBundle})); //trigger must handle the first dataBundle so it can apply configurable triggerGap before sending
      triggerFired.current = true;
    }
    else {
      // fire data bundle
      PubSub.publish(`${process.env.REACT_APP_BUILD_ENV}/guid/${guid}/actual`,JSON.stringify({cmd:'dataBundle',guid:guid,dataBundle:dataBundle})); // all other databundles go direct to actual for best performance
    }
  }
  

  return (<>
    {view === 'landing' && <LandingComponent experienceName={experienceName} setParentView={setView} guid={guid} PubSub={PubSub} parseCommsFromActual={parseComms} queries={queries} mutations={mutations} API={API} />}
    {view === 'launch' && <LauncherComponent setParentView={setView} guid={guid} mutations={mutations} API={API} experienceName={experienceName} />}
    {view === 'burnt' && <IsBurntComponent setParentView={setView} guid={guid} />}
    {view === 'invalid' && <IsInvalidComponent setParentView={setView} guid={guid} />}
    {view === 'control' && <ControlComponent setParentView={setView} guid={guid} setModalContent={setModalContent} PubSub={PubSub} sendTriggerOrDataBundle={sendTriggerOrDataBundle} experienceName={experienceName} />}
    {view === 'thankyou' && <ThankYouComponent setParentView={setView} guid={guid}/>}
    {view === 'busy' && <BusyComponent setParentView={setView} guid={guid}/>}
    {view === 'idle' && <IdleComponent setParentView={setView} guid={guid}/>}
    {view === 'lockout' && <LockoutComponent setParentView={setView} guid={guid} lockoutUntil={lockoutUntil}/>}
  </>)
}


export default ClientIndirectControl;