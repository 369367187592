import React from "react";
import GoldBorderModal from "../../../Components/Modals/GoldBorderModal";

const DefaultControlUI_Launcher = (props)=>{
  const {guid,API,mutations,experienceName} = props;
  const launch = async ()=>{
    await API.graphql({ query: mutations.launchBurnableExperience, variables:{id:guid,env:process.env.REACT_APP_BUILD_ENV,experienceName:experienceName} }) 
  }
  return (<GoldBorderModal {...props} modalContent={<><h1>Launcher</h1> <button onClick={launch}>Click to launch experience</button></>}/>)
}

export default DefaultControlUI_Launcher