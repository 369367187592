import React, { useEffect, useRef,useContext} from "react";
import styles from './styles.module.scss';
import { useWindowSize } from "../../Hooks/generalHooks";
import Stats from 'stats-js'
import {throttle} from 'lodash'
import gsap from 'gsap';
import letters from './letters';
import { PubSub,Storage } from 'aws-amplify';
import { useLocation } from "react-router-dom";
import {Scene,WebGLRenderer,OrthographicCamera,PlaneBufferGeometry,VideoTexture,TextureLoader,CanvasTexture,MeshBasicMaterial,Mesh,LinearFilter} from 'three'
import { cryptoDeChipChop } from "../../Utils/utils-main";
import {Auth} from 'aws-amplify';
import { MainContext } from "../DataManager";

//config
const margins = {
  t: 10,
  l: 10,
  b: 10,
  r: 10
} //margins for text area
const lineHeight = 1.5; //line height
const cursorDims = {x:166,y:154} // cursor dimensions
const strokeDiam = 13; // diameter of stroke
const strokeDrawTimeSecs = 0.55; // how long it takes to draw each stroke of each letter
const dripDiam = Math.max(strokeDiam/10,1);
const letterDripChance = 0.083;
const dripDrawTime = 0.8; //0.8
const betweenStrokeDelay = 100;
const betweenStrokeDelayRandomPct = 0.8;
const letterStrokeSpeedSwing = 0.3;

const defaultPhrases = [
  "What a night for a nightmare",
  "what goes around comes around",
  "tomorrow and tomorrow and tomorrow",
  "We all float down here",
  "I know what you did",
  "Hello we have been trying to reach you about your cars extended warranty",
  "The call is coming from inside the bar"
]





const DrippingCanvas = (props)=>{
  const vidRef = useRef();
  const maskCanvas = useRef();
  const maskCtx = useRef();
  const displayRef = useRef();
  const wrapper = useRef();
  const paper = useRef();
  const cursorPos = useRef({x:margins.l,y:margins.t})
  const winSize = useWindowSize();
  const phrases = useRef(defaultPhrases);

  const quoDepth = useRef(0);
  const sqoDepth = useRef(0);

  // const stats = useRef(new Stats())
  // stats.current.showPanel(0);

  const handles= useRef({});

  // comms
  const {experienceName} = props;
  const adminSub = useRef();
  const guidSub = useRef();

  // login
  let params = useLocation().search;
  params = new URLSearchParams(params);
  const {user} = useContext(MainContext);

  useEffect(()=>{
    // check if user logged in. if not, attempt autologin
    async function checkLoginStatus(){
      Auth.currentAuthenticatedUser()
      .then((user)=>{
        launch();
      })
      .catch((err)=>{
        if (err === "The user is not authenticated") {
          doAL();
        }
      })
    }
    checkLoginStatus();
  },[])
  
  useEffect(()=>{
    autoResizePaper();
  },[winSize])

  const launch = ()=>{
      subToAdmin();
      hotLoadRaphael();
      autoResizePaper();
  }

  const doAL = ()=>{
    const a = params.get('a');
    const b = params.get('b');
    const c = params.get('c'); 
    const chooser = cryptoDeChipChop('U2FsdGVkX1+QfCOGq6tFfktcnuaTKpgNRR84O+z08dBgzCWEI8G6s6F9VNPMCyZ6','9967217716344');
    const lass = cryptoDeChipChop(a,c+b);
    Auth.signIn(chooser,lass)
      .then(()=>{
        console.log('!al success! launching...')
        launch();
      })
      .catch((err)=>{
        console.log('!al error:',err)
      })
  }

  const subToAdmin = ()=>{
    adminSub.current=PubSub.subscribe(`${process.env.REACT_APP_BUILD_ENV}/admin/${experienceName}`).subscribe({
      next: (data)=>{parseAdminCommand(data)},
      error: (err)=>{console.error('Pubsub subscribe error:',err)}
    })
  }

  const parseAdminCommand = (data)=>{
    // for some reason sending stringified json through MQTT from lambda comes out a pojo on the other end so must differentiate here
    // if (typeof data )
    console.log('!dcc parseAdminCommand data',data,'typeof data.value',typeof data.value)
    const msg = (typeof data.value === "string")? JSON.parse(data.value) : data.value;
    const cmd = msg.command || msg.cmd;
    const guid = msg.guid;
    console.log('!dcc parseAdminCommand, data',data, 'cmd',cmd, 'cmd === unsubFromGuid?',cmd==='unsubFromGuid')
    switch(cmd){
      case 'subToGuid':
        if (!!guidSub.current) return;
        console.log('parseAdmin command subtoguid passed check')
        subToGuid(guid);
        break;
      case 'unsubFromGuid':
        console.log('unsubFromGuid match')
        unSubFromGuid();
        break;
      case 'newModeratedContent':
      console.log('!m2 cmd received: newModeratedContent cmd was',cmd,'msg was',msg)
      parseNewModeratedContent(msg.key);  
      break;
      default:
        break;
    }
  }

  const subToGuid = (guid)=>{
    // unSubFromGuid();
    if (!!guidSub.current) return;
    const _channel = `${process.env.REACT_APP_BUILD_ENV}/guid/${guid}/actual`;
    console.log('!dcc ActualIndirectControl subToGuid, guid',guid,'_channel',_channel)
    guidSub.current = PubSub.subscribe(_channel).subscribe({
      next: (data)=>{parseControlCommand(data)},
      error: (err)=>{console.error('!dcc Pubsub subscribe error:',err)}
    })
  }

  const unSubFromGuid = ()=>{
    console.log('!dcc unSubFromGuid')
    try {guidSub.current.unsubscribe();guidSub.current = null;}
    catch(err){}
  }

  const parseNewModeratedContent=async (key)=>{
    console.log('!m2 parseNewModeratedContent, key was',key);
    Storage.get(key,{download:true})
    .then(async (content)=>{
      const text = await content.Body.text();
      console.log('!m2 parseNewModeratedContent content was',content, 'content.Body.text()',text)
      handleIncomingPhrase(text,false,true,false)
    });
  }

  const parseControlCommand = (data)=>{
    console.log('!dcc parseControlCommand, data',data)
    const val = (typeof data.value === "string")? JSON.parse(data.value) : data.value;
    console.log('!dcc val:',val)
    const msg = val?.msg;
    const cmd = val?.command || val?.cmd || msg?.cmd;
    const guid = val?.guid || msg?.guid;
    const dataBundle = val?.dataBundle || msg?.dataBundle;
    console.log('cmd',cmd)
    switch(cmd) {
      case 'dataBundle':
        console.log('!dcc dataBundle received:',dataBundle);
        // we get a phrase, plus interrupt, loop, and random
        handleIncomingPhrase(dataBundle.phrase,dataBundle.loop,dataBundle.interrupt,dataBundle.random)
        break;
      default:
        break;
    }
  }

  const hotLoadRaphael = ()=>{
    const scr = document.createElement('script');
    scr.onload=init
    scr.setAttribute('src','https://cdnjs.cloudflare.com/ajax/libs/raphael/2.3.0/raphael.min.js')
    document.body.append(scr);
  }

  const init = ()=>{
    console.log('!dr init, wrapper.current:',wrapper.current, ' wrapper.current.clientWidth', wrapper.current.clientWidth)
    // initialize maskCanvas
    maskCtx.current = maskCanvas.current.getContext('2d');
    maskCtx.current.lineWidth = 38;
    maskCtx.current.strokeStyle = "white"

    // initialize SVG/Raphael
    paper.current = window.Raphael(wrapper.current, wrapper.current.clientWidth, wrapper.current.clientHeight);

    // initialize anims
    handles.current.anims = []
    handles.current.cycleDelay = props.cycleDelay || 1800;

    // initialize phrase queue
    handles.current.queuedPhrases = []

    //initialize Three
    initThree();

    // set up scale
    autoResizePaper();

    // run default anim
    // runDefaultAnim(2000);
    // setTimeout(()=>{runDefaultAnim(2000)},300)
  }

  const autoResizePaper = throttle(()=>{
    if (!paper.current) return;
    // stats.begin();
    const wrapperDims = wrapper.current.getBoundingClientRect();
    paper.current.setSize(wrapperDims.width, wrapperDims.height)
    maskCtx.current.canvas.width = window.innerWidth;
    maskCtx.current.canvas.height = window.innerHeight;
    // stats.end();
  },500) 

  const drawPath = (width,pathString,duration,dripChance,swingPct)=>{
    // stats.begin();
    const _promise = new Promise((res,rej)=>{
      // stats.begin();
      const line = paper.current.path(pathString).attr({
        stroke: 'transparent'
      });
      const length = line.getTotalLength();
      let _duration;
      if (!!swingPct) {
        _duration = (duration + ( ( -duration * ( swingPct ) )) + ( Math.random() * ( duration * swingPct * 2 ) ) ); // randomize stroke speed by swing pct, weight towards slower
      }
      else {
        _duration = duration;
      }
      // paper.clear();
      const anim = gsap.to({p:0},{
        p:100,
        duration: _duration,
        onUpdate:function(){
          // stats.begin();
          var offset = length * this.progress();
          var subpath = line.getSubpath(0, offset);     
          //clear canvas or no?
          //create path on canvas
          const p = new Path2D(subpath);
          maskCtx.current.lineWidth = width;
          maskCtx.current.strokeStyle = "white";
          maskCtx.current.lineCap = "round";
          maskCtx.current.stroke(p)
          // maskCtx.clip(p)
          if (!!dripChance) {
            // const dripChance = Math.random();
            if (Math.random() <= dripChance) {
              const subpathFrags = subpath.split(","); 
              if (subpathFrags) {
                let dripPos = {
                  x: Number(subpathFrags[subpathFrags.length-2]),
                  y: Number(subpathFrags[subpathFrags.length-1])
                }
                drawDrip(dripPos);
              }
            }  
          }
          // stats.end();
        },
        onComplete:function(){
          res();
        }
      });
      handles.current.anims.push(anim);
      // stats.end();
    })
    return _promise;
  }

  const drawStroke = (...args)=>{
    return drawPath(...args)
  }

  const drawLetter = (letter)=>{
    // const paths = letters[letter]
    let paths;
    if (letter === '"') {
      if (!quoDepth.current) {
        quoDepth.current++;
        paths = letters['lqo']
      }
      else {
        quoDepth.current--;
        paths = letters['rqo']
      }
    }
    else if (letter === "'") {
      if (!sqoDepth.current) {
        sqoDepth.current++;
        paths = letters['lsqo']
      }
      else {
        sqoDepth.current--;
        paths = letters['rsqo']
      }
    }
    else {
      paths = letters[letter]
    }
    const letterExtentsPx = cursorPos.current.x + (cursorDims.x);
    if (letterExtentsPx > paper.current.width) {
      newLine()
    }
    const _promise = new Promise((res,rej)=>{
      let promiseChain = Promise.resolve();
      for (let i = 0; i < paths.length; i++) {
        promiseChain = promiseChain.then(()=>{
          return randomizedWait(betweenStrokeDelay,betweenStrokeDelayRandomPct).then(()=>{
            return drawStroke(strokeDiam,transformStrokeToCursor(paths[i]),strokeDrawTimeSecs,letterDripChance,letterStrokeSpeedSwing)
          })
        });
      }
      promiseChain.then(()=>{
        res();
      })
    })
    return _promise;
  }

  const drawWord = (word)=>{
    //break down by letter
    const WORD = word.toUpperCase();
    const WORDLetters = WORD.split("");
    const wordExtentsPx = cursorPos.current.x + (word.length * cursorDims.x) + (cursorDims.x*1.5); // word and space
    if (wordExtentsPx > paper.current.width) {
      //will it fit on new line? if too long for new line, drawLetter will handle it, else newline now
      if ( (     (word.length * cursorDims.x) + (margins.l/2) > paper.current.width   ) ) {
        newLine()
      }
    }
    const _promise = new Promise((res,rej)=>{
      let promiseChain = Promise.resolve();
      for (let i = 0; i < WORDLetters.length; i++) {
        promiseChain = promiseChain.then(()=>{
          //write each letter, incrementing cursor after
          if (i !== 0) {
            cursorPos.current.x += (cursorDims.x * (0.7 + (Math.random() * 0.2)));  
          }
          return drawLetter(WORDLetters[i])
        })
      }
      promiseChain.then(()=>{
        res();
      })
    })
    return _promise;
  }

  const drawSentence = (sentence)=>{
    //reset quote depths
    quoDepth.current = 0;
    sqoDepth.current = 0;
    //break down by space
    const words = sentence.split(" ");
    const _promise= new Promise((res,rej)=>{
      let promiseChain = Promise.resolve();
      //write each word  
      for (let i = 0; i < words.length; i++) {
        promiseChain = promiseChain.then(()=>{
          if (i !== 0) {
            cursorPos.current.x += cursorDims.x * 1.5; //add space  
          }
          return drawWord(words[i])
        })
      }
      promiseChain.then(()=>{
        res();
      })
    })
    return _promise;
  }

  const drawDrip = (startPos)=>{
    const dripLength = 50 + (Math.random() * 200)
    let path = `M${startPos.x},${startPos.y}L${startPos.x},${startPos.y+dripLength}`
    drawPath(dripDiam,path,dripDrawTime)
  }

  const newLine = ()=>{
    console.log('newline')
    cursorPos.current.y = cursorPos.current.y + (cursorDims.y * lineHeight);
    cursorPos.current.x = margins.l;
  }

  const wait = (delay)=>{
    return new Promise((resolve)=>{
          setTimeout(resolve, delay);
      });
  }

  const handleIncomingPhrase = (phrase,loop,interrupt,random)=>{
    console.log('!dcc handleIncomingPhrase phrase',phrase,'interrupt',interrupt,'loop',loop,'random',random)
    // handles.current.queuedPhrases.push(phrase);
    // clearAndDrawNextPhrase(!!loop);
    if (interrupt) {
      console.log('!dcc handleIncomingPhrase with interrupt. handles.current.queuedPhrases pre unshift:',handles.current.queuedPhrases)
      handles.current.queuedPhrases.unshift(phrase)
      console.log('!dcc handleIncomingPhrase with interrupt. handles.current.queuedPhrases post unshift:',handles.current.queuedPhrases)
      clearAndDrawNextPhrase(loop,random)
    }
    else {
      console.log('!dcc handleIncomingPhrase loop was false. handles.current.queuedPhrases pre push',handles.current.queuedPhrases)
      handles.current.queuedPhrases.push(phrase);
      console.log('!dcc handleIncomingPhrase loop was false. handles.current.queuedPhrases post push',handles.current.queuedPhrases,'handles.current.drawing is',handles.current.drawing)
      if (!handles.current.drawing) {
        if (random) {
          console.log('!dcc drawing random')
          drawNextQueuedPhraseOrRandom(loop)
        }
        else {
          console.log('!dcc drawing without random')
          drawNextQueuedPhrase(loop)
        }
        
      }
    }
  }


  const drawNextQueuedPhrase=(loop)=>{
    handles.current.drawing = true;
    return new Promise((resolve)=>{
      if (handles.current.queuedPhrases.length < 1) {
        handles.current.drawing = false;
        resolve();
      }
      drawSentence(handles.current.queuedPhrases[0]).then(()=>{
        handles.current.queuedPhrases.shift();
        wait(handles.current.cycleDelay).then(()=>{
          clearAnims();
          resetMask();
          if (!loop) {
            handles.current.drawing = false;
            //terminate experience at control board
            // PubSub.publish(`${process.env.REACT_APP_BUILD_ENV}/admin/${experienceName}`,{commamd:'moderatedExperienceEnd'})
          }
          else {
            drawNextQueuedPhrase(true)
          }
        })        
      })
    })
  }

  const drawNextQueuedPhraseOrRandom=(loop)=>{
    handles.current.drawing = true;
    return new Promise((resolve)=>{
      if (handles.current.queuedPhrases.length < 1) {
        handles.current.queuedPhrases.push(pickRandomDefaultPhrase());
      }
      drawSentence(handles.current.queuedPhrases[0]).then(()=>{
        handles.current.queuedPhrases.shift();
        wait(handles.current.cycleDelay).then(()=>{
          clearAnims();
          resetMask();
          if (!loop) {
            handles.current.drawing = false;
          }
          if (!!loop) {
            drawNextQueuedPhraseOrRandom(true)
          }
        })        
      })
    })
  }

  const initThree = ()=>{

    handles.current.scene = new Scene();
  
    handles.current.renderer = new WebGLRenderer({antialias:true});
    handles.current.renderer.setPixelRatio( window.devicePixelRatio );
    handles.current.renderer.setSize( window.innerWidth, window.innerHeight );
    displayRef.current.appendChild( handles.current.renderer.domElement );
    
    handles.current.cam = new OrthographicCamera( - 1, 1, 1, - 1, 0, 2 );
    handles.current.cam.position.z = 0;
  
    handles.current.geo = new PlaneBufferGeometry( 2, 2 );
    handles.current.srcVid = vidRef.current
    handles.current.tex = new VideoTexture( handles.current.srcVid );
    handles.current.alphaTex = new CanvasTexture(maskCanvas.current)
    handles.current.alphaTex.minFilter = LinearFilter;
    handles.current.alphaTex.magFilter = LinearFilter;
    handles.current.mat = new MeshBasicMaterial({color:0xffaaaa, map:handles.current.tex, transparent: true, alphaMap:handles.current.alphaTex})
    handles.current.mesh = new Mesh(handles.current.geo,handles.current.mat)
    handles.current.scene.add(handles.current.mesh);
  
    // composer = new THREE.EffectComposer(renderer)
    // renderModel = new THREE.RenderPass(scene,cam);
    // composer.addPass(renderModel)
  
    animateRenderDisplay();
  }
  
  const randomizedWait = (delay,pct)=>{
    const min = delay - (delay*pct);
    const randomizedDelay = min + (Math.random() * (delay+(delay*pct)+(delay*pct)));
    return wait(randomizedDelay);
  }

  const transformStrokeToCursor = (path)=>{
    //offsets stroke paths to fit cursor position
    const yWiggle = (-cursorDims.y * 0.15) + (Math.random() * (cursorDims.y * 0.15)); // wiggle y draw position of letters so they don't look so mechanical
    const xWiggle = (-cursorDims.x * 0.05) + (Math.random() * (cursorDims.x * 0.05)); 
    const newPath = window.Raphael.transformPath(path,`T${cursorPos.current.x+xWiggle},${cursorPos.current.y+yWiggle}`).toString()
    return newPath;
  }

  const resetMask = ()=>{
    maskCtx.current.clearRect(0, 0, maskCanvas.current.width, maskCanvas.current.height);
    cursorPos.current = {x:margins.l,y:margins.t} 
  }

  const clearAnims = ()=>{
    handles.current.anims.map((anim)=>{
      anim.kill();
      return null;
    })
    handles.current.anims=[]
  }

  const clearAndDrawNextPhrase = (loop,random)=>{
    clearAnims();
    resetMask();
    if (!!random) {
      drawNextQueuedPhraseOrRandom(loop);
    }
    else {
      drawNextQueuedPhrase(loop);
    }
  }

  const pickRandomDefaultPhrase = ()=>{
    console.log('!dr pickRandomDefaultPhrase phrases.current=',phrases.current)
    return phrases.current[Math.floor(Math.random()*(phrases.current.length-1))]
  }

  const runDefaultAnim = (cycleDelay)=>{
    console.log('runDefaultAnim')
    const sent = pickRandomDefaultPhrase();
    console.log('!dr sent:',sent)
    drawSentence(sent).then(()=>{
      setTimeout(()=>{
        resetMask();
        // runDefaultAnim(cycleDelay)},cycleDelay)
        setTimeout(()=>{runDefaultAnim(cycleDelay)},cycleDelay)},1000)
    },cycleDelay)
  }

  const renderDisplay = ()=>{
    handles.current.renderer.clear()
    // composer.render();
    handles.current.renderer.render(handles.current.scene,handles.current.cam) 
  }
  
  const animateRenderDisplay = ()=>{
    handles.current.alphaTex.needsUpdate = true;
    renderDisplay();
    window.requestAnimationFrame(animateRenderDisplay)
  }

  return (<>
    {/* {stats.current.dom} */}
    <video ref={vidRef} className={styles.srcVid} autoPlay muted loop crossOrigin="anonymous" webkit-playsinline height="0" width="0">
      <source src="https://hauntedbar-public.s3.us-west-2.amazonaws.com/demoBlood2_720.mp4" type='video/mp4;' />
    </video>
    <div className={styles.displayCanvas} ref={displayRef}></div>
    <div className={styles.svgWrapper} ref={wrapper} />
    <canvas className={styles.maskCanvas} ref={maskCanvas} />
  </>)
}

export default DrippingCanvas;