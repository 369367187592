import React,{useEffect,useRef} from "react";
import { useConfigurables } from "../../../Utils/triggerUtils";
import { PubSub, API } from 'aws-amplify';
import * as mutations from '../../../graphql/mutations';

const TriggerIndirectControl = (props)=>{
  const {configurables,triggerOn,triggerOff,experienceName,setConnected,setLockoutUntil:setParentLockoutUntil,lockoutReset,setParentStatus,kickAndBurnUser,kickUser} = props;
  const parsedConfigurables = useConfigurables(configurables);
  // const {idleTimeout,lockoutTime} = parsedConfigurables || {};
  const adminSub = useRef(null);
  const guidSub = useRef(null);
  const idleTimer = useRef(null);
  const currentGuid = useRef(null)
  const lockoutUntil = useRef(0);
  // const lockoutTimeRef = useRef(null); 
  const configurablesRef = useRef(parsedConfigurables);// necessary to dodge a whole chain of stale state 
  const dataBundleRef = useRef(null);
  // const guidRef = useRef(null);
  const allowedUseTimer = useRef(null);

  useEffect(()=>{
    return (()=>{
      // dismount cleanup
      try {
        guidSub?.current?.unsubscribe();
        adminSub?.current?.unsubscribe();
      }
      catch(err){}
    })
  },[])

  useEffect(()=>{
    if (!!adminSub.current || !parsedConfigurables || !triggerOn || !triggerOff) return;
    adminSub.current=PubSub.subscribe(`${process.env.REACT_APP_BUILD_ENV}/admin/${experienceName}`).subscribe({
      next: (data)=>{parseAdminCommand(data)},
      error: (err)=>{console.error('Pubsub subscribe error:',err)}
    })
  },[experienceName, triggerOn, triggerOff])

  useEffect(()=>{
    configurablesRef.current = parsedConfigurables;
  },[parsedConfigurables])

  useEffect(()=>{ // trigger for parent to end lockout
    if(!!lockoutReset) {endLockout();}
  },[lockoutReset])


  useEffect(()=>{ // trigger for parent to kick user and burn token
    if(!!kickAndBurnUser) {decrementAndEnd(currentGuid.current,true)}
  },[kickAndBurnUser])

  useEffect(()=>{ // trigger for parent to kick user and not burn token
    if(!!kickUser) {endExperienceForUser(currentGuid.current)}
  },[kickUser])

  const startLockout=()=>{
    lockoutUntil.current=Date.now()+configurablesRef.current.lockoutTime;
    setTimeout(endLockout,configurablesRef.current.lockoutTime);
    setParentLockoutUntil(lockoutUntil.current)
  }

  const endLockout=()=>{
    setParentLockoutUntil(0);
    lockoutUntil.current = 0;
  }

  const trigger = ()=>{
    triggerOn();
    clearIdleTimeout();
  };

  const handleAllowedUseTimeout = (guid,burn)=>{
    clearAllowedUseTimer();
    decrementAndEnd(guid,true);
    setParentStatus('idle');
  }

  const parseControlCommand = async (data)=>{
    const {cmd:command,guid,dataBundle} = JSON.parse(data.value);
    switch (command) {
      case 'ack':
        finalizeControl(guid);
        setConnected(true);
        break;
      // case 'setColor':
      //   trigger(guid);
      //   clearIdleTimeout();
      //   break;
      case 'trigger':
        trigger();
        dataBundleRef.current = dataBundle;
        // send dataBundle to actual after triggerGap (if defined)
        if(!!dataBundle) {setTimeout(()=>{sendDataBundleToActual(guid)},configurables?.triggerGap || 0);}
        if (!configurablesRef.current.allowedUseTime) { // allowed use time is 0 or undefined, this is a fire once with data experience so term user connection now.
          setTimeout(()=>{decrementAndEnd(guid,true)},10); //slight delay and trigger off
        }
        else if (!!configurablesRef.current.allowedUseTime && !allowedUseTimer.current) { // allowed use time is > 0, this is a timed use/multiple data bundle experience, so start a timer to end the experience after allowedUseTime
          // setTimeout(()=>{decrementAndEnd(guid);},configurables.allowedUseTime)
          allowedUseTimer.current = setTimeout(()=>{handleAllowedUseTimeout(guid)},configurablesRef.current.allowedUseTime)
        }
        resetIdleTimeout(guid);
        break;
      case 'dataBundle':
        dataBundleRef.current = dataBundle
        sendDataBundleToActual(guid);
        resetIdleTimeout(guid);
        break;
      case 'contentSentToModeration':
        decrementAndEnd(guid,false);
        endExperienceForUser();
        break;
      default:
        break;
    }
  }

  const initControl = (guid) => {
    //return lockout if the system is in lockout
    if (lockoutUntil.current > Date.now()) {
      // send lockout msg to client
      sendMsg(`guid/${guid}/client`, {cmd:'lockout',lockoutUntil:lockoutUntil.current});
      return;
    }
    //return busy if another user is connected
    else if (!!currentGuid.current && currentGuid.current !== guid) {
      sendMsg(`guid/${guid}/client`, {cmd:'busy'});
      //TODO: add functionality to alert ECR when a control hit made while busy - "fading red dots"
      return;
    }
    else if (guidSub.current !== null) return
    const _channel = `${process.env.REACT_APP_BUILD_ENV}/guid/${guid}/trigger`;
    guidSub.current = PubSub.subscribe(_channel).subscribe({
      next: (data)=>{parseControlCommand(data)},
      error: (err)=>{console.error('!uot Pubsub subscribe error:',err)}
    })
    sendMsg(`guid/${guid}/client`, {cmd:'startControlRequestAccepted'});
    currentGuid.current = guid;
    startIdleTimeout(guid);
  }

  const startIdleTimeout=(guid)=>{
    idleTimer.current = setTimeout(()=>{resolveIdleTimeout(guid)},configurablesRef.current.idleTimeout)
  }

  const resolveIdleTimeout=(guid)=>{
      sendMsg(`guid/${guid}/client`, {cmd:'idle'});
      currentGuid.current = null;
      idleTimer.current = null;
      guidSub.current.unsubscribe();
      guidSub.current = null;
      setConnected(false);
  }
  
  const clearIdleTimeout=()=>{
    if (!idleTimer.current) return;
    clearTimeout(idleTimer.current);
    idleTimer.current = null;
  }

  const clearAllowedUseTimer=()=>{
    clearTimeout(allowedUseTimer.current);
    allowedUseTimer.current = null;
  }

  const resetIdleTimeout=()=>{
    clearIdleTimeout();
    startIdleTimeout();
  }

  const clearGuid = ()=>{
    guidSub?.current?.unsubscribe();
    guidSub.current = null;
    currentGuid.current = null;
  }

  const sendDataBundleToActual = (guid)=>{
    sendMsg(`guid/${guid}/actual`, {cmd:'dataBundle',dataBundle:dataBundleRef.current});
  }

  const parseAdminCommand = (data)=>{
    const {command} = data.value;
    switch (command) {
      case 'startControlRequest':
        initControl(data.value.guid);
        break;
      case 'triggerFromModeration':
        parseControlCommand({value:JSON.stringify({cmd:'trigger'})})
        break;
      default:
        break;
    }
  }

  const finalizeControl = (guid)=>{
    sendMsg(`guid/${guid}/client`, {cmd:'launchControls'});
    sendMsg(`admin/${experienceName}`, {cmd:'subToGuid',guid:guid});
    currentGuid.current = guid;
  }

  const sendMsg = (channel, msg)=>{
    const _channel = `${process.env.REACT_APP_BUILD_ENV}/${channel}`
    PubSub.publish(_channel, JSON.stringify(msg))
  }

  const endExperienceForUser = (guid)=>{
    clearIdleTimeout();
    clearAllowedUseTimer();
    clearGuid();
    sendMsg(`guid/${guid}/client`, {cmd:'endExperience'});
    sendMsg(`admin/${experienceName}`, {cmd:'unsubFromGuid'});
    setParentStatus('idle')
  } 

  const decrementAndEnd = (guid,doLockout)=>{
    triggerOff();
    API.graphql({ query: mutations.decrementBurnable, variables:{id:guid} }) // pass identityID to GKPub initUser to get IOT access  
      .then((result)=>{/*console.log('decrement result:',result)*/})
      .catch((err)=>{/*console.log('decrement error:',err)*/})
    endExperienceForUser(guid);
    clearGuid();
    if (doLockout) {
      startLockout();
    }
  }
  return null
  // return <span style={{color:"#fff"}}>TriggerIndirectControl test render. midiNote was {parsedConfigurables.midiNote}</span>
}


export default TriggerIndirectControl;