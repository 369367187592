import { useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss'
import {PDFDocument,rgb} from 'pdf-lib'
import JSZip from 'jszip'
import GradientButton from '../GradientButton';
import {saveAs} from 'file-saver';
import { promiseSleep } from '../../Utils/utils-main';

const QRStamper = (props)=>{
  const [p1Pdf,setP1Pdf] = useState();
  const [p2BasePdf,setP2BasePdf] = useState();
  const [p2Pdf,setP2Pdf] = useState();
  const [p1PdfUri,setP1PdfUri] = useState();
  const [p2PdfUri,setP2PdfUri] = useState();

  const [rows,setRows] = useState(2);
  const [cols,setCols] = useState(5);
  const [xStart,setXStart] = useState(0);
  const [xEvery,setXEvery] = useState(0);
  const [yStart,setYStart] = useState(0);
  const [yEvery,setYEvery] = useState(0);
  const [size,setSize] = useState(100);

  const experienceName = useRef();
  const [codesZip,setCodesZip] = useState();

  // const [lastUpdated,setLastUpdated] = useState(0)

  useEffect(()=>{
    if (!p1Pdf) return;
    const parse = async ()=>{
      const parsed = await parsePdfToUri(p1Pdf);
      setP1PdfUri(parsed)
    }
    parse();
  },[p1Pdf])

  
  useEffect(()=>{
    if (!p2Pdf) return;
    const parse = async ()=>{
      const parsed = await parsePdfToUri(p2Pdf);
      setP2PdfUri(parsed)
    }
    parse();
  },[p2Pdf])

  useEffect(()=>{
    if(!!p2BasePdf && !p2Pdf) {
      setP2Pdf(p2BasePdf)
    }
  },[p2BasePdf,p2Pdf])

  useEffect(()=>{
    redrawQRBoxes(rows,cols,xStart,xEvery,yStart,yEvery,p2BasePdf)
  },[
    rows,
    cols,
    xStart,
    xEvery,
    yStart,
    yEvery,
    size,
    p2BasePdf
  ])

  const redrawQRBoxes=async (rows,cols,xStart,xEvery,yStart,yEvery,p2BasePdf)=>{
    if (
      !rows ||
      !cols ||
      !xStart ||
      !xEvery ||
      !yStart ||
      !yEvery ||
      !p2PdfUri 
    ) return;
    let doc = p2BasePdf;
    doc = await doc.copy();
    const pages = doc.getPages();
    const page= pages[0];
    const origSize = page.getSize();
    
    //300 dpi
    const width = 8.5*300;
    const height = 11*300;
    page.setSize(width,height);
    page.scaleContent(width/origSize.width,height/origSize.height)
    for(let i = 0; i < rows; i++) {
      // page.moveTo(0, yStart+(i*yEvery));
      for(let j = 0; j < cols; j++) {
        // page.moveTo(xStart+(j*xEvery),yStart+(i*yEvery));
        page.drawSquare({
          x: xStart+(j*xEvery),
          y: yStart+(i*yEvery),
          size: size,
          color: rgb(1,0,0,1),
          opacity: 0.5
        })
        //test2
        // page.drawSquare({
        //   x: 10,
        //   y: 11,
        //   size: 201,
        //   color: rgb(1,0,0,1),
        //   opacity: 1
        // })  
      }
    }
    // save back to p2pdf
    // doc.save();
    // setP2Pdf(null);
    const copy = await doc.copy();
    setP2Pdf(copy);
    // setTimeout(()=>{setP2Pdf(doc);setLastUpdated(Date.now())},100);
  }

  const parsePdfToUri = async(pdfDoc)=>{
    let pdfBytes = await pdfDoc.save();
    pdfBytes = new Uint8Array(pdfBytes); 
    var pdfBlob = new Blob([pdfBytes], { type: "application/pdf" });
    const pdfDocUrl = URL.createObjectURL(pdfBlob);
    return pdfDocUrl;
  }

  const handlePdfFileSelect = async (e)=>{
    e.stopPropagation();
    e.preventDefault();
    let  files;
    const pageNo = e.target.parentElement.getAttribute('data-page');
    if (!!e.dataTransfer) {
      files = e.dataTransfer.files;
    }
    else {
      files = e.target.files;
    }
    const file = files[0]
    
    if (pageNo === "1") {
      const arrayBuffer = await file.arrayBuffer();
      const p1PdfDoc = await PDFDocument.load(arrayBuffer);
      setP1Pdf(p1PdfDoc);
    }
    else if (pageNo === "2") {
      const arrayBuffer = await file.arrayBuffer();
      const p2PdfDoc = await PDFDocument.load(arrayBuffer);
      // setP2Pdf(p2PdfDoc);
      setP2BasePdf(p2PdfDoc);
    }
  }

  const pdfDropZoneDragOver = (e)=>{
    e.stopPropagation();
    e.preventDefault();
    e.dataTransfer.dropEffect='copy'
  }
  const pdfDropZoneDrop = (e)=>{
    handlePdfFileSelect(e);
  }

  const handleXStartChange = (e)=>{
    const newStart = Number(e.target.value);
    setXStart(newStart);
  }

  const handleXEveryChange = (e)=>{
    const newEvery = Number(e.target.value);
    setXEvery(newEvery);
  }

  const handleYStartChange = (e)=>{
    const newStart = Number(e.target.value);
    setYStart(newStart);
  }

  const handleYEveryChange = (e)=>{
    const newEvery = Number(e.target.value);
    setYEvery(newEvery);
  }

  const handleColsChange = (e)=>{
    const newCols = Number(e.target.value);
    setCols(newCols);
  }

  const handleRowsChange = (e)=>{
    const newRows = Number(e.target.value);
    setRows(newRows);
  }

  const handleSizeChange = (e)=>{
    const newSize = Number(e.target.value);
    setSize(newSize);
  }

  const zipDropZoneDragOver = (e)=>{
    e.stopPropagation();
    e.preventDefault();
    e.dataTransfer.dropEffect='copy'
  }
  const zipDropZoneDrop = (e)=>{
    handleCodeZipFileSelect(e);
  }
  
  const handleCodeZipFileSelect = async (e)=>{
    e.stopPropagation();
    e.preventDefault();
    let files;
    if (!!e.dataTransfer) {
      files = e.dataTransfer.files;
    }
    else {
      files = e.target.files;
    }
    const file = files[0];
    if (!file) return;
    const newZip = await JSZip.loadAsync(file);
    setCodesZip(newZip);
  }

  const produceStampedPdf = async ()=>{
    const codeFileNames = Object.keys(codesZip.files);
    const totalCodes = codeFileNames.length;
    const codesPerPage = rows*cols;
    const pagesNeeded = totalCodes/codesPerPage;
    
    const applyStamps = async()=>{
      return new Promise(async (res,rej)=>{

        const newPDF = await PDFDocument.create();
        codeFileNames.map(async(codeFileName,i)=>{
          const sleep = await promiseSleep(500,'');
          
          // const stampCode = async(codeFileName,i)=>{
            
          // }

          // figure out what page we should be on
          let page;
          if (i%codesPerPage === 0) { // start new page if at beginning of new set
            //create new page from p2basepdf
            if (!p1Pdf) {alert('no p1 base page set!'); return}
            if (!p2BasePdf) {alert('no p2 base page set!'); return}
            const [p1BasePage] = await newPDF.copyPages(p1Pdf,[0]);
            const [p2BasePage] = await newPDF.copyPages(p2BasePdf,[0]);
            //insert into new pdf
            await newPDF.addPage(p1BasePage)
            await newPDF.addPage(p2BasePage)
            page = await newPDF.getPage(newPDF.getPageCount()-1);
          }
          else {
            page = await newPDF.getPage(newPDF.getPageCount()-1); 
          }
          // stamp code onto page
          const file = await codesZip.file(codeFileName).async("uint8array");
          const pngImage = await newPDF.embedPng(file);
          await page.drawImage(pngImage, {
            x: xStart + ( (i%cols)* xEvery),
            y: yStart + ( (i%rows)* yEvery),
            width: size,
            height: size,
          })
          if (i === codeFileNames.length-1) {
            // last file wrap it up
            // res(newPDF);
            setTimeout(()=>{res(newPDF)},1000) // give it a second to finish drawing pdf
          }
        })



      })
    } 

    const newPDF = await applyStamps();
    // export newPDF to user
    let zip = new JSZip();
    const _date = new Date();
    const dateCode = `${String(_date.getMonth()).padStart(2,0)}-${String(_date.getDay()).padStart(2,0)}-${String(_date.getFullYear())}_${String(_date.getHours() % 12  || 12).padStart(2,0)}-${String(_date.getMinutes()).padStart(2,0)}${(_date.getHours() > 12? 'p':'a')}`
    const newPDFBytes = newPDF.save()
    zip.file(`burnableQR_labels_${experienceName.current.value}_${dateCode}.pdf`,newPDFBytes)
    const zipContent = await zip.generateAsync({type:'blob'})
    saveAs(zipContent,`burnableQR_labels_${experienceName.current.value}_${dateCode}.zip`)
  }

  return (<div className={styles.QRStamper}>
    <div className={styles.row}>
      <div className={styles.page1} data-page="1">
        <h3>Page 1</h3>
        <input type="file" name="file" onChange={handlePdfFileSelect} />
        <div className={styles.dropZone} onDragOver={pdfDropZoneDragOver} onDrop={pdfDropZoneDrop}><span>Drop PDF</span></div>
        <iframe title="p1-display-frame" src={p1PdfUri} type="application/pdf" />
      </div>
      <div className={styles.page2} data-page="2">
        <h3>Page 2</h3>
        <input type="file" name="file" onChange={handlePdfFileSelect} />
        <div className={styles.dropZone} onDragOver={pdfDropZoneDragOver} onDrop={pdfDropZoneDrop}><span>Drop PDF</span></div>
        <iframe title="p2-display-frame" src={p2PdfUri} type="application/pdf" />
        <form className={styles.offsetForm}>
          
          <label for="size">Size</label>
          <input name="size" type="number" onChange={handleSizeChange}/>

          <label for="cols">Cols</label>
          <input name="cols" type="number" onChange={handleColsChange}/>
          <label for="rows">Rows</label>
          <input name="rows" type="number" onChange={handleRowsChange}/>

          <label for="xStart">X Start Px</label>
          <input name="xStart" type="number" onChange={handleXStartChange}/>
          <label for="xEvery">X Every Px</label>
          <input name="xEvery" type="number" onChange={handleXEveryChange}/>

          <label for="yStart">Y Start Px</label>
          <input name="yStart" type="number" onChange={handleYStartChange}/>
          <label for="yEvery">Y Every Px</label>
          <input name="yEvery" type="number" onChange={handleYEveryChange}/>

        </form>
      </div>
    </div>

    <div className={styles.row}>
      <form className={styles.codesZipForm}>
          <label for="codesZip">QRCodes (expects a .zip of square .png files)</label>
          <input name="codesZip" type="file" name="file" onChange={handleCodeZipFileSelect} />
          <div className={styles.dropZone} onDragOver={zipDropZoneDragOver} onDrop={zipDropZoneDrop}><span>Drop Zip</span></div>
      </form>
    </div>

    <div className={styles.buttonRow}>
      <label for="experienceName">Experience Name</label>
      <input ref={experienceName} name="experienceName" type="text" />
      <GradientButton style={{width:'100%',color:'#fff'}} text="Stamp it!" onClick={produceStampedPdf} />
    </div>
   
  </div>)
}

export default QRStamper;