import React, {useRef,useContext, useEffect, useState} from 'react';
import styles from "./styles.module.scss";
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'
import {Auth} from 'aws-amplify';
import {MainContext} from '../DataManager/';
import { useHistory } from "react-router-dom";
import Modal from '@material-ui/core/Modal';
import { useTheme,makeStyles} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import VerificationForm from '../VerificationForm';
import { fireFunctionFromKeycode } from '../../Utils/utils-main';
import DecoButton from '../DecoButton'
import { actionIfEnterOrSpace } from '../../Utils/utils-main';
import ResetPasswordForm from '../ResetPasswordForm'
import { cryptoChipChop } from '../../Utils/utils-main';

const forgotPasswordModalStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 'auto',
    left: theme.modal.defaultPosition.left,
    top: theme.modal.defaultPosition.top,
    transform: theme.modal.defaultPosition.transform,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));


const Login = (props)=>{
  const {switchToCreate} = props
  const {user,setUser,removeSessionData,setMainModalContent,clearMainModal,setMainModalTabs,setLoadingModalContent} = useContext(MainContext);
  const history = useHistory();
  const usernameField = useRef();
  const passwordField = useRef();
  const [usernameError,setUsernameError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [forgotPasswordModalContent,setForgotPasswordModalContent] = useState(null)
  const [isPasswordFocused,setIsPasswordFocused] = useState(false);
  
  
  // const theme = useTheme();
  // const forgotPasswordModalClasses = forgotPasswordModalStyles();
  // const ForgotPasswordModal = (props)=>{
  //   const theme = useTheme();
  //   return (<Modal open={!!forgotPasswordModalContent} onClose={()=>{setForgotPasswordModalContent(null)}}><Paper className={forgotPasswordModalClasses.paper}>{forgotPasswordModalContent}</Paper></Modal>)
  // }

  const textFieldStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      backgroundColor: '#fcfcfb',
      border: 'solid 1px rgba(0,0,0,0.2)',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // padding: '20px 12px 20px',
      padding: 0,
      borderRadius: '0',
      '&:hover': {
        border: 'solid 1px rgba(0,0,0,0.4)',
        backgroundColor: '#fff',
      },
      '&$focused': {
        border: 'solid 1px rgba(0,0,0,0.5)',
        backgroundColor: '#fff',
      },
      
    },
    input:{
      padding:'20px 12px 20px',
      fontFamily: 'haboro-contrast-normal, sans-serif',
      fontWeight: 400,
      fontStyle: 'normal',
      fontSize: '1.2em'
    },
    focused: {},
  }));
  const textFieldClasses=textFieldStyles()

  const login = async ()=>{
    try {
      setLoadingModalContent('default');
      setUsernameError('');
      setPasswordError('');
      setMainModalContent(<CircularProgress />)
      const username = usernameField.current.querySelector('input').value
      const password = passwordField.current.querySelector('input').value
      if ((/\s/).test(username)) {
        setUsernameError('Username must be a properly formatted email address (no spaces)')
      }
      else if ((/\s/).test(password)) {
        setPasswordError('Passwords may consist of alphanumerical and special characters only.')
      }
      Auth.signIn(username,password)
      .then(async (user)=>{
        await setUser(user);
        setTimeout(()=>{document.activeElement.blur()},100)
        setLoadingModalContent(null);
        setMainModalContent(null);
      })
      .catch((error)=>{
        setLoadingModalContent(null);
        switch(error.code){
          case 'UserNotFoundException':
            setUsernameError('Invalid username')
            break;
          case 'NotAuthorizedException':
            setPasswordError('Invalid Password')
            break;
          case "UserNotConfirmedException":
            const {h,s} = cryptoChipChop(password,Date.now().toString())
            const verificationTabs = [
              {tabTitle: 'Verify',tabContent:<VerificationForm codeDestination="email" username={username} h={h} s={s}/>},
            ]
            setMainModalTabs(verificationTabs);
          break;
          default:
            break;
        }
      })
      
    } catch (error) {
        
        switch(error.code){
          case 'UserNotFoundException':
            // alert('user not found');
            setUsernameError('Invalid username')
            clearMainModal();
            break;
          case 'NotAuthorizedException':
            setPasswordError('Invalid Password')
            clearMainModal();
            break;
          case "UserNotConfirmedException":
            setMainModalContent(<VerificationForm codeDestination="email"/>)
          break;
          default:
            break;
        }
    }
  }

  const launchForgotPassword = ()=>{
    setMainModalTabs([{tabTitle:'Reset',tabContent:<ResetPasswordForm />}])
  }

  return (
    <div className={styles.login}>
      <form className={`${styles.form} ${isPasswordFocused? styles.passwordFocused : ''}`} onSubmit={(e)=>{e.preventDefault();e.stopPropagation();return null}}>
        <h1>Email</h1>
        <TextField id="username" InputProps={{classes:textFieldClasses,disableUnderline:true}} variant="filled" ref={usernameField} error={usernameError} helperText={usernameError} onKeyUp={(e)=>{fireFunctionFromKeycode(e,login,13)}}/>
        <h1>Password</h1>
        <TextField id="password" InputProps={{classes:textFieldClasses,disableUnderline:true}} variant="filled" ref={passwordField} type="password" error={passwordError} helperText={passwordError} onFocus={()=>{setIsPasswordFocused(true)}} onBlur={()=>{setIsPasswordFocused(false)}} onKeyUp={(e)=>{fireFunctionFromKeycode(e,login,13)}}/>
        <div className={styles.bottomRow}>
          <div className={styles.goBtn}>
            <DecoButton text="Go" type="dark" fontSize="1.5rem" onClick={login} onEnterOrSpace={login}/>
          </div>
          <span className={styles.forgotPassword} onClick={launchForgotPassword} onKeyUp={(e)=>{actionIfEnterOrSpace(e,launchForgotPassword)}} tabIndex="0">Reset password</span>
        </div>
      </form>
    </div>
  )
}

export default Login;