import QRCodeStyling from 'qr-code-styling';
const defaultQr = {
  width: 500,
  height: 500,
  data: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
  qrOptions: {
    errorCorrectionLevel: 'Q',
    typeNumber: 0
  },
  imageOptions:{
    margin: 0,
    hideBackgroundDots: false,
    imageSize: 0.4
  },
  dotsOptions:{
    color: '#f00',
    gradient: {
      type: 'Linear',
      rotation: 0,
      colorStops: [
        {
          offset:0,
          color: '#0000ff'
        },
        {
          offset:1,
          color: '#00ff00'
        }
      ]
    }
  }
}

const DefaultQR = new QRCodeStyling(defaultQr);


export default DefaultQR;