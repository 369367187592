import React,{useEffect,useRef,useState} from "react"
import styles from './styles.module.scss';

const EditableConfigRowString = (props)=>{
  const {configurable,addRefToSet,refSet} = props;
  const fieldRef = useRef();
  const [fieldVal,setFieldVal] = useState(props.configurable.value);
  useEffect(()=>{
    if (!refSet.indexOf(fieldRef)) {addRefToSet(fieldRef);}
  },[fieldVal])
  return (<li><span>{configurable.name}</span><input className={styles.controlElementInput} ref={fieldRef} configurablename={configurable.name} valuetype="string" value={fieldVal} onChange={(e)=>{setFieldVal(e.target.value)}}></input></li>);
}

export default EditableConfigRowString