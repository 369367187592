import React, {useContext} from "react"
import {MainContext} from '../DataManager/';
import QueryStringParser from "../QueryStringParser";
const MainQueryParamSetup = (props)=>{
  // we need this wrapper component because app.js cannot consume props from datamanager because it is on the same level.
  const {setDebugEnabled,setSpaceGateEnabled} = useContext(MainContext);
  const MainParamBehaviors = {
    debug: {
      vals: {
        "0": ()=>{setDebugEnabled(false)},
        "1": ()=>{setDebugEnabled(true)}
      },
      envs: ["dev","stage"]
    },
    spaceGate: {
      vals: {
        "0": ()=>{setSpaceGateEnabled(false)},
        "1": ()=>{setSpaceGateEnabled(true)}
      }
    }
  }
  return <QueryStringParser paramBehaviors={MainParamBehaviors} />
}

export default MainQueryParamSetup;