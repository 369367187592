import React,{useEffect} from "react";
import GoldBorderModal from "../../../Components/Modals/GoldBorderModal";
// import * as queries from '../../../graphql/queries';
// import * as mutations from '../../../graphql/mutations';
// import { API } from 'aws-amplify';

const Blank = (props)=>{
  return (<>
    <h1>Blank</h1>
  </>)
}

const DefaultControlUI_Landing = (props)=>{
  const {setParentView,guid,PubSub,parseCommsFromActual,queries,API,experienceName} = props;
  // const [guid,setGuid] = useState();
  // const [loading,setLoading] = useState(true);
  const checkGuid = async (guid)=>{
    const guidCheck = await API.graphql({ query: queries.checkBurnable, variables:{id:guid,experienceName:experienceName} }) 
    if (guidCheck.data.checkBurnable.id === 'invalid') {
      setParentView('invalid')
    }
    else if (guidCheck.data.checkBurnable.usesLeft > 0) {
      //subscribe to pubsub guid channel
      const _channel = `${process.env.REACT_APP_BUILD_ENV}/guid/${guid}/client`;
      PubSub.subscribe(_channel).subscribe({
        next: (data)=>{parseCommsFromActual(data)},
        error: (err)=>{console.error('Pubsub subscribe error:',err)}
      });
      setParentView('launch');
    }
    else {
      setParentView('burnt')
    }
    
  }    
  const CheckingGuid = (props)=>{
    return(<h1>Checking your code...</h1>)
  }

  useEffect(()=>{
    if (!guid) return;
    //once guid has been set, we fire the graphql call to check guid status. when it returns we setParentView
    checkGuid(guid);  
  },[])
  return (
    <>
      {!guid && <GoldBorderModal modalContent={<Blank {...props} />} />}
      {!!guid && <GoldBorderModal modalContent={<CheckingGuid {...props} />} />}
    </>
  )
}

export default DefaultControlUI_Landing;