import React from "react";
import {Backdrop} from "@material-ui/core"
import GoldBorderModal from '../Modals/GoldBorderModal'
const ConfigModal = (props)=>{
  const {configModalContent} = props;
  return (<>
    <Backdrop open={true}/>
    <GoldBorderModal modalContent={configModalContent} />
  </>)
}

export default ConfigModal