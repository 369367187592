import React, {useEffect, useRef,useState,useContext} from 'react';
import {Auth} from 'aws-amplify';
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import styles from "./styles.module.scss";
import Modal from '@material-ui/core/Modal';

import { SettingsOutlined } from '@material-ui/icons';

import * as mutations from '../../graphql/mutations';
import { API } from 'aws-amplify';
import TextField from '@material-ui/core/TextField'
import VerificationForm from '../VerificationForm';
import { actionIfEnter,cryptoChipChop } from '../../Utils/utils-main';
import { useTheme,makeStyles} from '@material-ui/core/styles';
import DecoButton from '../DecoButton'
import {MainContext} from '../DataManager'
import initUser from './initUser'

const SignUp = (props)=>{
  // const {switchToLogin} = props;
  const signUpUsernameField_email = useRef();
  const signUpPasswordField_email = useRef();
  const signUpNameField_email = useRef();

  const signUpUsernameField_phone = useRef();
  const signUpPasswordField_phone = useRef();
  const signUpNameField_phone = useRef();

  const [usernameError,setUsernameError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  // const [signUpType,setSignUpType] = useState("emailSignup");
  // TEMP TODO clean this up. we used to have a toggle between email and phone here, but disabling for now. Will need to figure out the right way to reenable.
  const signUpType="emailSignup";

  const [modalContent, setModalContent] = useState(false);
  
  const {setMainModalTabs,setLoadingModalContent} = useContext(MainContext);

  const [rowShift,setRowShift] = useState(0); // used to shift rows up to keep fields in view with keyboard open on mobile devices

  // const handleTabChange = (e,newVal)=>{
  //   setSignUpType(newVal)
  // }

  const textFieldStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      backgroundColor: '#fcfcfb',
      border: 'solid 1px rgba(0,0,0,0.2)',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // padding: '20px 12px 20px',
      padding: 0,
      borderRadius: '0',
      '&:hover': {
        border: 'solid 1px rgba(0,0,0,0.4)',
        backgroundColor: '#fff',
      },
      '&$focused': {
        border: 'solid 1px rgba(0,0,0,0.5)',
        backgroundColor: '#fff',
      },
      
    },
    input:{
      padding:'20px 12px 20px',
      fontFamily: 'haboro-contrast-normal, sans-serif',
      fontWeight: 400,
      fontStyle: 'normal',
      fontSize: '1.2em'
    },
    focused: {},
  }));
  const textFieldClasses=textFieldStyles()
  async function doSignUp(){
    setLoadingModalContent('default');
    let username,password,name,email,phone;
    setUsernameError('');
    setPasswordError('');
    
    if (signUpType === "phoneSignup") {
      username = signUpUsernameField_phone.current.querySelector('input').value;
      password = signUpPasswordField_phone.current.querySelector('input').value;
      name = signUpNameField_phone.current.querySelector('input').value;
      email = '';
      phone = signUpUsernameField_phone.current.querySelector('input').value;
    }
    else {
      username = signUpUsernameField_email.current.querySelector('input').value;
      password = signUpPasswordField_email.current.querySelector('input').value;
      name = signUpNameField_email.current.querySelector('input').value;
      phone = '';
      email = signUpUsernameField_email.current.querySelector('input').value;
    }
    if ((/\s/).test(username)) {
      setUsernameError('Username must be a properly formatted email address (no spaces)')
      setLoadingModalContent(null);
      return;
    }
    else if ((/\s/).test(password)) {
      setPasswordError('Passwords may consist of alphanumerical and special characters only.')
      setLoadingModalContent(null);
      return;
    }
    try {
      const user = await Auth.signUp({
        username,
        password,
        email,
        // phone,
        attributes: {
          name,
          email
          // phone_number
        }
      })
      // await initUser(JSON.stringify(user));
      if(signUpType === "phoneSignup") {
        // setModalContent(<VerificationForm user={user} setModalContent={setModalContent} codeDestination="text message" />);
      } 
      else if (signUpType === "emailSignup") {
        const {h,s} = cryptoChipChop(password,Date.now().toString())
        const verificationTabs = [
          {tabTitle: 'Verify',tabContent:<VerificationForm codeDestination="email" username={username} h={h} s={s}/>},
        ]
        setLoadingModalContent(null);
        setMainModalTabs(verificationTabs);
        // setMainModalType('leatherTabbed')
        // setMainModalContent('lorem')
        // setModalContent(<VerificationForm user={user} setModalContent={setModalContent} codeDestination="email" />);
      }
      
    } catch (err) {
      console.warn('error during signup:', err);
      switch(err.code){
        case 'InvalidPasswordException':
          // alert('user not found');
          setPasswordError(err.message)
          setLoadingModalContent(null);
          break;
        case 'InvalidParameterException':
          setUsernameError(err.message)
          setLoadingModalContent(null);
          break;
        case 'UsernameExistsException':
          setUsernameError(err.message)
          setLoadingModalContent(null);
          break;
        default:
          break;
      }
    }
  }


  return (<div className={styles.signup} >

    {/* <Tabs value={signUpType} onChange={handleTabChange} className={styles.tabsWrapper}>
      <Tab label="Phone Signup" value="phoneSignup" />
      <Tab label="Email Signup"  value="emailSignup" />
    </Tabs> */}
    {/* {signUpType==="phoneSignup" && <form className={styles.phoneForm}>
      <label for="phone">Phone<super>*</super></label>
      <input ref={signUpUsernameField_phone} type="text" name="phone"/>
      <label for="password">Password<super>*</super></label>
      <input ref={signUpPasswordField_phone} type="password" name="password" />
      <label for="name">Name<super>*</super></label>
      <input ref={signUpNameField_phone} type="text" name="name" />
      <div className={styles.bottomRow}>
        <span className={styles.switchToLogin} onClick={switchToLogin}>Already have an account?</span>
        <Button variant="contained" color="primary" onClick={signUp}>Sign up</Button>
      </div>
      
    </form>} */}
    {signUpType==="emailSignup" && <form className={styles.emailForm} style={{top:`-${rowShift*7.5}em`}}>
      <h1>Email</h1>
      <TextField id="username" InputProps={{classes:textFieldClasses,disableUnderline:true}} ref={signUpUsernameField_email} required variant="filled" error={usernameError} helperText={usernameError} onKeyUp={(e)=>{actionIfEnter(e,doSignUp)}} onFocus={()=>{setRowShift(0)}} onBlur={()=>{setRowShift(0)}} />
      <h1>Password</h1>
      <TextField id="password" InputProps={{classes:textFieldClasses,disableUnderline:true}} type="password"  ref={signUpPasswordField_email} required variant="filled" error={passwordError} helperText={passwordError} onKeyUp={(e)=>{actionIfEnter(e,doSignUp)}} onFocus={()=>{setRowShift(1)}} onBlur={()=>{setRowShift(0)}} />
      <h1>First Name</h1>
      <TextField id="name" InputProps={{classes:textFieldClasses,disableUnderline:true}} ref={signUpNameField_email} required variant="filled" onKeyUp={(e)=>{actionIfEnter(e,doSignUp)}} onFocus={()=>{setRowShift(2)}} onBlur={()=>{setRowShift(0)}} />
      <div className={styles.bottomRow}>
        {/* <Button variant="contained" color="primary" onClick={signUp}>Sign up</Button> */}
        <div className={styles.goBtn}>
            <DecoButton text="Go" type="dark" fontSize="1.5rem" onClick={doSignUp} onEnterOrSpace={doSignUp} onFocus={()=>{setRowShift(0)}} onBlur={()=>{setRowShift(0)}} />
        </div>
      </div>
    </form>} 
  <Modal className={styles.modalWrapper} open={!!modalContent}>
    <Paper className={styles.modal} elevation={6}>
      {modalContent}
      {/* <VerificationPopup userid={signUpUsernameField_phone.current.value} /> */}
    </Paper>
  </Modal>
  </div>
  )
}

export default SignUp;