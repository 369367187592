/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:e78e253a-cb3f-4ba9-82c8-6bf92ba457db",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_ONB7Vtsrw",
    "aws_user_pools_web_client_id": "p2l6gludb26ntfvnnms8up9m6",
    "oauth": {},
    "aws_cognito_login_mechanisms": [
        "EMAIL"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://vt9lxg41x0.execute-api.us-west-2.amazonaws.com/master",
            "region": "us-west-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://yxxuia2lgngexkyfaxtwhuahxq.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "hbmainstorage212045-master",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
