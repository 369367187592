import React from "react"
import styles from './styles.module.scss'

const FixLocPerms = (props)=>{
  return (<div className={styles.FixLocPerms}>
    <h3>Geolocation denied</h3>
    <p>Uh oh! It looks like location permissions for the Haunted Bar have been denied in your browser. We need those to make sure you're here!</p>
    <p>For a quick workaround, you may be able to just open this page in a new private/incognito window and grant location permissions there. If that doesn't work...</p>
    <p>To enable those in Chrome, press the 3 dots menu button to the right of the address bar, and then Settings. Tap Site Settings and then Location. Here you can turn Location on or off. Try detailed instructions <a href="https://browserhow.com/how-to-enable-disable-geolocation-access-in-google-chrome/">here</a></p> 
    <p>To enable those in Firefox, press the 3 dots menu button to the right of the address bar, and then Settings. Tap Site Permissions and then Location. Here you can turn Location on or off. Try detailed instructions <a href="https://support.mozilla.org/en-US/questions/988163">here</a></p>
    <p>To enable those in Safari, try instructions <a href="https://browserhow.com/how-to-enable-or-disable-location-access-in-apple-safari/" target="_blank" rel="noreferrer">here</a></p>
  </div>)
}

export default FixLocPerms