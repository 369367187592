import React, {useContext,useRef,useState,createRef} from "react";
import { useEffect } from "react";
import {MainContext} from '../DataManager/';
import MapGuide from '../MapGuide'
import { haversine_distance } from "../../Utils/utils-main";
import eventGeofences from './eventGeofences'
import H from "@here/maps-api-for-javascript";
import {drawPolys} from "../MapGuide/mapUtils"
import ComeInsideScreen from '../Screens/ComeInsideScreen'
import PermsPrompt from '../PermsPrompt'
import GoldBorderModal from '../Modals/GoldBorderModal'
import FixLocPerms from "../FixLocPerms"
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import {polygon,point} from '@turf/helpers'
import throttle from 'lodash/throttle'
const SpaceGate = ({children,...props})=>{
  const {spaceGateEnabled,eventLatLong,eventRadiusInner,eventRadiusOuter} = useContext(MainContext);
  const [permsState,setPermsState] = useState(null);
  const [positionInitialized,setPositionInitialized] = useState(false);
  const [userIsAroundBar, setUserIsAroundBar] = useState(false);
  const [userIsInsideBar, setUserIsInsideBar] = useState(false);
  
  useEffect(()=>{
    checkPerms();
  },[])
  useEffect(()=>{
    if (permsState==="granted") {navigator.geolocation.watchPosition(getPosSuccess, getPosErr, options);}
  },[permsState])


  const initGeofences=()=>{
    const fencePolys = [];
    for (const fenceId in eventGeofences) {
      const fencePoints = eventGeofences[fenceId];
      const fencePoly = polygon([fencePoints]);
      fencePolys.push(fencePoly);
      setInterval(throttledCheckGeoFences,500)
    }
    // //TEMP DEBUG TODO DELME SECURITY
    window.fencePolys = fencePolys;
    window.currentLoc={lat:0,long:0}
  }
  useEffect(()=>{initGeofences()},[])


  const _setCurrentLoc=(loc)=>{
    window.currentLoc = loc;
  }

  const options = {
    enableHighAccuracy: true,
    timeout: Infinity,
    maximumAge: 0
  };

  const getLoc = ()=>{
    return window.currentLoc;
  }

  const checkGeofences = ()=>{
    const loc = getLoc();
    const pt = point([loc.long,loc.lat])
    const isInsideOuter = booleanPointInPolygon(pt,window.fencePolys[0]);
    const isInsideInner = booleanPointInPolygon(pt,window.fencePolys[1]);

    if (!!isInsideInner) {
      !userIsInsideBar && setUserIsInsideBar(true)
    }
    else if (!!isInsideOuter) {
      !!userIsInsideBar && setUserIsInsideBar(false)
      !userIsAroundBar && setUserIsAroundBar(true)
    }

  }
  const throttledCheckGeoFences = throttle(checkGeofences,400)

  const updatePosition=(pos)=>{
    const currentLoc = { lat: pos.coords.latitude, long: pos.coords.longitude };
    if (!window.debugTapped) {_setCurrentLoc(currentLoc);}
    throttledCheckGeoFences();
    
    if (!positionInitialized) {
      setPositionInitialized(true);
    }

  }
  const throttledUpdatePosition = throttle(updatePosition,100,{'trailingEdge':false})
  const getPosSuccess = (pos)=>{
    throttledUpdatePosition(pos)
  }

  const getPosErr = (err) => {
  };

  const handlePermsSuccess = ()=>{setPermsState("granted")}

  const handlePermsError = (err)=>{
    // 1: denied
    // 2: not available
    // 3: timeout
    switch (err.code){
      case 1:
        setPermsState("denied")
        break;
      case 2:
        setPermsState("prompt")
        break;
      case 3:
        setPermsState("prompt")
        break;
      default:
        setPermsState("prompt")
        break;
    }
   }

  const checkPerms = ()=>{
    if (!!navigator?.permissions) {
      // not ios, perms flow
      navigator.permissions.query({ name: 'geolocation' })
      .then((res)=>{
        switch(res.state){
          case "granted":
            //proceed
            setPermsState("granted")
            break;
          case "prompt":
            //show geoloc perms screen
            setPermsState("prompt");
            break;
          case "denied":
            //show geoloc perms denied screen
            setPermsState("denied")
            break;
          default:
            setPermsState("prompt")
            break;
        }
      })
    }
    else {
      // ios or no perms, try/catch flow
      const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      };
      // const success=()=>{handlePermsSuccess()} 
      // const error=
      // navigator.geolocation.getCurrentPosition(handlePermsSuccess, handlePermsError, options);
      setPermsState("prompt")
    }
  }

  // const simulateUpdateCoords = (newCoords)=>{
  //   if (newCoords) _setCurrentLoc(newCoords)
  //   else return _setCurrentLoc({lat:window.currentLoc.lat+0.001,long:window.currentLoc.long+0.001})
  // }
  // window.simulateUpdateCoords = simulateUpdateCoords;
  
  // const PermsDenied = ()=>{
  //   return (<>
      
  //   </>)
  // }

  const grantPerms=()=>{
    // how do we handle a grant?
    // user hits perms btn, we fire a request which throws up the popover, user can grant/deny, we can respond to that action by again pivoting the display state. Perfect. 
    navigator.geolocation.getCurrentPosition(handlePermsSuccess, handlePermsError, options);
    // for now while we test always grant
    // setPermsState("granted")
  }
  
  
  return (
    <>
    {!spaceGateEnabled && <>{children}</>}
    {!permsState && <h1>Checking Perms</h1>}
    {permsState==="denied" && !!spaceGateEnabled && <GoldBorderModal modalContent={<FixLocPerms />} /> }
    {permsState==="prompt" && !!spaceGateEnabled && <PermsPrompt permsState={permsState} action={grantPerms} />}
    {permsState==="granted" && !!spaceGateEnabled && !!positionInitialized && !!userIsInsideBar && <>{children}</>}
    {permsState==="granted" && !!spaceGateEnabled && !!positionInitialized && !userIsAroundBar && !userIsInsideBar && <><MapGuide /></>}
    {permsState==="granted" && !!spaceGateEnabled && !!positionInitialized && !!userIsAroundBar && !userIsInsideBar && <GoldBorderModal modalContent={<ComeInsideScreen />} />}
    </>
  )
}
export default SpaceGate;