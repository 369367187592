import './App.scss';
import React, {useState,useEffect} from 'react';
import Amplify from 'aws-amplify';
import awsmobile from './aws-exports';
import {ThemeProvider } from '@material-ui/core';
import { DataManager } from './Components/DataManager';
import {SoundManager} from './Components/SoundManager';
import {
  BrowserRouter as Router,
  Switch,
  useHistory
} from "react-router-dom";
import Theme from './Components/Theme'
import Grid from '@material-ui/core/Grid'
import styles from './main.module.scss';
import DebugControls from './Components/DebugControls'
import MainQueryParamSetup from './Components/MainQueryParamSetup'
import LoadingModal from './Components/Modals/LoadingModal'
import {RenderedRoutes} from './Routes'
import MultiModal from './Components/Modals/MultiModal'
import BarSignHand from './Img/Wallpapers/barSignHand.jpg'
import Wallpaper from './Components/Wallpaper'
Amplify.configure(awsmobile);

const initAudio=(e)=>{
  if (e.originalEvent === undefined) return; //synthetic click will cause failed init so bail before that can happen
  document.body.removeEventListener('click',initAudio)
  // init nav reroute bell
  const bellAudio = document.querySelector('.bellAudio');
  // window.bellAudio = bellAudio;
  bellAudio.addEventListener('canplaythrough',(e)=>{
    bellAudio.volume= 0.51;
    bellAudio.play();
    bellAudio.pause();
  })
  bellAudio.load();

  // init nav complete ghost breath
  const ghostBreathAudio = document.querySelector('.ghostBreathAudio');
  ghostBreathAudio.addEventListener('canplaythrough',(e)=>{
    ghostBreathAudio.volume= 1;
    ghostBreathAudio.play();
    ghostBreathAudio.pause();
  }) 
  ghostBreathAudio.load();
}

function App() {
  const history = useHistory();
  const [mainModalContent,setMainModalContent] = useState(null);
  const [mainModalType,setMainModalType] = useState('leatherTabbed');
  const [mainModalTabs,setMainModalTabs] = useState(null)
  const [loadingModalContent,setLoadingModalContent] = useState(null);
  const [wallpaperImg,setWallpaperImg] = useState(BarSignHand)
  useEffect(()=>{
    document.body.addEventListener('click',initAudio)
  },[])
  return (
    <ThemeProvider>
      <div className="App">
        <SoundManager>
          <DataManager mainModalContent={mainModalContent} setMainModalContent={setMainModalContent} mainModalTabs={mainModalContent} setMainModalTabs={setMainModalTabs} mainModalType={mainModalType} setMainModalType={setMainModalType} loadingModalContent={loadingModalContent} setLoadingModalContent={setLoadingModalContent} wallpaperImg={wallpaperImg} setWallpaperImg={setWallpaperImg}>
            <MainQueryParamSetup />
            <Theme>
              <Router>
                <Grid container spacing={0} className={styles.mainGridWrapper} xs={12}>
                  <Grid item xs={12} className={styles.mainGridItem}>
                    <Switch>
                      {RenderedRoutes}
                  </Switch>
                </Grid>
              </Grid>
              {!!loadingModalContent && <LoadingModal content={loadingModalContent} />}
              <MultiModal setModalContent={setMainModalContent} modalContent={mainModalContent} modalType={mainModalType} setModalType={setMainModalType} modalTabs={mainModalTabs} />
            </Router>
            </Theme>
            <DebugControls />
          </DataManager>
        </SoundManager>
        <Wallpaper bgImg={wallpaperImg}/>
      </div>
    </ThemeProvider>
  );
}
 
export default App;
