import styles from './styles.module.scss'
import{ PubSub} from 'aws-amplify';
const IOTPermsTest = (props)=>{

  // const adminChannelID = `${process.env.REACT_APP_BUILD_ENV}/guid/testGuid123/`
  // const guidChannelID = 'guidChannelTest'

  const clientChannel = `${process.env.REACT_APP_BUILD_ENV}/guid/testGuid123/client`
  const actualChannel = `${process.env.REACT_APP_BUILD_ENV}/guid/testGuid123/actual`
  const triggerChannel = `${process.env.REACT_APP_BUILD_ENV}/guid/testGuid123/trigger`
  const adminChannel = `${process.env.REACT_APP_BUILD_ENV}/admin/fakeExperience`

  const subToClientChannel = async ()=>{
    PubSub.subscribe(clientChannel).subscribe({
      next: (data)=>{console.log('PubSub message received on client channel:',data)},
      error: (err)=>{console.error('Pubsub subscribe to client channel error:',err)}
    })
  }

  const subToActualChannel = async ()=>{
    PubSub.subscribe(actualChannel).subscribe({
      next: (data)=>{console.log('PubSub message received on actual channel:',data)},
      error: (err)=>{console.error('Pubsub subscribe to actual channel error:',err)}
    })
  }

  const subToTriggerChannel = async ()=>{
    PubSub.subscribe(triggerChannel).subscribe({
      next: (data)=>{console.log('PubSub message received on trigger channel:',data)},
      error: (err)=>{console.error('Pubsub subscribe to trigger channel error:',err)}
    })
  }

  const subToAdminChannel = async ()=>{
    PubSub.subscribe(adminChannel).subscribe({
      next: (data)=>{console.log('PubSub message received on admin channel:',data)},
      error: (err)=>{console.error('Pubsub subscribe to admin channel error:',err)}
    })
  }

  const pubToClientChannel = async ()=>{
    PubSub.publish(clientChannel,{command:'testPubToClientChannel'})
  }

  const pubToActualChannel = async ()=>{
    PubSub.publish(actualChannel,{command:'testPubToActualChannel'})
  }

  const pubToTriggerChannel = async ()=>{
    PubSub.publish(triggerChannel,{command:'testPubToTriggerChannel'})
  }

  const pubToAdminChannel = async ()=>{
    PubSub.publish(adminChannel,{command:'testPubToAdminChannel'})
  }


  return (<div className={styles.IOTPermsTest}>
    <button onClick={subToClientChannel}>subToClientChannel (user,mod+)</button>
    <button onClick={pubToClientChannel}>pubToClientChannel (mod+)</button>
    <button onClick={subToActualChannel}>subToActualChannel (mod+)</button>
    <button onClick={pubToActualChannel}>pubToActualChannel (user,mod+)</button>
    <button onClick={subToTriggerChannel}>subToTriggerChannel (mod+)</button>
    <button onClick={pubToTriggerChannel}>pubToTriggerChannel (user,mod+)</button>
    <button onClick={subToAdminChannel}>subToAdminChannel (mod+)</button>
    <button onClick={pubToAdminChannel}>pubToAdminChannel (mod+)</button>
  </div>)
}

export default IOTPermsTest